import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Transforms, createEditor } from 'slate';
import { Slate, withReact } from 'slate-react';
import { Input, SearchIcon } from './style';
import { MentionsInput } from '../../../MentionsInput';
import { keyboardNavigation } from '../../../MentionsInput/keyboardNavigation';
import Leaf from '../Leaf';
import Element from '../Element';
import { withMention } from '../Mention';
import {
  updateMention,
  withReadOnly,
  elementsToText,
  buildSlateElements,
} from './utils';
import { StyledErrorMessage } from '../../../inputs/Input/style';

function EditableReadOnlyMentionsInput(props) {
  const {
    experienceId,
    placeholder,
    steps,
    name,
    error,
    initialValue,
    inputProps,
    containerStyle,
    onValueChange,
    dataTestId,
  } = props;

  const [elements, setElements] = useState(
    buildSlateElements(initialValue || ''),
  );
  const [value, setValue] = useState(initialValue);
  const editor = useMemo(
    () => withMention(withReadOnly(withReact(createEditor()))),
    [],
  );
  const [mentionsIsOpen, setMentionsIsOpen] = useState(false);
  const [mentionsItemIndex, setMentionsItemIndex] = useState(0);
  const renderElement = useCallback((props) => {
    return <Element {...props} editor={editor} />;
  }, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const mentionsItemRef = useRef(null);
  const mentionsListRef = useRef(null);

  useEffect(() => {
    if (elements) {
      const value = elementsToText(elements);
      setValue(value === ' ' ? '' : value);
    }
  }, [elements]);

  useEffect(() => {
    if (onValueChange) {
      onValueChange(value);
    }
  }, [value]);

  const insertReference = async (reference) => {
    updateMention(editor, reference, true);
    setMentionsIsOpen(false);
  };

  const handleKeyDown = (event) => {
    keyboardNavigation(
      event,
      mentionsIsOpen,
      mentionsItemIndex,
      setMentionsItemIndex,
      mentionsItemRef,
      mentionsListRef,
    );
  };

  const openMentions = () => {
    Transforms.select(editor, { offset: 0, path: [0, 0] });
    setMentionsIsOpen(true);
  };

  const handleCloseDropdown = () => {
    setMentionsIsOpen(false);
    setMentionsItemIndex(0);
  };

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid var(--border-color-primary)',
        borderRadius: '8px',
        ...containerStyle,
      }}
    >
      <Slate
        editor={editor}
        value={elements}
        onChange={(elements) => setElements(elements)}
      >
        <Input
          data-testid={dataTestId}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          spellCheck={false}
          onClick={openMentions}
          error={error}
          {...inputProps}
        />
        <SearchIcon aria-label="open mentions" size={20} onClick={openMentions} />
      </Slate>
      <MentionsInput
        name={name}
        placeholder={placeholder}
        experienceId={experienceId}
        isOpen={mentionsIsOpen}
        steps={steps}
        error={error}
        customInput
        onReference={insertReference}
        optionsListRef={mentionsListRef}
        listItemRef={mentionsItemRef}
        listItemIndex={mentionsItemIndex}
        listStyle={{ bottom: '0' }}
        onCloseDropdown={handleCloseDropdown}
      />
      <input name={name} type="hidden" value={value || ''}  data-testid={dataTestId}/>
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </div>
  );
}

export default EditableReadOnlyMentionsInput;
