import {
  APP_SETTINGS_PRIMARY_COLOR,
  APP_SETTINGS_PRIMARY_COLOR_TRANSPARENTIZED,
} from '../../../../constants';

export const styles = {
  container: {
    width: '100%',
    '& .MuiSlider-root': {
      padding: '14px 0 12px',
    },
    '& .MuiSlider-rail': {
      height: 3,
      opacity: 1,
      color: '#bdbdbd',
      borderRadius: '27px',
    },
    '& .MuiSlider-track': {
      height: 3,
      opacity: 1,
      color: APP_SETTINGS_PRIMARY_COLOR,
      border: 0,
      borderRadius: '27px',
    },
    '& .MuiSlider-thumb': {
      width: '14px',
      height: '14px',
      backgroundColor: '#fff',
      border: '1px solid var(--border-color-primary)',
    },
    '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
      boxShadow: `0px 0px 0px 8px ${APP_SETTINGS_PRIMARY_COLOR_TRANSPARENTIZED}`,
    },
  },
  input: {
    position: 'relative',
    width: 65,
    height: 42,
    backgroundColor: 'var(--white)',
    border: '1px solid var(--border-color-primary)',
    borderRadius: '8px',
    p: '0px 0px 0px 12px',
    '&:hover': {
      borderColor: 'var(--border-color-primary--hover)',
    },
    '&:after': {
      content: '"%"',
    },
    '& .MuiInputBase-input:focus': {
      borderColor: 'var(--border-color-primary--active)',
      borderRadius: 0,
      boxShadow: 'none',
    },
    '& .MuiInputBase-input:invalid': {
      border: 'none',
    },
    '& .MuiInputBase-inputMarginDense': {
      padding: '0px 0px 0px 12px',
    },
    '& .MuiInputBase-input': {
      width: '28px',
      MozAppearance: 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
};
