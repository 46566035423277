import React from 'react';
import PropTypes from 'prop-types';
import { useHover } from 'use-events';
import _isEmpty from 'lodash/isEmpty';

import { CURRENCY_SIGN_MAP } from '../../../../../constants';
import { SmallDeleteButton } from '../../../../Button';
import { DonationInputWrapper, RightButtonWrapper } from '../style';
import CustomizeToolbar from '../../../../CustomizeToolbar';
import EditableMentionsInput from '../../../../SlateElements/components/EditableMentionsInput';
import { ExperienceIdContext } from '../../../index';
import CssStyleHiddenInputs from '../../CssStyleHiddenInputs';

function AmountInput({
  index,
  value,
  payment_option_id,
  destroy,
  onChange,
  onSaveStyles,
  onKeyPress,
  onDelete,
  currency,
  cssStyle,
  error,
  mentionSteps,
}) {
  const [isHover, bind] = useHover();
  const experienceId = React.useContext(ExperienceIdContext);

  return (
    <>
      <CssStyleHiddenInputs
        cssStyle={cssStyle}
        attributesName={`ask[payment_options_attributes][${index}][css_style_attributes]`}
      />
      <input
        type="hidden"
        value={payment_option_id}
        name={`ask[payment_options_attributes][${index}][id]`}
      />
      <input
        type="hidden"
        value={destroy}
        name={`ask[payment_options_attributes][${index}][_destroy]`}
      />
      {!destroy && (
        <DonationInputWrapper {...bind}>
          <EditableMentionsInput
            name={`ask[payment_options_attributes][${index}][amount]`}
            initialValue={value}
            experienceId={experienceId}
            steps={mentionSteps}
            error={error}
            label={CURRENCY_SIGN_MAP[currency]}
            onValueChange={onChange}
            onKeyDown={onKeyPress}
            withHiddenInput
            autoFocus
          />
          <CustomizeToolbar
            cssStyle={cssStyle}
            index={index}
            isHover={isHover}
            handleSave={onSaveStyles}
            type="paymentOption"
            sx={{
              position: 'absolute',
              top: '10px',
              right: '50px',
            }}
          />
          <RightButtonWrapper>
            <SmallDeleteButton
              tabIndex="-1"
              data-testid="PaymentSection/DeleteButton"
              onClick={onDelete}
            >
              –
            </SmallDeleteButton>
          </RightButtonWrapper>
        </DonationInputWrapper>
      )}
    </>
  );
}

AmountInput.propTypes = {
  index: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  payment_option_id: PropTypes.number,
  destroy: PropTypes.bool,
  currency: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AmountInput;
