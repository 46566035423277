import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import { DataField } from '../DataField';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CssStyleHiddenInputs from '../CssStyleHiddenInputs';

const renderHiddenFields = (
  id,
  type,
  richText,
  required,
  deleted,
  cssStyle,
  dataFieldIndex,
  multipleChoiceOptions,
  currency,
  hidden,
  showConditions,
  checkboxHidden,
  defaultValue,
) => {
  const conditionSelected = _isNull(hidden);

  return (
    <>
      <CssStyleHiddenInputs
        cssStyle={cssStyle}
        attributesName={`ask[data_fields_attributes][${dataFieldIndex}][css_style_attributes]`}
      />
      {id && (
        <input
          type="hidden"
          name={`ask[data_fields_attributes][${dataFieldIndex}][id]`}
          defaultValue={id}
        />
      )}
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][field_type]`}
        data-testid="DataField/HiddenInput_type"
        defaultValue={type}
      />
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][required]`}
        data-testid="DataField/HiddenInput_required"
        defaultValue={required}
      />
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][position]`}
        defaultValue={dataFieldIndex}
      />
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][currency]`}
        defaultValue={currency}
      />
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][hidden]`}
        defaultValue={conditionSelected ? '' : hidden}
      />
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][checkbox_hidden]`}
        defaultValue={checkboxHidden}
      />
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][default_value]`}
        defaultValue={defaultValue}
      />
      <input
        type="hidden"
        name={`ask[data_fields_attributes][${dataFieldIndex}][_destroy]`}
        defaultValue={deleted}
      />
      {!_isEmpty(richText) && (
        <>
          <input
            type="hidden"
            name={`ask[data_fields_attributes][${dataFieldIndex}][rich_text_attributes][title]`}
            defaultValue={richText.title}
          />
          <input
            type="hidden"
            name={`ask[data_fields_attributes][${dataFieldIndex}][rich_text_attributes][title_elements]`}
            defaultValue={richText.titleElements}
          />
          <input
            type="hidden"
            name={`ask[data_fields_attributes][${dataFieldIndex}][rich_text_attributes][title_html]`}
            defaultValue={richText.titleHtml}
          />
        </>
      )}
      {multipleChoiceOptions && (
        <>
          {multipleChoiceOptions.map(
            ({ id, richText, deleted }, multipleChoiceIndex) => (
              <Fragment key={multipleChoiceIndex}>
                {id && (
                  <input
                    type="hidden"
                    name={`ask[data_fields_attributes][${dataFieldIndex}][multiple_choice_options_attributes][${multipleChoiceIndex}][id]`}
                    defaultValue={id}
                  />
                )}
                {richText.id && (
                  <input
                    type="hidden"
                    name={`ask[data_fields_attributes][${dataFieldIndex}][multiple_choice_options_attributes][${multipleChoiceIndex}][rich_text_attributes][id]`}
                    defaultValue={richText.id}
                  />
                )}
                <input
                  type="hidden"
                  name={`ask[data_fields_attributes][${dataFieldIndex}][multiple_choice_options_attributes][${multipleChoiceIndex}][rich_text_attributes][title]`}
                  defaultValue={richText.title}
                />
                <input
                  type="hidden"
                  name={`ask[data_fields_attributes][${dataFieldIndex}][multiple_choice_options_attributes][${multipleChoiceIndex}][position]`}
                  defaultValue={multipleChoiceIndex}
                />
                <input
                  type="hidden"
                  name={`ask[data_fields_attributes][${dataFieldIndex}][multiple_choice_options_attributes][${multipleChoiceIndex}][_destroy]`}
                  defaultValue={deleted}
                />
              </Fragment>
            ),
          )}
        </>
      )}
      {showConditions ? (
        conditionSelected ? (
          <>
            {showConditions.map((condition, index) => {
              return (
                <React.Fragment key={condition.id || condition.nanoid}>
                  <input
                    type="hidden"
                    defaultValue={condition.id}
                    name={`ask[data_fields_attributes][${dataFieldIndex}][show_condition_attributes][conditions_attributes][${index}][id]`}
                  />
                  <input
                    type="hidden"
                    defaultValue={condition.first_value}
                    name={`ask[data_fields_attributes][${dataFieldIndex}][show_condition_attributes][conditions_attributes][${index}][first_value]`}
                  />
                  <input
                    type="hidden"
                    defaultValue={condition.second_value}
                    name={`ask[data_fields_attributes][${dataFieldIndex}][show_condition_attributes][conditions_attributes][${index}][second_value]`}
                  />
                  <input
                    type="hidden"
                    defaultValue={condition.operator}
                    name={`ask[data_fields_attributes][${dataFieldIndex}][show_condition_attributes][conditions_attributes][${index}][operator]`}
                  />
                  <input
                    type="hidden"
                    defaultValue={condition.data_type}
                    name={`ask[data_fields_attributes][${dataFieldIndex}][show_condition_attributes][conditions_attributes][${index}][data_type]`}
                  />
                  <input
                    type="hidden"
                    defaultValue={condition._destroy}
                    name={`ask[data_fields_attributes][${dataFieldIndex}][show_condition_attributes][conditions_attributes][${index}][_destroy]`}
                  />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <input
            type="hidden"
            defaultValue={true}
            name={`ask[data_fields_attributes][${dataFieldIndex}][show_condition_attributes][_destroy]`}
          />
        )
      ) : null}
    </>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const DragAndDropDataFields = ({
  dataFields,
  setDataFields,
  errors,
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      dataFields,
      result.source.index,
      result.destination.index,
    );

    setDataFields(items);
  };

  const updateDataField = (newDataField, index) => {
    const newDataFields = dataFields.map((field, i) => {
      if (index === i) {
        return { ...field, ...newDataField };
      } else {
        return { ...field };
      }
    });

    setDataFields(newDataFields);
  };

  const deleteDataField = (index) => {
    const newDataFields = dataFields.map((field, i) => {
      if (index === i) {
        return { ...field, deleted: true };
      } else {
        return { ...field };
      }
    });

    setDataFields(newDataFields);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {dataFields.map(
              (
                {
                  id,
                  type,
                  richText,
                  disabled,
                  required,
                  deleted,
                  cssStyle,
                  multipleChoiceOptions,
                  currency,
                  hidden,
                  showConditions,
                  checkboxHidden,
                  defaultValue,
                },
                index,
              ) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      data-testid="DataFieldsSection/DataFieldContainer"
                    >
                      <>
                        {renderHiddenFields(
                          id,
                          type,
                          richText,
                          required,
                          deleted,
                          cssStyle,
                          index,
                          multipleChoiceOptions,
                          currency,
                          hidden,
                          showConditions,
                          checkboxHidden,
                          defaultValue,
                        )}
                        {!deleted && (
                          <DataField
                            id={id}
                            type={type}
                            richText={richText}
                            cssStyle={cssStyle}
                            multipleChoiceOptions={multipleChoiceOptions}
                            dragHandleProps={provided.dragHandleProps}
                            disabled={disabled}
                            required={required}
                            index={index}
                            currency={currency}
                            hidden={hidden}
                            showConditions={showConditions}
                            checkboxHidden={checkboxHidden}
                            defaultValue={defaultValue}
                            onChange={deleteDataField}
                            onSave={updateDataField}
                            errors={
                              errors?.data_fields && errors.data_fields[index]
                            }
                          />
                        )}
                      </>
                    </div>
                  )}
                </Draggable>
              ),
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragAndDropDataFields.propTypes = {
  dataFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
    }),
  ).isRequired,
  setDataFields: PropTypes.func.isRequired,
};
