import React from 'react';
import CssStyleHiddenInputs from '../../CssStyleHiddenInputs';

function HiddenInputs({ index, buttonId, cssStyle, richText, kind }) {
  return (
    <>
      <CssStyleHiddenInputs
        cssStyle={cssStyle}
        attributesName="ask[cta_button_style_attributes]"
      />
      {buttonId && (
        <input
          type="hidden"
          name={`ask[cta_buttons_attributes][${index}][id]`}
          value={buttonId}
        />
      )}
      <input
        type="hidden"
        name={`ask[cta_buttons_attributes][${index}][kind]`}
        defaultValue={kind}
      />
      {richText.id && (
        <input
          type="hidden"
          name={`ask[cta_buttons_attributes][${index}][rich_text_attributes][id]`}
          defaultValue={richText.id}
        />
      )}
      <input
        type="hidden"
        name={`ask[cta_buttons_attributes][${index}][rich_text_attributes][title]`}
        defaultValue={richText.title}
      />
      <input
        type="hidden"
        name={`ask[cta_buttons_attributes][${index}][rich_text_attributes][title_elements]`}
        defaultValue={richText.titleElements}
      />
      <input
        type="hidden"
        name={`ask[cta_buttons_attributes][${index}][rich_text_attributes][title_html]`}
        defaultValue={richText.titleHtml}
      />
    </>
  );
}

export default HiddenInputs;
