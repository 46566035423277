import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SimpleSelect from '../../../select';
import { setValueToStore } from '../../../../sharedStore';
import BlockContext from '../../../../contexts/BlockContext';

const options = [
  { value: 'free_response', label: 'Free Response' },
  { value: 'donation', label: 'Payment or Donation' },
  { value: 'multiple_choice', label: 'Multiple Choice' },
  { value: 'quick_answer', label: 'Yes / No' },
  { value: 'data_only', label: 'Data Fields' },
  { value: 'content', label: 'Content (no response)' },
];

function BlockTypeSelect({ blockType, updateBlockAttributes, disabled }) {
  const [, setBlockState] = useContext(BlockContext);
  const currentOption = options.find((type) => type.value === blockType);

  const handleTypeChange = (e) => {
    setValueToStore('blockFormChanged', true);

    const value = e.value;
    updateBlockAttributes({ type: value });
    // Update Context
    setBlockState((prevState) => ({ ...prevState, type: value }));
  };

  return (
    <SimpleSelect
      options={options}
      value={currentOption}
      isSearchable={false}
      name="ask[response_option]"
      classNamePrefix="react-select__block-type"
      onChange={handleTypeChange}
      isDisabled={disabled}
    />
  );
}

BlockTypeSelect.propTypes = {
  blockType: PropTypes.string.isRequired,
  updateBlockAttributes: PropTypes.func.isRequired,
};

export default BlockTypeSelect;
