import { useMemo } from 'react';
import { useTranslation } from '../../i18n';
import { getExperiencesList, getBlocksList, getMergeFields } from '../api';

const buildMentionSteps = (t) => [
  {
    type: 'experiences',
    title: t('mentions.expTitle'),
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: getBlocksList,
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: getMergeFields,
  },
];

function useBuildMentionsSteps({ translationsKeyPrefix }) {
  const { t } = useTranslation('translation', {
    keyPrefix: translationsKeyPrefix,
  });

  const mentionSteps = useMemo(() => buildMentionSteps(t), [t]);

  return mentionSteps;
}

export default useBuildMentionsSteps;
