import { slateToHtml } from '../TitleSection/utils';

export const handleCtaButtonChange = (value, elements, index, setBlockState) => {
  const richText = {
    title: value,
    titleElements: JSON.stringify(elements),
    titleHtml: slateToHtml(elements),
  };
  setBlockState((prevState) => {
    return {
      ...prevState,
      ctaButtons: prevState.ctaButtons.map((button, i) => {
        if (i === index) {
          return {
            ...button,
            richText: {
              id: button.richText.id,
              ...richText,
            },
          };
        } else {
          return button;
        }
      }),
    };
  });
};
