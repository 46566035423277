import React, { useReducer, useEffect, useMemo, useContext } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import MarginIcon from '@mui/icons-material/Margin';
import ExpandIcon from '@mui/icons-material/Expand';
import difference from 'lodash/xor';
import IconBox from './components/IconBox';
import BorderSides from './components/BorderSides';
import BorderWidth from './components/BorderWidth';
import BorderStyle from './components/BorderStyle';
import BoxShadow from './components/BoxShadow';
import BorderRadius from './components/BorderRadius';
import Color from './components/Color';
import Spacing from './components/Spacing';
import { useMuiPopover } from '../hooks';
import BlockContext from '../../contexts/BlockContext';
import reducer, {
  initialState,
  SET_STYLES,
  SET_BACKGROUND_COLOR,
  SET_COLOR,
  SET_BORDER_COLOR,
  SET_BORDER_SIDES,
  SET_BORDER_WIDTH,
  SET_BORDER_STYLE,
  SET_BOX_SHADOW,
  SET_BORDER_RADIUS,
  SET_SPACING,
} from './reducer';

function CustomizeToolbar({ cssStyle, isHover, handleSave, index, type, sx }) {
  const [{ paletteColors }] = useContext(BlockContext);
  const [state, dispatch] = useReducer(reducer, {});
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'CustomToolbar/Container',
  );

  const stylesDifference = useMemo(
    () => difference(Object.values(state), Object.values(initialState[type])),
    [state],
  );

  useEffect(() => {
    if (cssStyle) {
      dispatch({
        type: SET_STYLES,
        payload: { styles: cssStyle, type },
      });
    }
  }, []);

  useEffect(() => {
    if (stylesDifference.length) {
      handleSave({ cssStyle: state }, index);
    }
  }, [state]);

  const handleSetBackgroundColor = (color) => {
    dispatch({
      type: SET_BACKGROUND_COLOR,
      payload: { color },
    });
  };

  const handleSetColor = (color) => {
    dispatch({
      type: SET_COLOR,
      payload: { color },
    });
  };

  const handleSetBorderColor = (color) => {
    dispatch({
      type: SET_BORDER_COLOR,
      payload: { color },
    });
  };

  const handleSetBorderSides = (styles) => {
    dispatch({
      type: SET_BORDER_SIDES,
      payload: { styles },
    });
  };

  const handleSetBorderWidth = (width) => {
    dispatch({
      type: SET_BORDER_WIDTH,
      payload: { width },
    });
  };

  const handleSetBorderStyle = (style) => {
    dispatch({
      type: SET_BORDER_STYLE,
      payload: { style },
    });
  };

  const handleSetBoxShadow = (shadow) => {
    dispatch({
      type: SET_BOX_SHADOW,
      payload: { shadow },
    });
  };

  const handleSetBorderRadius = (radius) => {
    dispatch({
      type: SET_BORDER_RADIUS,
      payload: { radius },
    });
  };

  const handleSetSpacing = (styles) => {
    dispatch({
      type: SET_SPACING,
      payload: { styles },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '28px',
        height: '28px',
        borderRadius: '4px',
        ...(sx ? sx : {}),
      }}
    >
      {(isHover || open) && (
        <>
          <IconBox isOpen={open}>
            <FormatPaintOutlinedIcon
              onClick={handleOpen}
              sx={{
                fontSize: '20px',
                color: '#333',
                opacity: '0.54',
              }}
            />
          </IconBox>
          <Popover
            id={id}
            data-testid={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            sx={{
              '& .MuiPopover-paper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px 8px',
                borderRadius: '16px',
              },
            }}
          >
            <Color
              title="Background color"
              Icon={FormatColorFillIcon}
              initialColor={state.backgroundColor || ''}
              onChangeComplete={handleSetBackgroundColor}
              paletteColors={paletteColors}
            />
            <Color
              title="Color"
              Icon={FormatColorTextIcon}
              initialColor={state.color || ''}
              onChangeComplete={handleSetColor}
              paletteColors={paletteColors}
            />
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{ margin: '0 10px' }}
              flexItem
            />
            <Color
              title="Border color"
              Icon={BorderColorOutlinedIcon}
              initialColor={state.borderColor || ''}
              onChangeComplete={handleSetBorderColor}
              paletteColors={paletteColors}
            />
            <BorderSides
              initialStyles={{
                borderTop: state.borderTop || '',
                borderRight: state.borderRight || '',
                borderBottom: state.borderBottom || '',
                borderLeft: state.borderLeft || '',
              }}
              handleSetBorderSides={handleSetBorderSides}
            />
            <BorderWidth
              initialWidth={state.borderWidth || ''}
              handleSetBorderWidth={handleSetBorderWidth}
            />
            <BorderStyle
              initialStyle={state.borderStyle || ''}
              handleSetBorderStyle={handleSetBorderStyle}
            />
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{ margin: '0 10px' }}
              flexItem
            />
            <BoxShadow
              initialShadow={state.boxShadow || ''}
              handleSetBoxShadow={handleSetBoxShadow}
            />
            <BorderRadius
              initialRadius={state.borderRadius || ''}
              handleSetBorderRadius={handleSetBorderRadius}
              roundedCornersValue={
                (initialState[type] && initialState[type].borderRadius) || '8px'
              }
            />
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{ margin: '0 10px' }}
              flexItem
            />
            <Spacing
              type="padding"
              Icon={ExpandIcon}
              values={{
                vertical: state.paddingTop || '',
                horizontal: state.paddingRight || '',
              }}
              onChange={handleSetSpacing}
            />
            <Spacing
              type="margin"
              Icon={MarginIcon}
              values={{
                vertical: state.marginTop || '',
                horizontal: state.marginRight || '',
              }}
              onChange={handleSetSpacing}
            />
          </Popover>
        </>
      )}
    </Box>
  );
}

export default CustomizeToolbar;
