import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Label } from '../../../../../globals';
import BlockContext from '../../../../../../contexts/BlockContext';
import ColorPickerInput from '../../../../../inputs/ColorPickerInput';
import { rgbaObjToString } from '../../../../../../utils';
import { getInitialColor } from '../../../../../CustomizeToolbar/utils';

function BackgroundColor({
  label,
  defaultColor,
  blockStateAttribute,
  hiddenInputAttribute,
}) {
  const [blockState, setBlockState] = useContext(BlockContext);
  const [color, setColor] = useState(getInitialColor(defaultColor, blockState.paletteColors));

  const updateBlockState = (colorValue) => {
    setBlockState((prevState) => ({
      ...prevState,
      backgroundSetting: {
        ...prevState.backgroundSetting,
        [blockStateAttribute]: colorValue,
      },
    }));
  };

  const updateColor = (value) => {
    const rgbaString = rgbaObjToString(value);
    setColor(rgbaString);
    updateBlockState(rgbaString);
  };

  const handleColorPick = (colorObj) => {
    setColor(colorObj.color);
    updateBlockState(colorObj.name);
  };

  return (
    <>
      <Label>{label}</Label>
      <ColorPickerInput
        color={color}
        onChange={updateColor}
        onChangeComplete={updateColor}
        onColorPick={handleColorPick}
        paletteColors={blockState.paletteColors}
        withAlpha
      />
      <input
        type="hidden"
        name={`ask[background_setting_attributes][${hiddenInputAttribute}]`}
        value={blockState.backgroundSetting && blockState.backgroundSetting[blockStateAttribute]}
      />
    </>
  );
}

BackgroundColor.propTypes = {
  defaultColor: PropTypes.string,
};

export default BackgroundColor;
