import React, { useMemo, useState } from 'react';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { withMention } from '../Mention';
import { withSingleLine } from '../utils';
import EditableMentionsElement from '../EditableMentionsElement';
import DraggableInput from '../DraggableInput';
import ErrorMessage from '../../../shared/ErrorMessage';
import {
  slateToHtml,
  slateToElements,
} from '../../../BlockForm/components/TitleSection/utils';
import { withLinks } from '../../../BlockForm/components/TitleSection/components/LinksButton/utils';

function EditableDraggableMentionsInput(props) {
  const {
    placeholder,
    inputStyles,
    error,
    index,
    dataTarget,
    onChange,
    dataTestId,
    optionRichTextId,
    optionName,
    renderHiddenInputs,
    isDraggingOver,
  } = props;
  const [value, setValue] = useState({ text: '', elements: '', html: '' });

  const handleValueChange = (textValue, elements) => {
    const htmlValue = slateToHtml(elements);
    const elementsValue = JSON.stringify(slateToElements(elements));
    setValue({ text: textValue, html: htmlValue, elements: elementsValue });
    onChange(textValue, elementsValue, htmlValue);
  };

  const editor = useMemo(
    () => withMention(withLinks(withSingleLine(withHistory(withReact(createEditor()))))),
    [],
  );

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <EditableMentionsElement
        {...props}
        onValueChange={handleValueChange}
        editor={editor}
        key={optionName}
        autoFocus={!value.text.length}
        render={(renderElement, renderLeaf, handleKeyDown) => (
          <DraggableInput
            editor={editor}
            noMargin
            data-testid={dataTestId}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={(e) => {
              handleKeyDown(e);
              props.onKeyDown(e);
            }}
            placeholder={placeholder}
            spellCheck={false}
            style={inputStyles}
            autoFocus
            data-target={dataTarget}
            isDraggingOver={isDraggingOver}
          />
        )}
      />
      {renderHiddenInputs && (
        <>
          {optionRichTextId && (
            <input
              type="hidden"
              name={`ask[multiple_choice_options_attributes][${index}][rich_text_attributes][id]`}
              value={optionRichTextId}
            />
          )}
          <input
            type="hidden"
            name={`ask[multiple_choice_options_attributes][${index}][rich_text_attributes][title]`}
            value={value.text}
            data-testid={dataTestId}
          />
          <input
            type="hidden"
            name={`ask[multiple_choice_options_attributes][${index}][rich_text_attributes][title_elements]`}
            value={value.text ? value.elements : ''}
          />
          <input
            type="hidden"
            name={`ask[multiple_choice_options_attributes][${index}][rich_text_attributes][title_html]`}
            value={value.text ? value.html : ''}
          />
        </>
      )}
      {error && <ErrorMessage text={error} position="right-top" />}
    </div>
  );
}

export default EditableDraggableMentionsInput;
