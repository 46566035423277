import React, { useState, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../../i18n';
import TilesSelector from '../../../tilesSelector';
import { MergeMediaSubLabel } from './style';
import { Hint, Legend, FormSection, Label, Fieldset } from '../../../globals';
import { EditableReadOnlyMentionsInput } from '../../../SlateElements';
import FullScreenIcon from './images/background-layout--full-screen.png';
import FitToSizeIcon from './images/background-layout--fit-to-size.png';
import { ExperienceIdContext } from '../..';
import BackgroundColor from './components/BackgroundColor';
import BlockContext from '../../../../contexts/BlockContext';

import {
  getExperiencesList,
  getBlocksList,
  getCustomMergeFields,
} from '../../../api';
import MediaOptions from './components/MediaOptions';

const queryParams = { block_type: 'free_response' };

const buildMentionSteps = (t) => [
  {
    type: 'experiences',
    title: t('mentions.expTitle'),
    referencePrefix: 'experience_',
    loadList: () => getExperiencesList(queryParams),
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: (experienceId) => getBlocksList(experienceId, queryParams),
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: () =>
      getCustomMergeFields([{ title: 'Media', value: 'data.media' }]),
  },
];

const options = [
  {
    name: 'full_screen',
    value: 'full_screen',
    label: 'Full Screen',
    testId: 'BackgroundLayout/fullScreen',
    icon: FullScreenIcon,
  },
  {
    name: 'fit_to_size',
    value: 'fit_to_size',
    label: 'Fit-to-size',
    testId: 'BackgroundLayout/fitToSize',
    icon: FitToSizeIcon,
  },
];

function BackgroundLayout({
  layoutValue,
  referenceMergeMedia,
  backgroundSetting,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'BackgroundLayout',
  });
  const [checkedValue, setCheckedValue] = useState(layoutValue);
  const experienceId = useContext(ExperienceIdContext);
  const [, setBlockState] = useContext(BlockContext);
  const { id, mediaColorOverlay, mediaHiddenOnMobile } = backgroundSetting;
  const mentionSteps = useMemo(() => buildMentionSteps(t), [t]);

  const updateMediaLayout = useCallback((value) => {
    setCheckedValue(value);

    setBlockState((prevState) => ({
      ...prevState,
      backgroundLayout: value,
    }));
  }, []);

  return (
    <div data-testid="MediaSettings/BackgroundLayout">
      <Legend>Media style</Legend>
      <FormSection backgroundColor="var(--background-secondary)">
        <Label>Media Layout</Label>
        <TilesSelector
          options={options}
          defaultValue={checkedValue}
          onChange={updateMediaLayout}
        />
        <Fieldset>
          <BackgroundColor
            label="Media color overlay"
            defaultColor={mediaColorOverlay}
            blockStateAttribute="mediaColorOverlay"
            hiddenInputAttribute="media_color_overlay"
          />
        </Fieldset>
        <MediaOptions mediaHiddenOnMobile={mediaHiddenOnMobile} />
      </FormSection>
      <MergeMediaSubLabel>Merge media</MergeMediaSubLabel>
      <Hint>{t('hint')}</Hint>
      <EditableReadOnlyMentionsInput
        name="ask[reference_merge_media]"
        experienceId={experienceId}
        initialValue={referenceMergeMedia}
        placeholder="Search free response blocks"
        steps={mentionSteps}
        inputProps={{ 'data-testid': 'BackgroundLayout/MergeMediaInput' }}
      />
      <input type="hidden" name="ask[background_layout]" value={checkedValue} />
      <input
        type="hidden"
        name="ask[background_setting_attributes][id]"
        value={id}
      />
    </div>
  );
}

BackgroundLayout.propTypes = {
  layoutValue: PropTypes.string.isRequired,
  referenceMergeMedia: PropTypes.string,
  backgroundSetting: PropTypes.shape({
    id: PropTypes.number,
    backgroundColorOverlay: PropTypes.string,
    backgroundMediaFileName: PropTypes.string,
    backgroundMediaImageUrl: PropTypes.string,
    backgroundMediaOpacity: PropTypes.string,
    backgroundMediaPreviewUrl: PropTypes.string,
    backgroundMediaVideoSources: PropTypes.arrayOf(PropTypes.string),
    mediaColorOverlay: PropTypes.string,
  }),
  errors: PropTypes.object.isRequired,
};

export default BackgroundLayout;
