import styled from 'styled-components';

export const DonationInputWrapper = styled.div`
  position: relative;
  width: calc(100% - 45px);
  margin-bottom: 5px;
`;

export const RightButtonWrapper = styled.div`
  position: absolute;
  right: -62px;
  top: 2px;
  width: 45px;
  height: 48px;
`;
