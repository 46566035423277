import styled from 'styled-components';

export const DonationInputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const RightButtonWrapper = styled.div`
  width: 45px;
  justify-content: flex-end;
  display: flex;
  height: 48px;
`;

export const InputsWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const LeftPadding = styled.div`
  padding: ${(props) => (props.inline ? '0 0 0 1.5rem' : '0')};
  margin-top: ${(props) => (props.inline ? '-15px' : '0px')};
`;

export const ErrorMessage = styled.span`
  border-radius: 5px;
  text-align: center;
  background-color: var(--error);
  color: var(--white);
  font-size: 12px;
  padding: 5px 12px;
  position: relative;
  float: right;
  z-index: 5;
`;

export const TooltipIconWrapper = styled.div`
  margin-left: 8px;
`;

export const CheckboxTooltipIconWrapper = styled.div`
  margin-left: -4px;
  display: flex;
`;
