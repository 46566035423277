import React from 'react';
import Box from '@mui/material/Box';
import ExpandIcon from '@mui/icons-material/Expand';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const valuesMapper = {
  vertical: {
    focus: true,
    rotate: 'rotate(0deg)',
  },
  horizontal: {
    focus: false,
    rotate: 'rotate(90deg)',
  },
};

export default function InputWithAdornments({
  inputValue,
  unitValue,
  onChange,
  orientation,
}) {
  const handleInputChange = (e) => {
    onChange(e.target.value, orientation, unitValue);
  };

  const handleUnitChange = (e) => {
    onChange(inputValue, orientation, e.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D6D6D6',
        borderRadius: '8px',
        height: 36,
        overflow: 'hidden',
      }}
    >
      <TextField
        value={inputValue}
        onChange={handleInputChange}
        type="number"
        aria-label={orientation}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            display: 'none',
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            pl: 1,
            pr: 0,
          },
          '& .MuiInputBase-input::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& .MuiInputBase-input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        }}
        InputProps={{
          autoFocus: valuesMapper[orientation].focus,
          sx: {
            '& .MuiInputBase-input': {
              p: 1,
              '&:focus': {
                boxShadow: 'none',
              },
            },
          },
          startAdornment: (
            <ExpandIcon
              sx={{
                color: 'rgba(51, 51, 51, 0.54)',
                transform: valuesMapper[orientation].rotate,
              }}
            />
          ),
          endAdornment: (
            <FormControl sx={{ minWidth: 'unset' }}>
              <Select
                sx={{
                  minWidth: 68,
                  borderLeft: '1px solid rgb(214, 214, 214)',
                }}
                value={unitValue}
                label="Units"
                onChange={handleUnitChange}
              >
                <MenuItem value="px">px</MenuItem>
                <MenuItem value="%">%</MenuItem>
              </Select>
            </FormControl>
          ),
        }}
        variant="outlined"
        hiddenLabel
      />
    </Box>
  );
}
