import styled from 'styled-components';

export const PreviewContainer = styled.div`
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0 1rem 0;
  font-size: 13px;

  span {
    font-size: 13px;
  }

  a {
    color: var(--font-color-primary);
  }
`;
