import React from 'react';
import MUISlider, { sliderClasses } from '@mui/material/Slider';
import {
  APP_SETTINGS_PRIMARY_COLOR,
  APP_SETTINGS_PRIMARY_COLOR_TRANSPARENTIZED,
} from '../../constants';

export default function Slider(props) {
  return (
    <MUISlider
      sx={{
        [`&.${sliderClasses.root}`]: {
          width: 'calc(100% - 3rem)',
          color: 'transparent',
          height: 48,
          ml: '18px',
          pt: 0,
          pb: '10px',
        },
        [`& .${sliderClasses.thumb}`]: {
          top: 25,
          width: 38,
          height: 38,
          ml: '8px',
          backgroundColor: APP_SETTINGS_PRIMARY_COLOR,
          [`&:focus, &:hover, &.${sliderClasses.active}`]: {
            boxShadow: `0px 0px 0px 8px ${APP_SETTINGS_PRIMARY_COLOR_TRANSPARENTIZED}`,
          },
        },
        [`& .${sliderClasses.active}`]: {},
        [`& .${sliderClasses.valueLabel}`]: {
          width: 32,
          height: 32,
          top: 34,
          left: 3,
          background: 'transparent',
          fontSize: '1rem',
          fontFamily: 'inherit',
        },
        [`& .${sliderClasses.track}`]: {
          background: 'transparent',
        },
        [`& .${sliderClasses.rail}`]: {
          height: 48,
          borderRadius: '45px',
          pr: '49px',
          top: 'unset',
          transform: 'translateX(-18px)',
          backgroundColor: '#fff',
          border: '1px solid var(--border-color-primary)',
          opacity: 1,
        },
        [`& .${sliderClasses.mark}`]: {
          display: 'none',
        },
        [`& .${sliderClasses.markLabel}`]: {
          fontSize: 12,
          transform: 'translateX(2px)',
          color: 'var(--font-color-primary)',
          top: 60,
        },
      }}
      defaultValue={1}
      aria-labelledby="discrete-slider-restrict"
      valueLabelDisplay="on"
      {...props}
    />
  );
}
