import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Label } from '../../../../../globals';
import InputSlider from '../../../InputSlider';
import UploadForm from './components/UploadForm';
import Preview from './components/Preview';
import BlockContext from '../../../../../../contexts/BlockContext';
import { SectionWrapper } from './style';

const BackgroundContent = ({
  directUploadUrl,
  defaultSignedId,
  fileName,
  previewUrl,
  mediaOpacity,
  errors,
}) => {
  const [signedId, setSignedId] = React.useState(defaultSignedId);
  const [currentFilename, setCurrentFilename] = React.useState(fileName);
  const [currentMediaUrl, setCurrentMediaUrl] = React.useState(previewUrl);
  const [mediaAttached, setMediaAttached] = React.useState(previewUrl);
  const [contentType, setContentType] = React.useState(null);
  const [opacity, setOpacity] = React.useState(mediaOpacity);
  const [mediaIsUploading, setMediaIsUploading] = React.useState(false);
  const [, setBlockState] = useContext(BlockContext);

  useEffect(() => {
    const error =
      errors['background_setting.background_media'] &&
      errors['background_setting.background_media'][0];

    if (error) {
      setMediaAttached(false);
    }
  }, []);

  useEffect(() => {
    if (currentMediaUrl && contentType && !mediaIsUploading) {
      const backgroundIsImage = contentType.includes('image');
      const backgroundIsVideo = contentType.includes('video');
      const propertyToUpdate = backgroundIsImage ?
        'backgroundMediaImageUrl' :
        'backgroundMediaVideoSources';

      setBlockState((prevState) => ({
        ...prevState,
        backgroundSetting: {
          ...prevState.backgroundSetting,
          [propertyToUpdate]: [currentMediaUrl],
          backgroundMediaIsImage: backgroundIsImage,
          backgroundMediaIsVideo: backgroundIsVideo,
        },
      }));
    }
  }, [currentMediaUrl, contentType, mediaIsUploading]);

  const removeMedia = useCallback(() => {
    if (mediaAttached) {
      setSignedId('');
    } else {
      setSignedId(null);
    }

    setMediaAttached(false);

    setBlockState((prevState) => ({
      ...prevState,
      backgroundSetting: {
        ...prevState.backgroundSetting,
        backgroundMediaImageUrl: null,
        backgroundMediaVideoSources: null,
        backgroundMediaIsImage: false,
        backgroundMediaIsVideo: false,
      },
    }));
  }, [mediaAttached]);

  const attachMedia = useCallback((signedId) => {
    setSignedId(signedId);

    setMediaAttached(true);
  }, []);

  const updateOpacity = useCallback((value) => {
    const preparedValue = value / 100;

    setOpacity(preparedValue);
    setBlockState((prevState) => ({
      ...prevState,
      backgroundSetting: {
        ...prevState.backgroundSetting,
        backgroundMediaOpacity: preparedValue,
      },
    }));
  }, []);

  return (
    <>
      <Label>Upload content</Label>
      <SectionWrapper>
        {mediaAttached ? (
          <Preview
            removeMedia={removeMedia}
            previewUrl={currentMediaUrl}
            fileName={currentFilename}
            contentType={contentType}
          />
        ) : (
          <UploadForm
            attachMedia={attachMedia}
            mediaIsUploading={mediaIsUploading}
            setMediaIsUploading={setMediaIsUploading}
            setFilename={setCurrentFilename}
            setCurrentMediaUrl={setCurrentMediaUrl}
            setContentType={setContentType}
            directUploadUrl={directUploadUrl}
          />
        )}
      </SectionWrapper>
      {signedId !== null && (
        <input
          type="hidden"
          name="ask[background_setting_attributes][background_media]"
          value={signedId}
        />
      )}
      <SectionWrapper>
        <Label>Media opacity</Label>
        <InputSlider
          defaultValue={Math.trunc(opacity * 100)}
          onChange={updateOpacity}
        />
        <input
          type="hidden"
          name="ask[background_setting_attributes][background_media_opacity]"
          value={opacity}
        />
      </SectionWrapper>
    </>
  );
};

BackgroundContent.propTypes = {
  directUploadUrl: PropTypes.string,
  defaultSignedId: PropTypes.string,
  fileName: PropTypes.string,
  previewUrl: PropTypes.string,
  mediaOpacity: PropTypes.string,
  errors: PropTypes.object.isRequired,
};

export default BackgroundContent;
