import React from 'react';
import PropTypes from 'prop-types';
import { StyledDisabledSelect, StyledSelect } from './style';
import { CURRENCY_SIGN_MAP } from '../../../../../constants';

function CurrencySelector({ currency, disabled, onChange }) {
  if (disabled) {
    return (
      <StyledDisabledSelect>
        {`${CURRENCY_SIGN_MAP[currency]} ${currency}`}
      </StyledDisabledSelect>
    );
  } else {
    return (
      <StyledSelect
        name="ask[currency]"
        defaultValue={currency}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="usd">$ USD</option>
        <option value="gbp">£ GBP</option>
      </StyledSelect>
    );
  }
}

CurrencySelector.propTypes = {
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CurrencySelector;
