import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-secondary);
  margin-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  border: 1px dashed var(--border-color-primary);
  border-radius: 8px;
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  max-width: 152px;
  margin-top: 50px;
`;

export const HelperText = styled.p`
  font-size: 15px;
  color: #bdbdbd;
  text-align: center;
  margin-bottom: 45px;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: 8px;
`;
