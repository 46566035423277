import React from 'react';
import _isEmpty from 'lodash/isEmpty';

export default function DestroyHiddenInputs({ blockState }) {
  if (blockState.showNavigationButtons || _isEmpty(blockState.navigationButtons)) {
    return null;
  }

  return (
    <>
      <input
        type="hidden"
        name="ask[navigation_buttons_attributes][0][id]"
        value={blockState.navigationButtons[0].id}
      />
      <input
        type="hidden"
        name="ask[navigation_buttons_attributes][1][id]"
        value={blockState.navigationButtons[1].id}
      />
      <input
        type="hidden"
        name="ask[navigation_buttons_attributes][0][_destroy]"
        value={!blockState.showNavigationButtons}
      />
      <input
        type="hidden"
        name="ask[navigation_buttons_attributes][1][_destroy]"
        value={!blockState.showNavigationButtons}
      />
    </>
  );
}
