import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../inputs/Input';
import {
  Fieldset,
  Label,
  FormSection,
  FlexRowSpaceBetween,
  FlexCol,
} from '../../../../globals';

const MAX_VALID_VALUE = 96;
const ERROR_MAX_VALUE_PROPERTY = 'title_setting.font_size_max';
const ERROR_MIN_VALUE_PROPERTY = 'title_setting.font_size_min';
const ERROR_MESSAGE = 'Must be between 1 and 96';

const errorKeysMapper = {
  fontSizeMin: ERROR_MIN_VALUE_PROPERTY,
  fontSizeMax: ERROR_MAX_VALUE_PROPERTY,
};

function FontSizeSection({ defaultValues, updateContext, defaultErrors }) {
  const [inputValues, setInputValues] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultErrors);

  const clearError = (property) => {
    setErrors({ ...errors, [property]: [] });
  };

  const validate = (ctxProperty, value) => {
    const errorKey = errorKeysMapper[ctxProperty];

    if (value < 1 || value > MAX_VALID_VALUE) {
      setErrors({
        ...errors,
        [errorKey]: [ERROR_MESSAGE],
      });
    } else {
      updateContext(ctxProperty, value);
    }
  };

  const handleMaxValueChange = (value, ctxProperty) => {
    clearError(errorKeysMapper[ctxProperty]);
    validate(ctxProperty, value);
    setInputValues({
      ...inputValues,
      [ctxProperty]: value,
    });
  };

  return (
    <Fieldset data-testid="PromptSettings/fontSizeLimits">
      <Label>font size limits</Label>
      <FormSection backgroundColor="var(--background-secondary)">
        <FlexRowSpaceBetween>
          <FlexCol style={{ width: '48%' }}>
            <Label>min</Label>
            <Input
              type="number"
              value={inputValues.fontSizeMin}
              name="ask[title_setting_attributes][font_size_min]"
              error={
                errors[ERROR_MIN_VALUE_PROPERTY] &&
                errors[ERROR_MIN_VALUE_PROPERTY][0]
              }
              onChange={(e) =>
                handleMaxValueChange(e.target.value, 'fontSizeMin')
              }
            />
          </FlexCol>
          <FlexCol style={{ width: '48%' }}>
            <Label>max</Label>
            <Input
              type="number"
              value={inputValues.fontSizeMax}
              name="ask[title_setting_attributes][font_size_max]"
              error={
                errors[ERROR_MAX_VALUE_PROPERTY] &&
                errors[ERROR_MAX_VALUE_PROPERTY][0]
              }
              onChange={(e) =>
                handleMaxValueChange(e.target.value, 'fontSizeMax')
              }
            />
          </FlexCol>
        </FlexRowSpaceBetween>
      </FormSection>
    </Fieldset>
  );
}

FontSizeSection.propTypes = {
  defaultValues: PropTypes.shape({
    fontSizeMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fontSizeMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  updateContext: PropTypes.func,
  defaultErrors: PropTypes.object,
};

export default FontSizeSection;
