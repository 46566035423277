import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import { EditableMentionsInput } from '../../../SlateElements';
import { FormSection, Label } from '../../../globals';
import SimpleSelect from '../../../select';
import MultipleChoiceSection from '../MultipleChoiceSection';
import { ButtonsWrapper, ActionButton } from './style';
import {
  AlignLeft,
  User,
  Mail,
  MapPin,
  Phone,
  Calendar,
  Briefcase,
  List,
} from 'react-feather';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import Box from '@mui/material/Box';
import { SwitchWithTitle } from '../../../switch';
import { ExperienceIdContext } from '../..';
import useBuildMentionsSteps from '../../../hooks/useBuildMentionsSteps';
import DefaultValue from './components/DefaultValue';
import { slateToHtml } from '../TitleSection/utils';
import { buildDefaultRichObject } from '../../../../utils';
import ShowFieldSection from './components/ShowFieldSection';

const options = [
  { value: 'text', label: 'Text (Default)', icon: <AlignLeft /> },
  { value: 'paragraph_text', label: 'Paragraph text', icon: <AlignLeft /> },
  { value: 'first_name', label: 'First name', icon: <User /> },
  { value: 'last_name', label: 'Last name', icon: <User /> },
  { value: 'full_name', label: 'Full name', icon: <User /> },
  { value: 'address', label: 'Street address', icon: <MapPin /> },
  { value: 'city', label: 'City', icon: <MapPin /> },
  { value: 'state', label: 'State', icon: <MapPin /> },
  { value: 'zip_code', label: 'Zip', icon: <MapPin /> },
  { value: 'country', label: 'Country', icon: <MapPin /> },
  { value: 'phone', label: 'Phone', icon: <Phone /> },
  { value: 'email', label: 'Email', icon: <Mail /> },
  { value: 'date_of_birth', label: 'Date', icon: <Calendar /> },
  { value: 'multiple_choice', label: 'Multiple choice', icon: <List /> },
  {
    value: 'money',
    label: 'Currency',
    icon: <PaymentsOutlinedIcon />,
    currency: 'usd',
  },
  {
    value: 'currently_employed',
    label: 'Currently employed',
    icon: <Briefcase />,
  },
  { value: 'integer', label: 'Number', icon: <LooksOneOutlinedIcon /> },
  { value: 'company', label: 'Company', icon: <Briefcase /> },
  { value: 'occupation', label: 'Occupation', icon: <Briefcase /> },
  { value: 'checkbox', label: 'Checkbox', icon: <CheckBoxOutlinedIcon /> },
  { value: 'rich_text', label: 'Rich text', icon: <AlignLeft /> },
  { value: 'upload', label: 'Upload', icon: <UploadFileIcon /> },
  { value: 'signature', label: 'Signature', icon: <DrawOutlinedIcon /> },
];

const currencyOptions = [
  { value: 'usd', label: '$ USD' },
  { value: 'gbp', label: '£ GBP' },
];

const defaultDataField = {
  type: 'text',
  required: false,
  deleted: false,
  hidden: false,
  checkboxHidden: false,
  multipleChoiceOptions: [],
  richText: { title: '', titleHtml: '', titleElements: '' },
};

const filterDeletedMultipleChoiceOptions = (items) =>
  items.filter((item) => !item.deleted);

function DataFieldEditForm({
  isActive,
  setIsActive,
  onSave,
  initialDataField,
  errors,
}) {
  const [dataField, setDataField] = useState(
    initialDataField || defaultDataField,
  );
  const [error, setError] = useState(null);
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(
    dataField.multipleChoiceOptions,
  );
  const experienceId = React.useContext(ExperienceIdContext);
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'TitleSection',
  });

  const handleChange = (value, elements) => {
    setDataField({
      ...dataField,
      richText: {
        title: value,
        titleElements: JSON.stringify(elements),
        titleHtml: slateToHtml(elements),
      },
    });
  };

  const handleDataFieldChange = (values) => {
    setDataField({ ...dataField, ...values });
  };

  const handleTypeChange = (value) => {
    const option = options.find((option) => option.value === value);
    const optionsWithoutDefaultValue = ['text', 'paragraph_text'];

    if (optionsWithoutDefaultValue.includes(option.value)) {
      setDataField({
        ...dataField,
        type: value,
        currency: option.currency,
      });
    } else {
      setDataField({
        ...dataField,
        type: value,
        currency: option.currency,
        richText: buildDefaultRichObject(option.label),
      });
    }
  };

  const setSelectDefaultValue = () => {
    const filteredOptions = options.filter(
      (option) => option.value === dataField.type,
    );
    return filteredOptions[0];
  };

  const handleFormSave = (e) => {
    e.preventDefault();
    const trimmedTitle = dataField.richText.title.trim();

    if (_isEmpty(trimmedTitle)) {
      setError("Can't be empty");
    } else if (dataField.required && dataField.hidden) {
      setError("Can't be required and hidden");
    } else {
      const newDataField = {
        ...dataField,
        richText: {
          ...dataField.richText,
          title: trimmedTitle,
        },
        multipleChoiceOptions,
      };

      onSave(newDataField);
    }
  };

  const handleHideCheckbox = (e) => {
    const checked = e.target.checked;
    handleDataFieldChange({
      defaultValue: checked.toString(),
      checkboxHidden: checked,
    });
  };

  if (!isActive) {
    return null;
  }

  return (
    <FormSection
      style={{ marginBottom: '8px' }}
      backgroundColor="var(--surface-background--dark)"
      data-testid="DataFieldsSection/DataFieldEditForm"
    >
      <Box sx={{ mb: 3 }}>
        <Label>Type</Label>
        <SimpleSelect
          options={options}
          defaultValue={setSelectDefaultValue()}
          placeholder="Choose a field type"
          classNamePrefix="react-select__data-field-type"
          onChange={({ value }) => handleTypeChange(value)}
        />
      </Box>
      <Label>Field name</Label>
      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        data-testid="DataFieldEditForm/Name"
      >
        <EditableMentionsInput
          name="DataFieldEditForm/mentionsInput"
          experienceId={experienceId}
          steps={mentionSteps}
          placeholder="Name field"
          key={`name_input_${dataField.type}`}
          initialValue={
            dataField.richText.titleElements
              ? JSON.parse(dataField.richText.titleElements)
              : dataField.richText.title
          }
          dataTestId="DataFieldEditForm/NameInput"
          error={error}
          onValueChange={handleChange}
          withHiddenInput
          autoFocus
          multirows
          customizable
        />
        {dataField.type === 'money' && (
          <Box sx={{ width: 135 }}>
            <SimpleSelect
              options={currencyOptions}
              defaultValue={
                dataField.currency
                  ? currencyOptions.find((o) => o.value === dataField.currency)
                  : currencyOptions[0]
              }
              classNamePrefix="react-select__data-field-currency"
              onChange={({ value }) =>
                handleDataFieldChange({ currency: value })
              }
              noMargin
              isDisabled={dataField.id}
            />
          </Box>
        )}
      </Box>
      {dataField.type === 'multiple_choice' && (
        <div style={{ marginTop: '24px' }}>
          <MultipleChoiceSection
            optionsList={filterDeletedMultipleChoiceOptions(
              multipleChoiceOptions,
            )}
            withoutCheckboxes
            withoutInputNames
            onOptionsChange={setMultipleChoiceOptions}
          />
        </div>
      )}
      <Box sx={{ mt: 3 }}>
        {(dataField.type !== 'upload' || dataField.type !== 'signature') && (
          <>
            {dataField.type !== 'checkbox' && <Label>Default Value</Label>}
            <DefaultValue
              type={dataField.type}
              experienceId={experienceId}
              mentionSteps={mentionSteps}
              onChange={handleDataFieldChange}
              multipleChoiceOptions={multipleChoiceOptions}
              defaultValue={dataField.defaultValue}
              defaultValueSwitcherDisabled={dataField.checkboxHidden}
            />
          </>
        )}
      </Box>
      <Box sx={{ pb: '46px' }}>
        <SwitchWithTitle
          onChange={(e) =>
            handleDataFieldChange({ required: e.target.checked })
          }
          title="Required Field"
          data-testid="DataFieldEditForm/RequiredSwitcher"
          noborder="true"
          checked={dataField.required}
        />
        {dataField.type === 'checkbox' && (
          <SwitchWithTitle
            onChange={handleHideCheckbox}
            title="Hide Checkbox"
            data-testid="DataFieldEditForm/HideCheckboxSwitcher"
            noborder="true"
            checked={dataField.checkboxHidden}
          />
        )}
        <ShowFieldSection
          selectedValue={_isNull(dataField.hidden) ? 'condition' : dataField.hidden}
          onChange={handleDataFieldChange}
          mentionSteps={mentionSteps}
          experienceId={experienceId}
          showConditions={dataField.showConditions}
          errors={errors}
        />
      </Box>
      <ButtonsWrapper>
        <ActionButton
          data-testid="DataFieldEditForm/CancelButton"
          onClick={() => setIsActive(false)}
        >
          Cancel
        </ActionButton>
        <ActionButton
          primary
          data-testid="DataFieldEditForm/SaveButton"
          onClick={handleFormSave}
        >
          Save Field
        </ActionButton>
      </ButtonsWrapper>
    </FormSection>
  );
}

DataFieldEditForm.propTypes = {
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialDataField: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    hidden: PropTypes.bool,
  }),
};

export default DataFieldEditForm;
