import React, { useState, useEffect } from 'react';
import { StyledButton } from './style';
import { useDebounce } from '../../../hooks';

const DEBOUNCE_DELAY = 100;

const SubmitButton = ({ children }) => {
  const [buttonWidth, setButtonWidth] = useState(null);
  const [leftMargin, setLeftMargin] = useState(null);
  const debouncedButtonWidth = useDebounce(buttonWidth, DEBOUNCE_DELAY);
  const debouncedLeftMargin = useDebounce(leftMargin, DEBOUNCE_DELAY);

  useEffect(() => {
    const settingsContainer = document.querySelector('#askEditSettings');
    const blockListContainer = document.querySelector('#askEditBlocks');

    if (settingsContainer) {
      setButtonWidth(settingsContainer.scrollWidth);
    }

    if (blockListContainer) {
      setLeftMargin(blockListContainer.offsetWidth);
    }

    const handleResize = () => {
      setButtonWidth(settingsContainer.scrollWidth);
      setLeftMargin(blockListContainer.offsetWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledButton
      type="submit"
      name="commit"
      responsive
      width={debouncedButtonWidth}
      leftMargin={debouncedLeftMargin}
    >
      {children}
    </StyledButton>
  );
};

export default SubmitButton;
