import React, { useState } from 'react';
import { GOAL_AND_METRICS_DEFAULT_VALUES } from '../constants';

const GoalAndMetricContext = React.createContext();

GoalAndMetricContext.displayName = 'Goal And Metric Context';

export function Context({ children }) {
  const fakeAmounts = {
    submissionAmount: 0,
    raiseAmountByBlock: 0,
    raiseAmountByExperience: 0,
    raiseAmountByAsker: 0,
    goal: 'submission_amount',
  };
  const initialState = { ...GOAL_AND_METRICS_DEFAULT_VALUES, ...fakeAmounts };

  const dataState = useState(initialState);

  return (
    <GoalAndMetricContext.Provider value={dataState}>
      {children}
    </GoalAndMetricContext.Provider>
  );
}

export default GoalAndMetricContext;
