import { useState, useEffect } from 'react';

import { useTranslation } from '../../../i18n';

export const METADATA_TITLE = 'metadata.title';
export const METADATA_URL = 'url';

const BLOCKS_LIST_TYPE = 'blocks';
const experienceMetadataList = (t) => [
  {
    title: t('expTitle'),
    subTitle: t('expMeta'),
    value: METADATA_TITLE,
    dataTestId: 'OptionsList/MetadataListItem',
    experienceMetadata: true,
  },
  {
    title: t('expUrl'),
    subTitle: t('expMeta'),
    value: METADATA_URL,
    dataTestId: 'OptionsList/MetadataListItem',
    experienceMetadata: true,
  },
];

const sortExperiencesList = (t, list, experienceId) => {
  list.some((item, index) => {
    if (item.value === experienceId) {
      item.subTitle = t('subtitle');
      list.splice(index, 1);
      list.unshift(item);
      return true;
    }
  });

  return list;
};

export const usePreparedList = (
  onComplete,
  onMetadataSelect,
  steps,
  experienceId,
) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'MentionsInput.OptionsList',
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [currentList, setCurrentList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [header, setHeader] = useState('');

  useEffect(() => {
    setHeader(steps[stepIndex].title);

    steps[stepIndex].loadList().then((data) => {
      const sortedList = sortExperiencesList(t, data, experienceId);

      setCurrentList(sortedList);
      setIsLoaded(true);
    });
  }, []);

  const handleNextStep = (value, nextStep) => {
    setHeader(`${header} / ${nextStep.title}`);

    nextStep.loadList(value).then((data) => {
      let options = data;

      if (nextStep.type === BLOCKS_LIST_TYPE) {
        options = options.concat(experienceMetadataList(t));
      }

      setCurrentList(options);
      setStepIndex(stepIndex + 1);
      setIsLoaded(true);
    });
  };

  const onSelect = (item) => {
    const { value, experienceMetadata, type, available_types } = item;
    if (experienceMetadata) {
      const selectedExperienceId = selectedItems[0];

      onMetadataSelect(selectedExperienceId, value);

      return;
    }

    const items = [...selectedItems, value];
    const nextStep = steps[stepIndex + 1];
    setSelectedItems(items);
    setIsLoaded(false);

    if (nextStep) {
      handleNextStep(value, nextStep);
    } else {
      onComplete(items, type, available_types);
    }
  };

  return {
    preparedList: currentList,
    onSelect,
    header,
    isLoaded,
  };
};
