import React from 'react';
import PropTypes from 'prop-types';
import _isNumber from 'lodash/isNumber';
import _isEmpty from 'lodash/isEmpty';
import PaymentOptionFeeModal from '../../../../modals/PaymentOptionFeeModal';
import HiddenInputs from './HiddenInputs';
import { ExperienceIdContext } from '../../..';

const getDefaultValue = (values, attr) =>
  values && _isNumber(values[attr]) ? values[attr] / 100 : '';

const defaultDisclaimerRichText = (experienceId, blockId) => ({
  titleElements: JSON.stringify([
    {
      type: 'paragraph',
      children: [
        { text: "You'll be charged a " },
        {
          children: [{ text: '' }],
          reference: `{{experience_${experienceId}.block_${blockId}.data.payment_method_fee}}`,
          type: 'mention',
        },
        {
          text:
            ' fee in addition to your payment amount to cover processing fees.',
        },
      ],
    },
  ]),
});

const defaultCardTypeFees = (paymentMethod, kind) => {
  if (paymentMethod && kind === 'card') {
    return {
      supportsCreditCardFees: paymentMethod.supportsCreditCardFees,
      supportsDebitCardFees: paymentMethod.supportsDebitCardFees,
      supportsPrepaidCardFees: paymentMethod.supportsPrepaidCardFees,
      supportsUnknownCardFees: paymentMethod.supportsUnknownCardFees,
    };
  } else {
    return {
      supportsCreditCardFees: true,
      supportsDebitCardFees: false,
      supportsPrepaidCardFees: false,
      supportsUnknownCardFees: false,
    };
  }
};

function FeeSection({
  kind,
  title,
  index,
  errors,
  blockId,
  paymentMethod,
  methodIsEnabled,
  modalIsOpen,
  hideModal,
}) {
  const experienceId = React.useContext(ExperienceIdContext);
  const [values, setValues] = React.useState({
    percentageValue: paymentMethod ? paymentMethod.feePercentage : 0,
    minValue: getDefaultValue(paymentMethod, 'feeMinCents'),
    maxValue: getDefaultValue(paymentMethod, 'feeMaxCents'),
    ...defaultCardTypeFees(paymentMethod, kind),
    feesEnabled: !_isEmpty(paymentMethod),
    disclaimerText: {
      richText:
        paymentMethod?.disclaimer.richText ||
        defaultDisclaimerRichText(experienceId, blockId),
      cssStyle: paymentMethod?.disclaimer.cssStyle || {},
    },
  });

  return (
    <>
      <PaymentOptionFeeModal
        title={title}
        index={index}
        kind={kind}
        errors={errors}
        blockId={blockId}
        isOpen={modalIsOpen}
        hideModal={hideModal}
        defaultValues={values}
        setValues={setValues}
        experienceId={experienceId}
      />
      <HiddenInputs
        kind={kind}
        index={index}
        id={paymentMethod?.id}
        methodIsEnabled={methodIsEnabled}
        values={values}
      />
    </>
  );
}

FeeSection.propTypes = {
  index: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  methodIsEnabled: PropTypes.bool,
  paymentMethod: PropTypes.object,
  errors: PropTypes.object.isRequired,
};

export default FeeSection;
