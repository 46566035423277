import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tooltip from '../../../tooltip';

function IconBox({ children, isOpen, tooltipTitle }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '28px',
        height: '28px',
        borderRadius: '4px',
        background: `${isOpen ? 'rgba(29, 29, 29, 0.08)' : 'transparent'}`,
        '&:hover': {
          background: 'rgba(29, 29, 29, 0.08)',
        },
        '& svg': {
          fontSize: '20px',
          margin: '0 5px',
          cursor: 'pointer',
        },
      }}
    >
      {tooltipTitle ? (
        <Tooltip
          popperStyle={{ textTransform: 'capitalize' }}
          title={tooltipTitle}
          placement="top"
        >
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
}

IconBox.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default IconBox;
