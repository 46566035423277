import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { ErrorMessage as StyledError } from '../../../../globals';

export default function ErrorMessage({ errors, index }) {
  if (_isEmpty(errors)) {
    return null;
  }

  if (
    Object.keys(errors).find((e) => e.includes('payment_methods') && errors.payment_methods.length > 0 && index === 0)
  ) {
    return <StyledError style={{ position: 'absolute', right: '30px', top: '-9px' }}>{errors.payment_methods[0]}</StyledError>;
  } else {
    return null;
  }

}
