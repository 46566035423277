import styled from 'styled-components';

export const InnerWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid var(--border-color-primary);
  cursor: pointer;

  & svg {
    stroke: var(--font-color-secondary);
  }
`;

export const LabelWrapper = styled.div`
  font-weight: 500;
  font-size: 1rem;
`;
