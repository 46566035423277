import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MUICheckbox, { checkboxClasses } from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

const iconStyles = {
  border: '1px solid var(--border-color-primary)',
  borderRadius: '4px',
  width: 20,
  height: 20,
  backgroundColor: 'var(--white)',
  opacity: 0.7,
  'input:hover ~ &': {
    opacity: 0.8,
  },
  'input:disabled ~ &': {
    background: 'var(--disabled-input-background)',
  },
};

const checkedIconStyles = {
  ...iconStyles,
  backgroundColor: 'var(--white)',
  opacity: 1,
  position: 'relative',
  '&:after': {
    display: 'block',
    position: 'absolute',
    content: '""',
    left: '10px',
    top: '5px',
    width: '2px',
    height: '9px',
    backgroundColor: 'var(--primary-color)',
    transform: 'rotate(49deg) scale(1.2)',
  },
  '&:before': {
    display: 'block',
    position: 'absolute',
    content: '""',
    left: '5px',
    top: '9px',
    width: '2px',
    height: '5px',
    backgroundColor: 'var(--primary-color)',
    transform: 'rotate(-47deg) scale(1.2)',
  },
  'input:hover ~ &': {
    opacity: 1,
  },
  'input:disabled ~ &': {
    '&:before': {
      backgroundColor: 'var(--disabled-input-color)',
    },
    '&:after': {
      backgroundColor: 'var(--disabled-input-color)',
    },
  },
};

function Checkbox(props) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(props.checkedByDefault);
  }, [props.checkedByDefault]);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        sx={{
          p: '0',
          backgroundColor: 'unset',
          marginTop: '0',
          marginBottom: '0',
          '& span.MuiFormControlLabel-label': {
            fontFamily: 'inherit',
            '&.Mui-disabled': {
              color: 'inherit',
              opacity: 0.5,
            },
          },
        }}
        control={
          <MUICheckbox
            sx={{
              [`&.${checkboxClasses.root}`]: {
                marginRight: '3px',
                p: '9px',
                borderRadius: '50%',
              },
            }}
            checked={isChecked}
            name={props.name}
            value={props.value}
            disabled={props.disabled}
            onChange={props.onChange || handleChange}
            color="default"
            data-testid={props['data-testid']}
            checkedIcon={<Box component="span" sx={checkedIconStyles} />}
            icon={<Box component="span" sx={iconStyles} />}
          />
        }
        label={props.label}
      />
      {props.withInput && (
        <input
          name={props.name}
          hidden={true}
          value={isChecked}
          readOnly={true}
        />
      )}
    </div>
  );
}

Checkbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  withInput: PropTypes.bool,
  checkedByDefault: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default Checkbox;
