import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { ChevronDown, ChevronUp } from 'react-feather';
import { InnerWrapper, FlexWrapper, LabelWrapper } from './style';

function ExpansionPanel({ children, name, title, isDefaultOpen, onChange, ...rest }) {
  const [cookies, setCookie] = useCookies([name]);
  const [isOpen, setIsOpen] = useState(
    isDefaultOpen || cookies[name] === 'true',
  );
  const isPersistable = name !== undefined;

  const persistChanges = (nextState) => {
    if (nextState) {
      setCookie(name, 'true', { path: '/' });
    } else {
      // This method does not work in browser (Chrome 86+, Firefox)
      // removeCookie(name);

      // Use this as workaround for removing cookie
      setCookie(name, 'false', { path: '/' });
    }
  };

  const handleChange = () => {
    const newState = !isOpen;

    if (isPersistable) {
      persistChanges(newState);
    }

    if (onChange) {
      onChange();
    }

    setIsOpen(newState);
  };

  return (
    <>
      <FlexWrapper onClick={handleChange} {...rest}>
        <LabelWrapper>{title}</LabelWrapper>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </FlexWrapper>
      {isOpen && <InnerWrapper>{children}</InnerWrapper>}
    </>
  );
}

ExpansionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
  isDefaultOpen: PropTypes.bool,
};

export default ExpansionPanel;
