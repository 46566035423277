import RailsRanger from 'rails-ranger';
import useWorkspace from './hooks/useWorkspace';

const config = {
  axios: {
    baseURL: '/api/v1',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
};

const axiosInstance = new RailsRanger(config);


// NOTE: we wrap response interceptors into a function so we can pass history
// object from the component and use it for smooth redirects instead
// of window.location.replace()
// If we will not use global redirects here during long time so it could be
// used without a function. (Vova)
export const setupInterceptors = (history) => {
  axiosInstance.client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Order of handlers is important!
      //
      // Response errors
      if (error.response) {
        const { status } = error.response;

        if (status === 403) {
          history.push('/not-found');

          return Promise.reject(new Error('Not Authorized'));
        }

        if (status === 404) {
          history.push('/not-found');

          return Promise.reject(new Error('Not Found'));
        }

        return Promise.reject(error);
      }

      // Request errors e.g.: No Network Connection
      if (error.request) {
        return Promise.reject(error);
      }

      // Other errors
      return Promise.reject(new Error('Something went wrong'));
    },
  );
};

axiosInstance.client.interceptors.request.use((request) => {
  const workspaceId = useWorkspace();

  if (workspaceId) {
    request.headers['Workspace-Id'] = workspaceId;
  }

  return request;
});

export default axiosInstance;
