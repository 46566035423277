import React  from 'react';
import PropTypes from 'prop-types';
import { useHover } from 'use-events';
import Box from '@mui/material/Box';
import _isEmpty from 'lodash/isEmpty';
import { SmallDeleteButton } from '../../../../../Button';
import { DonationInputWrapper, RightButtonWrapper } from '../../style';
import { EditableDraggableMentionsInput } from '../../../../../SlateElements';
import CustomizeToolbar from '../../../../../CustomizeToolbar';
import useBuildMentionsSteps from '../../../../../hooks/useBuildMentionsSteps';
import CssStyleHiddenInputs from '../../../CssStyleHiddenInputs';

function OptionInput({
  option,
  index,
  position,
  multiIsChecked,
  onChange,
  onStylesChange,
  addOptionItem,
  onDelete,
  withDeleteButton,
  withoutInputNames,
  error,
  dataTarget,
  isDraggingOver,
}) {
  const [isHover, bind] = useHover();
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'MultipleChoiceSection',
  });
  const isNewRecord = option.id === '' || option.id === null;

  const { cssStyle, richText } = option;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addOptionItem();
    }
  };

  const handleValueChange = (textValue, elementsValue, htmlValue) => {
    if (textValue[textValue.length - 1] !== '[') {
      onChange(textValue, elementsValue, htmlValue);
    }
  };

  const handleStylesChange = (updatedItem, index) => {
    onStylesChange(updatedItem, index);
  };

  return (
    <DonationInputWrapper>
      {!option.deleted && (
        <>
          <Box sx={{ position: 'relative' }} {...bind}>
            <EditableDraggableMentionsInput
              dataTestId="MultipleChoiceSection/Input"
              index={index}
              renderHiddenInputs={!withoutInputNames}
              error={error}
              initialValue={
                richText.titleElements
                  ? JSON.parse(richText.titleElements)
                  : richText.title
              }
              optionRichTextId={richText.id}
              optionName={richText.title || ''}
              placeholder="Enter option"
              dataTarget={dataTarget}
              steps={mentionSteps}
              onChange={handleValueChange}
              onKeyDown={handleKeyDown}
              isDraggingOver={isDraggingOver}
            />
            <CustomizeToolbar
              isHover={isHover}
              handleSave={handleStylesChange}
              index={index}
              cssStyle={cssStyle}
              type={`multipleChoice${
                multiIsChecked ? '_withMultiSelection' : ''
              }`}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            />
          </Box>
          <RightButtonWrapper>
            {withDeleteButton && (
              <SmallDeleteButton
                tabIndex="-1"
                data-testid="MultipleChoiceSection/DeleteButton"
                onClick={onDelete}
              >
                –
              </SmallDeleteButton>
            )}
          </RightButtonWrapper>
        </>
      )}

      {!withoutInputNames && (
        <>
          <CssStyleHiddenInputs
            cssStyle={cssStyle}
            attributesName={`ask[multiple_choice_options_attributes][${index}][css_style_attributes]`}
          />
          <input
            type="hidden"
            name={`ask[multiple_choice_options_attributes][${index}][position]`}
            value={position}
          />
          {!isNewRecord && (
            <>
              <input
                type="hidden"
                name={`ask[multiple_choice_options_attributes][${index}][id]`}
                value={option.id}
              />
              <input
                type="hidden"
                name={`ask[multiple_choice_options_attributes][${index}][_destroy]`}
                value={option.deleted}
              />
            </>
          )}
        </>
      )}
    </DonationInputWrapper>
  );
}

OptionInput.propTypes = {
  option: PropTypes.object,
  index: PropTypes.number.isRequired,
  position: PropTypes.number,
  onChange: PropTypes.func,
  addOptionItem: PropTypes.func,
  onDelete: PropTypes.func,
  withDeleteButton: PropTypes.bool,
  error: PropTypes.string,
  dataTarget: PropTypes.string,
};

export default OptionInput;
