import React, { useState, useContext } from 'react';
import SimpleSelect from '../../../../../select';
import { useTranslation } from '../../../../../../i18n';
import { EditableMentionsInput } from '../../../../../SlateElements';
import { ExperienceIdContext } from '../../../../index';
import {
  Label,
  FormSection,
  FormField,
  FormSectionDescription,
} from '../../../../../globals';
import useBuildMentionsSteps from '../../../../../hooks/useBuildMentionsSteps';

function RaiseAmountForm({
  isDonation,
  errors,
  goalState,
  updateGoalState,
  raiseGoalType,
  handleRaiseGoalTypeChange,
  currencySymbol,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'GoalSection.RaiseAmountForm',
  });
  const [showErrors, setShowErrors] = useState(true);
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'GoalSection.RaiseAmountForm',
  });
  const experienceId = useContext(ExperienceIdContext);

  const handleSelectChange = (option) => {
    handleRaiseGoalTypeChange(option);
    setShowErrors(false);
  };

  const options = [
    { value: 'by_experience', label: t('goalOptions.experienceLabel') },
    { value: 'by_asker', label: t('goalOptions.askerLabel') },
    { value: 'by_variant', label: t('goalOptions.variantLabel') },
  ];

  if (isDonation) {
    options.unshift({ value: 'by_block', label: t('goalOptions.blockLabel') });
  }

  const handleValueChange = (value, attribute) => {
    if (value[value.length - 1] !== '[') {
      updateGoalState(attribute, value);
    }
  };

  // NOTE: `||` for case when raiseGoalType is by_block and block type switched to NOT donation
  const selectedType = options.find((option) => option.value == raiseGoalType) || options[0];

  return (
    <FormSection>
      <Label>Display funds from</Label>
      <FormSectionDescription>{t('descriptionText')}</FormSectionDescription>
      <FormField>
        <SimpleSelect
          options={options}
          value={selectedType}
          onChange={handleSelectChange}
          isSearchable={false}
          name="ask[ask_metric_attributes][raise_goal_type]"
          classNamePrefix="react-select__raise-goal-type"
        />
      </FormField>
      <Label>Goal</Label>
      <EditableMentionsInput
        initialValue={goalState.raiseGoal.toString()}
        key={`goal_${selectedType.value}`}
        placeholder="ex: 5000"
        experienceId={experienceId}
        steps={mentionSteps}
        label={currencySymbol}
        name="ask[ask_metric_attributes][raise_goal]"
        error={showErrors && errors['ask_metric.raise_goal']}
        onValueChange={(value) => handleValueChange(value, 'raiseGoal')}
        inputStyles={{ marginBottom: '1rem' }}
        withHiddenInput
      />
      <Label>Goal description</Label>
      <EditableMentionsInput
        initialValue={goalState.raiseGoalName || ''}
        key={`goal_name_${selectedType.value}`}
        placeholder="ex: raised"
        dataTestId="RaiseAmountForm/Input"
        experienceId={experienceId}
        steps={mentionSteps}
        name="ask[ask_metric_attributes][raise_goal_name]"
        error={showErrors && errors['ask_metric.raise_goal_name']}
        onValueChange={(value) => handleValueChange(value, 'raiseGoalName')}
        withHiddenInput
        autoFocus
      />
    </FormSection>
  );
}

export default RaiseAmountForm;
