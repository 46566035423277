import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Input } from '../../../../inputs/Input';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Label,
  FormSectionDescription,
  FormSubsection,
  FlexSpaceBetween,
} from '../../../../globals';
import FeeSection from '../FeeSection';
import Checkbox from '../../../../checkbox';
import { useModal } from '../../../../hooks';
import ErrorMessage from './ErrorMessage';

export default function PaymentOptionsSection({
  t,
  block,
  errors,
  achDisabled,
}) {
  const {
    isOpen: cardModalIsOpen,
    hideModal: hideCardModal,
    showModal: showCardModal,
  } = useModal();
  const {
    isOpen: achModalIsOpen,
    hideModal: hideAchModal,
    showModal: showAchModal,
  } = useModal();
  const [paypalEnabled, setPaypalEnabled] = React.useState(block.paypalEnabled);
  const [achEnabled, setAchEnabled] = React.useState(block.achEnabled);
  const [cardEnabled, setCardEnabled] = React.useState(block.cardEnabled);
  const [paypalEmail, setPaypalEmail] = React.useState(block.paypalEmail);

  return (
    <>
      <Label>Payment options</Label>
      <FlexSpaceBetween style={{ position: 'relative' }}>
        <Checkbox
          name="ask[card_enabled]"
          checkedByDefault={cardEnabled}
          value={cardEnabled}
          onChange={(e) => setCardEnabled(e.target.checked)}
          label="Credit card"
          withInput
        />
        <IconButton
          sx={{
            width: 'auto',
            '&:hover': { color: 'inherit', bgcolor: 'transparent' },
          }}
          aria-label="open card fee modal"
          size="small"
          onClick={showCardModal}
          disabled={!cardEnabled}
          disableRipple
        >
          <SettingsOutlinedIcon />
        </IconButton>
        <ErrorMessage errors={errors} index={0} />
      </FlexSpaceBetween>
      <FeeSection
        title="Fee Options"
        index={0}
        kind="card"
        methodIsEnabled={cardEnabled}
        errors={errors}
        blockId={block.blockId}
        paymentMethod={block.paymentMethods.find((m) => m.kind === 'card')}
        modalIsOpen={cardModalIsOpen}
        hideModal={hideCardModal}
      />
      <FlexSpaceBetween style={{ position: 'relative' }}>
        <Checkbox
          name="ask[ach_enabled]"
          checkedByDefault={achEnabled}
          value={achEnabled}
          onChange={(e) => setAchEnabled(e.target.checked)}
          label="ACH"
          disabled={achDisabled}
          withInput
        />
        <IconButton
          sx={{
            width: 'auto',
            '&:hover': { color: 'inherit', bgcolor: 'transparent' },
          }}
          aria-label="open ach fee modal"
          size="small"
          onClick={showAchModal}
          disabled={!achEnabled}
          disableRipple
        >
          <SettingsOutlinedIcon />
        </IconButton>
        <ErrorMessage errors={errors} index={1} />
      </FlexSpaceBetween>
      <FeeSection
        title="Fee Options"
        index={1}
        kind="us_bank_account"
        methodIsEnabled={achEnabled}
        errors={errors}
        blockId={block.blockId}
        paymentMethod={block.paymentMethods.find(
          (m) => m.kind === 'us_bank_account',
        )}
        modalIsOpen={achModalIsOpen}
        hideModal={hideAchModal}
      />
      <Checkbox
        name="paypal_checkbox"
        checkedByDefault={paypalEnabled}
        value={paypalEnabled}
        onChange={(e) => setPaypalEnabled(e.target.checked)}
        label="PayPal"
      />
      <input type="hidden" name="ask[paypal_enabled]" value={paypalEnabled} />
      {paypalEnabled && (
        <FormSubsection>
          <FormSectionDescription>{t('description')}</FormSectionDescription>
          <Input
            name="ask[paypal_email]"
            defaultValue={paypalEmail}
            placeholder="Enter your PayPal email"
            onChange={(e) => setPaypalEmail(e.target.value)}
            error={errors.paypal_email && errors.paypal_email[0]}
            autoFocus
          />
        </FormSubsection>
      )}
    </>
  );
}
