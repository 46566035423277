import React from 'react';
import Popover from '@mui/material/Popover';
import ReactColorPicker from '../../../BlockForm/components/ReactColorPicker';

export default function ColorPopover({
  id,
  open,
  anchorEl,
  handleClose,
  color,
  handleChange,
  handleChangeComplete,
  handleColorPick,
  paletteColors,
}) {
  const handleResetColor = () => {
    handleChange('');
    handleChangeComplete('');
  };

  return (
    <Popover
      id={id}
      data-testid={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <ReactColorPicker
        color={color}
        onChange={handleChange}
        onChangeComplete={handleChangeComplete}
        handleColorPick={handleColorPick || handleChangeComplete}
        paletteColors={paletteColors}
        resetColor={handleResetColor}
        withAlpha
      />
    </Popover>
  );
}
