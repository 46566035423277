import styled from 'styled-components';

export const StyledTitle = styled.div`
  color: var(--font-color-secondary);
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  line-height: 2rem;
`;

export const InnerContentWrapper = styled.div`
  padding: 1rem 1rem 1.5rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled.button`
  color: ${(props) => (props.primary ? 'var(--white)' : 'var(--font-color-secondary--middle)')};
  display: flex;
  border-radius: 0;
  margin: 0;
  justify-content: center;

  && {
    background-color: ${(props) =>
      props.primary ? 'var(--primary-color)' : 'var(--button-background)'};
  }

  &&:hover {
    color: ${(props) => (props.primary ? 'var(--white)' : 'var(--font-color-secondary--middle)')};
    background-color: ${(props) =>
      props.primary
        ? 'var(--primary-color-darken)'
        : 'var(--button-background--hover)'};
  }
`;
