import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../checkbox';
import { ErrorMessageFloat, Label, FormSection } from '../../../globals';
import BlockContext from '../../../../contexts/BlockContext';
import BlockLabelInput from '../BlockLabelInput';

const DEFAULT_UGC_TYPES = [
  {
    label: 'Video',
    value: 'video',
    testId: 'FreeResponseSection/CheckboxVideo',
  },
  {
    label: 'Text',
    value: 'text',
    testId: 'FreeResponseSection/CheckboxText',
  },
  {
    label: 'File upload',
    value: 'upload',
    testId: 'FreeResponseSection/CheckboxUpload',
  },
];

function FreeResponseSection({ errors, ugcTypes, updateBlockAttributes }) {
  const [, setBlockState] = useContext(BlockContext);
  const typeIsPresent = (type) => {
    return ugcTypes.includes(type);
  };

  const updateUgcTypes = useCallback(
    (e) => {
      const newUgcTypes = e.target.checked ?
        [...ugcTypes, e.target.value] :
        ugcTypes.filter((type) => type !== e.target.value);

      updateBlockAttributes({ ugcTypes: newUgcTypes });
      // Update Context
      setBlockState((prevState) => ({ ...prevState, ugcTypes: newUgcTypes }));
    },
    [ugcTypes, updateBlockAttributes],
  );

  return (
    <FormSection data-testid="BlockForm/FreeResponseSection">
      <BlockLabelInput blockType="free_response" />
      <Label>Response types accepted</Label>
      {errors.ugc_types && (
        <ErrorMessageFloat>{errors.ugc_types}</ErrorMessageFloat>
      )}
      {DEFAULT_UGC_TYPES.map((item) => (
        <Checkbox
          key={item.value}
          label={item.label}
          value={item.value}
          checkedByDefault={typeIsPresent(item.value)}
          name="ask[ugc_types][]"
          data-testid={item.testId}
          onChange={updateUgcTypes}
        />
      ))}
      <input type="hidden" name="ask[ugc_types][]" value="" />
    </FormSection>
  );
}

FreeResponseSection.propTypes = {
  ugcTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateBlockAttributes: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
export default FreeResponseSection;
