import styled from 'styled-components';
import { StyledButton } from '../../../modal/style';

export const ButtonsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
`;

export const ActionButton = styled(StyledButton)`
  height: 58px;
  align-items: center;
  color: ${(props) => (props.primary ? 'var(--white)' : 'var(--font-color-secondary)')};

  &&:hover {
    color: ${(props) => (props.primary ? 'var(--white)' : 'var(--font-color-secondary)')};
  }
`;
