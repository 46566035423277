import React from 'react';
import CssStyleHiddenInputs from '../../CssStyleHiddenInputs';

export default function HiddenInputs({
  id,
  kind,
  index,
  values,
  methodIsEnabled,
}) {
  const {
    percentageValue,
    minValue,
    maxValue,
    supportsCreditCardFees,
    supportsDebitCardFees,
    supportsPrepaidCardFees,
    supportsUnknownCardFees,
    feesEnabled,
    disclaimerText,
  } = values;

  if (!feesEnabled || !methodIsEnabled) {
    if (id) {
      return (
        <>
          <input
            type="hidden"
            name={`ask[payment_methods_attributes][${index}][id]`}
            value={id}
          />
          <input
            type="hidden"
            name={`ask[payment_methods_attributes][${index}][_destroy]`}
            value={true}
          />
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][id]`}
        value={id}
      />
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][kind]`}
        value={kind}
      />
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][fee_percentage]`}
        value={percentageValue}
      />
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][fee_min]`}
        value={minValue}
      />
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][fee_max]`}
        value={maxValue}
      />
      {kind === 'card' && (
        <>
          <input
            type="hidden"
            name={`ask[payment_methods_attributes][${index}][supports_credit_card_fees]`}
            value={supportsCreditCardFees}
          />
          <input
            type="hidden"
            name={`ask[payment_methods_attributes][${index}][supports_debit_card_fees]`}
            value={supportsDebitCardFees}
          />
          <input
            type="hidden"
            name={`ask[payment_methods_attributes][${index}][supports_prepaid_card_fees]`}
            value={supportsPrepaidCardFees}
          />
          <input
            type="hidden"
            name={`ask[payment_methods_attributes][${index}][supports_unknown_card_fees]`}
            value={supportsUnknownCardFees}
          />
        </>
      )}
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][disclaimer_attributes][rich_text_attributes][title]`}
        defaultValue={disclaimerText.richText.title}
      />
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][disclaimer_attributes][rich_text_attributes][title_elements]`}
        defaultValue={disclaimerText.richText.titleElements}
      />
      <input
        type="hidden"
        name={`ask[payment_methods_attributes][${index}][disclaimer_attributes][rich_text_attributes][title_html]`}
        defaultValue={disclaimerText.richText.titleHtml}
      />
      <CssStyleHiddenInputs
        attributesName={`ask[payment_methods_attributes][${index}][disclaimer_attributes][css_style_attributes]`}
        cssStyle={disclaimerText.cssStyle}
      />
    </>
  );
}
