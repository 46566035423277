import styled from 'styled-components';
import { Editable } from 'slate-react';

export const Textarea = styled(Editable)`
  display: block;
  overflow: auto;
  resize: vertical;
  background: var(--background-primary);
  color: var(--font-color-primary);
border: 1px solid var(--border-color-primary);
  padding: 0.5rem 15px;
  border-radius: 8px;
  height: auto;
  min-height: 200px !important;
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
  line-height: 1;
`;
