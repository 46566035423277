import React from 'react';
import { Label } from '../../../../../globals';
import { SwitchWithTitle } from '../../../../../switch';
import BlockContext from '../../../../../../contexts/BlockContext';

export default function MediaOptions({ mediaHiddenOnMobile }) {
  const [hiddenOnMobile, setHiddenOnMobile] = React.useState(
    mediaHiddenOnMobile || false,
  );
  const [, setBlockState] = React.useContext(BlockContext);

  const handleHideOnMobile = (e) => {
    const checked = e.target.checked;
    setHiddenOnMobile(checked);
    setBlockState((prevState) => ({
      ...prevState,
      backgroundSetting: {
        ...prevState.backgroundSetting,
        mediaHiddenOnMobile: checked,
      },
    }));
  };

  return (
    <>
      <Label>Media options</Label>
      <SwitchWithTitle
        title="Hide media on mobile"
        checked={hiddenOnMobile}
        data-testid="BackgroundLayout/HideOnMobileSwitcher"
        onChange={handleHideOnMobile}
        noborder="true"
      />
      <input
        type="hidden"
        name="ask[background_setting_attributes][media_hidden_on_mobile]"
        value={hiddenOnMobile}
      />
    </>
  );
}
