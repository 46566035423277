import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { SwitchWithTitle } from '../../../switch';
import BlockContext from '../../../../contexts/BlockContext';

function SkippableBlockSection({ isChecked }) {
  const [checked, setChecked] = useState(isChecked || false);
  const [, setBlockState] = useContext(BlockContext);

  const handleSwitchChange = (e) => {
    const checked = e.target.checked;
    setChecked(checked);
    setBlockState((prevState) => ({ ...prevState, skippableChecked: checked }));
  };

  return (
    <div data-testid="SkippableBlockSection/Container">
      <SwitchWithTitle
        checked={checked}
        onChange={handleSwitchChange}
        title="Make block optional"
        data-testid="SkippableBlockSection/Switcher"
      />
      <input type="hidden" name="ask[skippable_checked]" value={checked} />
    </div>
  );
}

SkippableBlockSection.propTypes = {
  isChecked: PropTypes.bool,
};

export default SkippableBlockSection;
