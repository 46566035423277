import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import { styles } from '../../../../../inputs/DateSelector/style';

export default function DatePicker({ selectedDate, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={selectedDate}
        onChange={onChange}
        renderInput={(params) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pr: 1.5,
                '.MuiInputAdornment-root': {
                  ml: 0,
                },
                '.MuiInputAdornment-root .MuiIconButton-root': {
                  width: 'auto',
                  '&:hover': {
                    color: 'inherit',
                  },
                },
              }}
            >
              <input
                style={{
                  position: 'absolute',
                  width: '1px',
                  height: '1px',
                  visibility: 'hidden',
                  margin: 0,
                }}
                ref={params.inputRef}
              />
              {params.InputProps?.endAdornment}
            </Box>
          );
        }}
        PaperProps={{ sx: styles.paper }}
      />
    </LocalizationProvider>
  );
}
