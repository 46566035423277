import React from 'react';
import Popper from '@mui/material/Popper';

export default function withPopper(Component) {
  return (props) => {
    const open = Boolean(props.anchorEl);

    if (props.anchorEl) {
      return (
        <Popper
          sx={{ zIndex: 10 }}
          open={open}
          anchorEl={props.anchorEl}
          placement="bottom-start"
        >
          <Component {...props} />
        </Popper>
      );
    } else {
      return <Component {...props} />;
    }
  };
}
