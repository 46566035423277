import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';
import { createEditor } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';

import useDebounce from '../../../hooks/useDebounce';
import { Legend } from '../../../globals';
import { ExperienceIdContext } from '../../index';
import { toggleMark, withDeleteEmptyMark } from './components/MarkButton';
import { withDeleteEmptyBlock } from './components/BlockButton';
import { withLinks } from './components/LinksButton/utils';
import { Textarea } from './style';
import { MentionsInput } from '../../../MentionsInput';
import { keyboardNavigation } from '../../../MentionsInput/keyboardNavigation';
import { slateToHtml, slateToText, slateToElements } from './utils';
import { setValueToStore } from '../../../../sharedStore';
import ButtonsContainer from './components/ButtonsContainer';
import BlockContext from '../../../../contexts/BlockContext';
import {
  withMention,
  insertMention,
  Element,
  Leaf,
} from '../../../SlateElements';
import useBuildMentionsSteps from '../../../hooks/useBuildMentionsSteps';
import Box from '@mui/material/Box';
import CustomizeToolbar from '../../../CustomizeToolbar';
import { useHover } from 'use-events';
import HiddenInputs from './components/HiddenInputs';
import FormulaPicker from '../../../FormulaPicker';
import { getAnchorElement, prevCharIs } from '../../../SlateElements/components/utils';

const INITIAL_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const prepareInitValue = (defaultValue) =>
  (defaultValue && JSON.parse(defaultValue)) || INITIAL_VALUE;

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+shift+s': 'strikethrough',
};

const DEBOUNCE_DELAY = 500;

function TitleSection({ defaultTitleElements, defaultFont }) {
  const editor = useMemo(
    () =>
      withLinks(
        withDeleteEmptyBlock(
          withDeleteEmptyMark(
            withMention(withHistory(withReact(createEditor()))),
          ),
        ),
      ),
    [],
  );
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'TitleSection',
  });
  const experienceId = useContext(ExperienceIdContext);
  const [blockState, setBlockState] = useContext(BlockContext);
  const [value, setValue] = useState(prepareInitValue(defaultTitleElements));
  const debouncedValue = useDebounce(value, DEBOUNCE_DELAY);
  const [mentionsIsOpen, setMentionsIsOpen] = useState(false);
  const [mentionsItemIndex, setMentionsItemIndex] = useState(0);
  const [mentionsAnchorEl, setMentionsAnchorEl] = React.useState(null);
  const [formulasAnchorEl, setFormulasAnchorEl] = React.useState(null);
  const [isHover, bind] = useHover();
  const renderElement = useCallback(
    (props) => <Element {...props} editor={editor} />,
    [],
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const linkButtonRef = useRef(null);
  const mentionsItemRef = useRef(null);
  const mentionsListRef = useRef(null);

  const handleValueChange = (value) => {
    setValueToStore('blockFormChanged', true);

    if (editor.selection) {
      if (prevCharIs(editor, '[')) {
        const anchorEl = getAnchorElement(editor);
        setMentionsAnchorEl(anchorEl);
        setMentionsItemIndex(0);
        setMentionsIsOpen(true);
      } else if (prevCharIs(editor, '=')) {
        const anchorEl = getAnchorElement(editor);
        setFormulasAnchorEl(anchorEl);
      } else {
        setMentionsIsOpen(false);
        setFormulasAnchorEl(null);
      }
    }

    setValue(value);
    setBlockState((prevState) => ({
      ...prevState,
      titleHtml: slateToHtml(value),
      titleSetting: {
        ...prevState.titleSetting,
        titleElements: JSON.stringify(slateToElements(value)),
      },
    }));
  };

  const handleStylesChange = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      titleSetting: {
        ...prevState.titleSetting,
        cssStyle: value.cssStyle,
      },
    }));
  };

  const insertReference = async (reference, _type, available_types) => {
    insertMention(editor, reference, false, available_types);
  };

  const handleKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }

    if (isHotkey('mod+k', event) && linkButtonRef.current) {
      const mousedown = document.createEvent('MouseEvents');
      mousedown.initEvent('mousedown', true, true);
      linkButtonRef.current.dispatchEvent(mousedown);
    }

    keyboardNavigation(
      event,
      mentionsIsOpen,
      mentionsItemIndex,
      setMentionsItemIndex,
      mentionsItemRef,
      mentionsListRef,
    );
  };

  const htmlValue = useMemo(() => slateToHtml(debouncedValue), [
    debouncedValue,
  ]);
  const textValue = useMemo(() => slateToText(debouncedValue), [
    debouncedValue,
  ]);
  const elementsValue = useMemo(
    () => JSON.stringify(slateToElements(debouncedValue)),
    [debouncedValue],
  );

  return (
    <div data-testid="BlockForm/TitleSection">
      <Box sx={{ position: 'relative' }} {...bind}>
        <Legend>Prompt</Legend>
        <Slate editor={editor} value={value} onChange={handleValueChange}>
          <Textarea
            data-testid="TitleSection/Textarea"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="e.g. Where should we eat dinner tonight?"
            onKeyDown={handleKeyDown}
            spellCheck={false}
          />
          <ButtonsContainer
            linkButtonRef={linkButtonRef}
            defaultFont={defaultFont}
          />
        </Slate>
        <MentionsInput
          name="TitleSection/mentionsInput"
          experienceId={experienceId}
          isOpen={mentionsIsOpen}
          steps={mentionSteps}
          customInput
          onReference={insertReference}
          optionsListRef={mentionsListRef}
          listItemRef={mentionsItemRef}
          listItemIndex={mentionsItemIndex}
          anchorEl={mentionsAnchorEl}
        />
        <FormulaPicker
          editor={editor}
          anchorEl={formulasAnchorEl}
          setAnchorEl={setFormulasAnchorEl}
        />
        <CustomizeToolbar
          isHover={isHover}
          handleSave={handleStylesChange}
          cssStyle={blockState.titleSetting.cssStyle || {}}
          type="blockPrompt"
          sx={{
            position: 'absolute',
            bottom: '62px',
            right: '10px',
          }}
        />
        <HiddenInputs
          htmlValue={htmlValue}
          textValue={textValue}
          elementsValue={elementsValue}
          cssStyle={blockState.titleSetting.cssStyle}
        />
      </Box>
    </div>
  );
}

TitleSection.propTypes = {
  defaultTitleElements: PropTypes.string,
};

export default TitleSection;
