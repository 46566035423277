import React from 'react';
import _camelCase from 'lodash/camelCase';

const styleNames = [
  'background_color',
  'color',
  'border_color',
  'border_width',
  'border_style',
  'border_radius',
  'box_shadow',
  'border_top',
  'border_right',
  'border_bottom',
  'border_left',
  'margin_top',
  'margin_right',
  'margin_bottom',
  'margin_left',
  'padding_top',
  'padding_right',
  'padding_bottom',
  'padding_left',
];

export default function CssStyleHiddenInputs({ cssStyle, attributesName }) {
  const styles = cssStyle || {};
  return (
    <>
      {styles.id && (
        <input type="hidden" name={`${attributesName}[id]`} value={styles.id} />
      )}
      {styleNames.map((styleName) => (
        <input
          key={styleName}
          type="hidden"
          name={`${attributesName}[${styleName}]`}
          defaultValue={styles[_camelCase(styleName)]}
        />
      ))}
    </>
  );
}
