import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { SwitchWithTitle } from '../../../switch';
import { SUBMISSION_BUTTON_SETTINGS } from '../../../../constants';
import { useEffect, useState } from 'react';
import SingleButtonSettings from './SingleButtonSettings';
import PaymentButtonsSettings from './PaymentButtonsSettings';
import BlockContext from '../../../../contexts/BlockContext';

function SubmissionButtonSection({
  errors,
  blockType,
  isChecked,
  primaryColor,
}) {
  const settings = SUBMISSION_BUTTON_SETTINGS[blockType];
  const [, setBlockState] = useContext(BlockContext);
  const [show, setShow] = useState(settings.showSection);
  const [noSwitcher, setNoSwitcher] = useState(settings.noSwitcher);
  const [legend, setLegend] = useState(settings.legend);
  const [title, setTitle] = useState(settings.title);
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setShow(settings.showSection);
    setNoSwitcher(settings.noSwitcher);
    setTitle(settings.title);
    setLegend(settings.legend);
  }, [blockType]);

  if (!show) {
    return null;
  }

  if (noSwitcher) {
    return (
      <div data-testid="BlockForm/SubmissionButtonSection">
        <input type="hidden" name="ask[cta_button_settings_checked]" value="1" />
        {blockType === 'donation' ? (
          <PaymentButtonsSettings
            primaryColor={primaryColor}
            errors={errors}
          />
        ) : (
          <SingleButtonSettings
            primaryColor={primaryColor}
            legend={legend}
            errors={errors}
          />
        )}
      </div>
    );
  }

  return (
    <div data-testid="BlockForm/SubmissionButtonSection">
      <input type="hidden" name="ask[cta_button_settings_checked]" value={ checked } />
      <SwitchWithTitle
        checked={checked}
        title={title}
        onChange={(e) => {
          const checked = e.target.checked;
          setChecked(checked);
          setBlockState((prevState) => ({
            ...prevState,
            ctaButtonSettingsChecked: checked,
          }));
        }}
      >
        <SingleButtonSettings
          primaryColor={primaryColor}
          legend={legend}
          errors={errors}
        />
      </SwitchWithTitle>
    </div>
  );
}

SubmissionButtonSection.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  blockType: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
};

export default SubmissionButtonSection;
