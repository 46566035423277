import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _camelCase from 'lodash/camelCase';

import {
  CURRENCY_SIGN_MAP,
  GOAL_AND_METRICS_DEFAULT_VALUES,
} from '../../../../constants';

import GoalSection from '../GoalSection';
import MetricSection from '../MetricSection';

import { SwitchWithTitle } from '../../../switch';
import { Legend, Fieldset } from '../../../globals';

import BlockContext from '../../../../contexts/BlockContext';
import GoalAndMetricContext from '../../../../contexts/GoalAndMetricContext';

function GoalAndMetricSection({
  goals,
  blockType,
  currency,
  isChecked,
  askMetric,
  errors,
  updateBlockAttributes,
}) {
  const [, setBlockState] = useContext(BlockContext);
  const [, setMetricsState] = useContext(GoalAndMetricContext);

  const handleIsChecked = (e) => {
    const checked = e.target.checked;
    updateBlockAttributes({ metricsChecked: checked });
    setBlockState((prevState) => ({ ...prevState, metricsChecked: checked }));
  };

  const setValueFor = (keyName) => {
    if (errors[`ask_metric.${keyName}`]) {
      return askMetric[keyName];
    } else {
      return (
        askMetric[keyName] ||
        GOAL_AND_METRICS_DEFAULT_VALUES[_camelCase(keyName)]
      );
    }
  };

  const [goalState, setGoalState] = useState({
    goal: askMetric.goal || goals[0],
    submissionGoal: setValueFor('submission_goal'),
    submissionGoalName: setValueFor('submission_goal_name'),
    raiseGoal: setValueFor('raise_goal'),
    raiseGoalName: setValueFor('raise_goal_name'),
    otherGoal: setValueFor('other_goal'),
    otherGoalAmount: setValueFor('other_goal_amount'),
    otherGoalName: setValueFor('other_goal_name'),
  });

  const [metricState, setMetricState] = useState({
    amountRaisedMetric: setValueFor('amount_raised_metric'),
    amountRaisedMetricName: setValueFor('amount_raised_metric_name'),
    amountRaisedByExperienceMetric: setValueFor(
      'amount_raised_by_experience_metric',
    ),
    amountRaisedByExperienceMetricName: setValueFor(
      'amount_raised_by_experience_metric_name',
    ),
    amountRaisedByVariantMetric: setValueFor('amount_raised_by_variant_metric'),
    amountRaisedByVariantMetricName: setValueFor(
      'amount_raised_by_variant_metric_name',
    ),
    amountRaisedByAskerMetric: setValueFor('amount_raised_by_asker_metric'),
    amountRaisedByAskerMetricName: setValueFor(
      'amount_raised_by_asker_metric_name',
    ),
    submissionsMetric: setValueFor('submissions_metric'),
    submissionsMetricName: setValueFor('submissions_metric_name'),
    otherMetric: setValueFor('other_metric'),
    otherMetricAmount: setValueFor('other_metric_amount'),
    otherMetricName: setValueFor('other_metric_name'),
  });

  const setGoalType = useCallback(() => {
    if (askMetric.raise_goal_type) {
      return askMetric.raise_goal_type;
    }

    if (blockType === 'donation') {
      return 'by_block';
    } else {
      return 'by_experience';
    }
  }, [askMetric.raise_goal_type, blockType]);

  const [raiseGoalType, setRaiseGoalType] = useState('by_experience');

  useEffect(() => {
    setRaiseGoalType(setGoalType());
  }, [setGoalType]);

  const updateGoalState = (key, newValue) => {
    setGoalState({ ...goalState, ...{ [key]: newValue } });
    // Update Context
    setMetricsState((prevState) => ({ ...prevState, [key]: newValue }));
  };

  const updateMetricState = (key, newValue) => {
    setMetricState({ ...metricState, ...{ [key]: newValue } });
    // Update Context
    setMetricsState((prevState) => ({ ...prevState, [key]: newValue }));
  };

  const currencySymbol = () => {
    return CURRENCY_SIGN_MAP[currency];
  };

  const isDonation = () => {
    return blockType === 'donation';
  };

  const handleRaiseGoalTypeChange = (option) => {
    let raiseGoal, raiseGoalName;
    if (option.value === 'by_asker') {
      raiseGoal = GOAL_AND_METRICS_DEFAULT_VALUES.raiseGoalByAsker;
      raiseGoalName = GOAL_AND_METRICS_DEFAULT_VALUES.raiseGoalNameByAsker;
    } else {
      raiseGoal = GOAL_AND_METRICS_DEFAULT_VALUES.raiseGoal;
      raiseGoalName = GOAL_AND_METRICS_DEFAULT_VALUES.raiseGoalName;
    }
    setRaiseGoalType(option.value);
    setGoalState({
      ...goalState,
      ...{ raiseGoal, raiseGoalName },
    });
    // Update context
    setMetricsState((prevState) => ({ ...prevState, raiseGoal, raiseGoalName}));
  };

  return (
    <div data-testid="BlockForm/GoalAndMetricSection">
      <input type="hidden" name="ask[metrics_checked]" value={isChecked} />
      <SwitchWithTitle
        checked={isChecked}
        onChange={handleIsChecked}
        title="Display Goal & Metrics"
        data-testid="GoalAndMetricSection/Switcher"
      >
        <input
          type="hidden"
          name="ask[ask_metric_attributes][id]"
          value={askMetric.id}
        />
        <Fieldset data-testid="GoalAndMetricSection/GoalsSection">
          <Legend>Goal</Legend>
          <GoalSection
            goals={goals}
            goalState={goalState}
            errors={errors}
            updateGoalState={updateGoalState}
            currencySymbol={currencySymbol()}
            isDonation={isDonation()}
            raiseGoalType={raiseGoalType}
            handleRaiseGoalTypeChange={handleRaiseGoalTypeChange}
          />
        </Fieldset>
        <Fieldset data-testid="GoalAndMetricSection/MetricsSection">
          <Legend>Metrics</Legend>
          <MetricSection
            metricState={metricState}
            updateMetricState={updateMetricState}
            errors={errors}
            isDonation={isDonation()}
          />
        </Fieldset>
      </SwitchWithTitle>
    </div>
  );
}

GoalAndMetricSection.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.string).isRequired,
  currency: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  blockType: PropTypes.string.isRequired,
  askMetric: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateBlockAttributes: PropTypes.func.isRequired,
};

export default GoalAndMetricSection;
