import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Fieldset, Label, FormSection, Code } from '../../../../globals';
import InputWithTooltip from '../InputWithTooltip';
import _camelCase from 'lodash/camelCase';

function PaymentButtonsSettings({ primaryColor, errors }) {
  const textInputs = [
    {
      name: 'cta_donation_first_button',
      label: 'Payment amounts button',
      tooltipTitle: (
        <>
          Responders are first asked to select or enter an amount. This button
          will be shown with the amount choices. You may use merge fields{' '}
          <Code>[</Code> to show the selected amount or frequency in this
          button.
        </>
      ),
    },
    {
      name: 'cta_donation_second_button',
      label: 'Data collection button',
      tooltipTitle:
        'Payment blocks with 5 or more data fields will have a page exclusively for data collection. This page will appear second in the payment flow.',
    },
    {
      name: 'cta_donation_third_button',
      label: 'Payment checkout button',
      tooltipTitle:
        'This is the last page responders see before submitting their payment. This page includes the payment method and may include data fields if fewer than 4 data fields are selected.',
    },
  ];

  return (
    <Fieldset>
      <Label
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        Submission button settings
      </Label>
      <FormSection style={{ paddingBottom: '2rem' }}>
        {textInputs.map(({ label, tooltipTitle, name}, index) => {
          return (
            <InputWithTooltip
              index={index}
              key={name}
              label={label}
              tooltipTitle={tooltipTitle}
              name={name}
              errors={errors}
              primaryColor={primaryColor}
            />
          );
        })}
      </FormSection>
    </Fieldset>
  );
};

PaymentButtonsSettings.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
};

export default PaymentButtonsSettings;
