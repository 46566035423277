import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transforms, Editor } from 'slate';
import { Slate } from 'slate-react';
import { MentionsInput } from '../../../MentionsInput';
import { keyboardNavigation } from '../../../MentionsInput/keyboardNavigation';
import Leaf from '../Leaf';
import Element from '../Element';
import { insertMention } from '../Mention';
import { elementsToText, buildSlateElements, getAnchorElement, prevCharIs } from '../utils';
import FormulaPicker from '../../../FormulaPicker';

function EditableMentionsElement(props) {
  const {
    experienceId,
    placeholder,
    steps,
    name,
    error,
    initialValue,
    valueToInsert,
    onValueChange,
    onReferenceType,
    editor,
    render,
    autoFocus,
    noTrailingSpace,
    extendKeyBoardNavigation,
  } = props;

  const [elements, setElements] = useState(buildSlateElements(initialValue));
  const [mentionsIsOpen, setMentionsIsOpen] = useState(false);
  const [mentionsItemIndex, setMentionsItemIndex] = useState(0);
  const [formulasAnchorEl, setFormulasAnchorEl] = React.useState(null);
  const renderElement = useCallback((props) => {
    return <Element {...props} editor={editor} />;
  }, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const mentionsItemRef = useRef(null);
  const mentionsListRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      });
    }
  }, []);

  useEffect(() => {
    if (elements) {
      onValueChange(elementsToText(elements), elements);
    }

    editor.children = elements;
  }, [elements]);

  useEffect(() => {
    if (valueToInsert) {
      const value = typeof(valueToInsert) === 'string' ? valueToInsert : elementsToText(valueToInsert);
      Transforms.insertText(editor, value, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      });
    }
  }, [valueToInsert]);

  const handleValueChange = (elements) => {
    if (editor.selection) {
      if (prevCharIs(editor, '[')) {
        setMentionsItemIndex(0);
        setMentionsIsOpen(true);
      } else if (prevCharIs(editor, '=')) {
        const anchorEl = getAnchorElement(editor);
        setFormulasAnchorEl(anchorEl);
      } else {
        setMentionsIsOpen(false);
        setFormulasAnchorEl(null);
      }
    }

    setElements(elements);
  };

  const insertReference = async (reference, type) => {
    insertMention(editor, reference, noTrailingSpace);
    if (onReferenceType) {
      onReferenceType(type);
    }
  };

  const handleKeyDown = (event) => {
    keyboardNavigation(
      event,
      mentionsIsOpen,
      mentionsItemIndex,
      setMentionsItemIndex,
      mentionsItemRef,
      mentionsListRef,
      extendKeyBoardNavigation,
    );
  };

  return (
    <>
      <Slate editor={editor} value={elements} onChange={handleValueChange}>
        {render(renderElement, renderLeaf, handleKeyDown)}
      </Slate>
      <MentionsInput
        name={name}
        placeholder={placeholder}
        experienceId={experienceId}
        isOpen={mentionsIsOpen}
        steps={steps}
        error={error}
        customInput
        onReference={insertReference}
        optionsListRef={mentionsListRef}
        listItemRef={mentionsItemRef}
        listItemIndex={mentionsItemIndex}
        listStyle={{ bottom: '0' }}
      />
      <FormulaPicker
        editor={editor}
        anchorEl={formulasAnchorEl}
        setAnchorEl={setFormulasAnchorEl}
      />
    </>
  );
}

EditableMentionsElement.propTypes = {
  defaultTitleElements: PropTypes.string,
};

export default EditableMentionsElement;
