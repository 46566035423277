import React from 'react';
import CssStyleHiddenInputs from '../../../CssStyleHiddenInputs';

export default function HiddenInputs({ askLabel }) {
  const { richText, id } = askLabel;
  return (
    <>
      <CssStyleHiddenInputs
        cssStyle={askLabel.cssStyle}
        attributesName="ask[ask_label_attributes][css_style_attributes]"
      />
      <input
        type="hidden"
        name="ask[ask_label_attributes][id]"
        defaultValue={id}
      />
      <input
        type="hidden"
        name="ask[ask_label_attributes][rich_text_attributes][id]"
        defaultValue={richText.id}
      />
      <input
        type="hidden"
        name="ask[ask_label_attributes][rich_text_attributes][title]"
        defaultValue={richText.title}
      />
      <input
        type="hidden"
        name="ask[ask_label_attributes][rich_text_attributes][title_elements]"
        defaultValue={richText.titleElements}
      />
      <input
        type="hidden"
        name="ask[ask_label_attributes][rich_text_attributes][title_html]"
        defaultValue={richText.titleHtml}
      />
    </>
  );
}
