import React, { useContext } from 'react';
import { Input } from '../../../../../inputs/Input';
import { EditableMentionsInput } from '../../../../../SlateElements';
import { ExperienceIdContext } from '../../../../index';
import useBuildMentionsSteps from '../../../../../hooks/useBuildMentionsSteps';
import {
  Label,
  FormSection,
  FormSectionDescription,
} from '../../../../../globals';

function OtherAmountForm({ errors, goalState, updateGoalState }) {
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'GoalSection.OtherAmountForm',
  });
  const experienceId = useContext(ExperienceIdContext);

  const handleValueChange = (value, attribute) => {
    if (value[value.length - 1] !== '[') {
      updateGoalState(attribute, value);
    }
  };

  return (
    <FormSection>
      <FormSectionDescription>
        If you have a goal that we don't automatically track, enter it here and
        then manually update it as your results come in.
      </FormSectionDescription>
      <Label>Goal</Label>
      <EditableMentionsInput
        initialValue={goalState.otherGoal.toString()}
        experienceId={experienceId}
        placeholder="ex: 1000"
        steps={mentionSteps}
        name="ask[ask_metric_attributes][other_goal]"
        error={errors['ask_metric.other_goal']}
        onValueChange={(value) => handleValueChange(value, 'otherGoal')}
        inputStyles={{ marginBottom: '1rem' }}
        withHiddenInput
      />
      <Label>Manual tracking</Label>
      <Input
        defaultValue={goalState.otherGoalAmount}
        placeholder="ex: 0"
        name="ask[ask_metric_attributes][other_goal_amount]"
        error={errors['ask_metric.other_goal_amount']}
        onChange={(e) => {
          updateGoalState('otherGoalAmount', e.target.value);
        }}
      />
      <Label>Goal description</Label>

      <EditableMentionsInput
        initialValue={goalState.otherGoalName || ''}
        experienceId={experienceId}
        dataTestId="OtherAmountForm/Input"
        placeholder="ex: voters registered"
        steps={mentionSteps}
        name="ask[ask_metric_attributes][other_goal_name]"
        error={errors['ask_metric.other_goal_name']}
        onValueChange={(value) => handleValueChange(value, 'otherGoalName')}
        withHiddenInput
        autoFocus
      />
    </FormSection>
  );
}

export default OtherAmountForm;
