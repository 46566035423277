import React from 'react';
import Select, { components } from 'react-select';
import { ErrorMessageWrapper } from '../experiences/events/EditActionForm/style';
import { ErrorMessage } from '../globals';

const selectStyles = {
  container: (styles) => ({
    ...styles,
  }),
  control: (styles, state) => ({
    ...styles,
    borderWidth: '0',
    backgroundColor: 'var(--white)',
    border: '1px solid var(--border-color-primary)',
    borderRadius: '8px',
    boxShadow: 'none',
    cursor: 'pointer',
    paddingLeft: '5px',
    fontWeight: 500,
    marginBottom: state.selectProps.noMargin ? 0 : '5px',
    ':hover': {
      borderColor: 'var(--border-color-primary--hover)',
    },
    ':active': {
      borderColor: 'var(--border-color-primary--active)',
    },
    ':focus': {
      borderColor: 'var(--border-color-primary--active)',
    },
    input: {
      margin: '0',
      padding: '5px',
      ':focus': {
        boxShadow: 'none',
      },
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: '0px',
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isDisabled
      ? 'var(--disabled-input-color)'
      : 'var(--font-color-secondary)',
  }),
  menu: (styles) => ({
    ...styles,
    color: 'var(--font-color-secondary--dark)',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '8px',
    backgroundColor: 'var(--white)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    marginTop: '1px',
    zIndex: 10,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: state.isFocused || state.isSelected
        ? 'var(--option-background--selected)'
        : 'var(--option-background)',
      color: state.isDisabled
        ? 'var(--disabled-input-color)'
        : 'var(--font-color-secondary--dark)',
      ':hover': {
        backgroundColor: 'var(--option-background--selected)',
      },
    };
  },
  singleValue: (styles, state) => ({
    ...styles,
    color: state.isDisabled
      ? 'var(--disabled-input-color)'
      : 'var(--font-color-secondary--dark)',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'var(--font-color-secondary--light)',
    fontWeight: 400,
  }),
};

const iconWrapperStyles = {
  height: '25px',
  marginRight: '12px',
};

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    {props.data.icon && <span style={iconWrapperStyles}>{props.data.icon}
    </span>}
    {props.data.label}
  </Option>
);

function SimpleSelect(props) {
  return (
    <div style={{ position: 'relative' }}>
      <Select
        styles={selectStyles}
        {...props}
        components={{ Option: IconOption }}
      />
      {props.error && (
        <ErrorMessageWrapper>
          <ErrorMessage>{props.error}</ErrorMessage>
        </ErrorMessageWrapper>
      )}
    </div>
  );
}

export default SimpleSelect;
