import React from 'react';
import { CustomPicker } from 'react-color';
import Box from '@mui/material/Box';
import { Swatch } from 'react-color/lib/components/common';
import ColorInput from '../ColorInput';
import AlphaInput from '../AlphaInput';

function ColorOpacityInput(props) {
  const { color, handleOpenColorPicker, withAlpha } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid var(--border-color-primary)',
        height: 50,
        m: '0.5rem 0 0 0',
        p: 0,
        borderRadius: '8px',
        overflow: 'hidden',
      }}
      data-testid="ColorOpacityInput/Container"
    >
      <Swatch
        style={{
          width: '2rem',
          height: '2rem',
          marginLeft: '0.5rem',
          borderRadius: '8px',
        }}
        color={color.hex || color}
        onClick={handleOpenColorPicker}
      />
      <ColorInput {...props} />
      {withAlpha && <AlphaInput {...props} />}
    </Box>
  );
}

export default CustomPicker(ColorOpacityInput);
