import React from 'react';
import { useSlate, useFocused } from 'slate-react';
import { Editor, Range } from 'slate';
import Portal from '@mui/material/Portal';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import MarkButton from '../../../BlockForm/components/TitleSection/components/MarkButton';
import { Divider } from '../../../BlockForm/components/TitleSection/components/ButtonsContainer/style';
import HighlightPicker from '../../../BlockForm/components/TitleSection/components/HighlightPicker';
import ColorPicker from '../../../BlockForm/components/TitleSection/components/ColorPicker';
import BlockButton from '../../../BlockForm/components/TitleSection/components/BlockButton';
import LetterSettings from '../../../BlockForm/components/TitleSection/components/LetterSettings';
import LinksButton from '../../../BlockForm/components/TitleSection/components/LinksButton';
import Select from '../../../BlockForm/components/TitleSection/components/Select';
import { getMobileOS } from '../../../../utils';
import ParagraphButton from '../../../BlockForm/components/TitleSection/components/ParagraphButton';
import BlockContext, {
  FONT_WEIGHT_MENU_ITEMS,
} from '../../../../contexts/BlockContext';
import useHorizontalScroll from '../../../hooks/useHorizontalScroll';

function HoveringToolbar({ portalRef }) {
  const [show, setShow] = React.useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = React.useState(false);
  const [
    { fontsList, fontsSelectItems, fontWeightsSelectItems, paletteColors },
    setBlockState,
  ] = React.useContext(BlockContext);
  const editor = useSlate();
  const inFocus = useFocused();
  const {
    scrollContainerRef,
    handleScroll,
    isAtStart,
    isAtEnd,
  } = useHorizontalScroll();

  React.useEffect(() => {
    const { selection } = editor;

    if (popoverIsOpen) {
      setShow(true);
      return;
    }
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  });

  const topPosition = React.useMemo(
    () => (getMobileOS() === 'Android' ? '48px' : '-42px'),
    [],
  );

  const handleFontFamilyChange = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      fontWeightsSelectItems: FONT_WEIGHT_MENU_ITEMS.map((item) => ({
        ...item,
        disabled: !fontsList
          .find((f) => f.font_family === value)
          ?.font_weights?.includes(item.value),
      })),
    }));
  };

  return (
    <Portal container={portalRef.current}>
      <Box
        sx={{
          display: show ? 'flex' : 'none',
          position: 'absolute',
          left: '-22px',
          top: topPosition,
          maxWidth: '430px',
          overflowX: 'auto',
          overflowY: 'hidden',
          height: 56,
          pl: isAtStart ? '12px' : 0,
          pr: isAtEnd ? '12px' : 0,
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0px 0px 16px rgba(51, 51, 51, 0.12)',
          zIndex: 1,
          '&::before': {
            content: isAtStart ? 'none' : '""',
            position: 'sticky',
            left: 0,
            bottom: 0,
            height: 56,
            width: 12,
            background: 'linear-gradient(270deg, rgba(229, 229, 229, 0.12) 0%, #bfbfbf 100%)',
          },
          '&::after': {
            content: isAtEnd ? 'none' : '""',
            position: 'sticky',
            mr: '-100%',
            right: 0,
            bottom: 0,
            height: 56,
            width: 12,
            background: 'linear-gradient(90deg, rgba(229, 229, 229, 0.12) 0%, #bfbfbf 100%)',
          },
        }}
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Select
            onPopover={setPopoverIsOpen}
            menuItems={fontsSelectItems}
            variant="fontFamily"
            defaultValue="inherit"
            ariaLabel="font select"
            onChange={handleFontFamilyChange}
          />
          <Select
            onPopover={setPopoverIsOpen}
            menuItems={fontWeightsSelectItems}
            variant="fontWeight"
            defaultValue={400}
            ariaLabel="font weight select"
          />
          <ColorPicker onPopover={setPopoverIsOpen} paletteColors={paletteColors} />
          <HighlightPicker onPopover={setPopoverIsOpen} paletteColors={paletteColors} />
          <ParagraphButton onPopover={setPopoverIsOpen} />
          <Divider />
          <MarkButton format="bold" />
          <MarkButton format="italic" />
          <MarkButton format="strikethrough" />
          <MarkButton format="underline" />
          <Divider />
          <BlockButton format="left" />
          <BlockButton format="center" />
          <BlockButton format="right" />
          <Divider />
          <LinksButton onPopover={setPopoverIsOpen} />
          <Divider />
          <LetterSettings onPopover={setPopoverIsOpen} />
        </Stack>
      </Box>
    </Portal>
  );
}

export default HoveringToolbar;
