import React, { useState } from 'react';
import SimpleSelect from '../../../select';
import SubmissionAmountForm from './components/SubmissionAmountForm';
import RaiseAmountForm from './components/RaiseAmountForm';
import OtherAmountForm from './components/OtherAmountForm';

const OPTION_LABELS = {
  submission_amount: 'Number of submissions',
  raise_amount: 'Amount raised',
  other_amount: 'Other',
  none: 'No goal',
};

function GoalSection({
  goals,
  goalState,
  errors,
  updateGoalState,
  currencySymbol,
  isDonation,
  raiseGoalType,
  handleRaiseGoalTypeChange,
}) {
  const [selectedGoal, setSelectedGoal] = useState(goalState.goal);

  const options = goals.map((goal) => {
    return { value: goal, label: OPTION_LABELS[goal] };
  });

  const handleSelectGoal = (selectedOption) => {
    setSelectedGoal(selectedOption.value);
    updateGoalState('goal', selectedOption.value);
  };

  return (
    <>
      <SimpleSelect
        options={options}
        defaultValue={options.find((option) => option.value == selectedGoal)}
        onChange={(value) => handleSelectGoal(value)}
        isSearchable={false}
        name="ask[ask_metric_attributes][goal]"
        classNamePrefix="react-select__goals"
      />
      {selectedGoal === 'submission_amount' && (
        <SubmissionAmountForm
          errors={errors}
          goalState={goalState}
          updateGoalState={updateGoalState}
        />
      )}
      {selectedGoal === 'raise_amount' && (
        <RaiseAmountForm
          errors={errors}
          isDonation={isDonation}
          goalState={goalState}
          updateGoalState={updateGoalState}
          raiseGoalType={raiseGoalType}
          handleRaiseGoalTypeChange={handleRaiseGoalTypeChange}
          currencySymbol={currencySymbol}
        />
      )}
      {selectedGoal === 'other_amount' && (
        <OtherAmountForm
          errors={errors}
          goalState={goalState}
          updateGoalState={updateGoalState}
        />
      )}
    </>
  );
}

export default GoalSection;
