import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionLabel = styled.label`
  width: 6.5rem;
  height: 6.5rem;
  display: block;
  border-radius: 10px;
  padding: 2px;
  box-sizing: border-box;
  margin-right: 16px;
  background: ${(props) => props.background || 'unset'};
  border: ${(props) =>
    props.active
      ? '3px solid var(--primary-color)'
      : '3px solid var(--border-color-primary)'};
  opacity: ${(props) => (props.active ? '1' : '0.8')};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
      transition: transform 0.2s ease;
    }
  }
`;

export const LabelText = styled.p`
  width: 6.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const OptionIcon = styled.div`
  background: ${(props) =>
    props.background ? `url(${props.background})` : 'unset'};
  display: block;
  border-radius: 6px;
  width: 100%;
  height: 100%;

  & svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const HiddenRadioButton = styled.input`
  display: none;
`;
