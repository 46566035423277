import React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import IconBox from '../IconBox';
import { useMuiPopover } from '../../../hooks';

function ElementIcon({ style }) {
  return (
    <span
      style={{
        display: 'inline-block',
        width: '24px',
        height: '12px',
        marginRight: '16px',
        border: '2px solid rgba(51, 51, 51, 0.54)',
        ...style,
      }}
    />
  );
}

function BorderRadius({
  initialRadius,
  handleSetBorderRadius,
  roundedCornersValue,
}) {
  const radiusValues = {
    ROUNDED_CORNERS: roundedCornersValue,
    SQUARED_CORNERS: '0px',
  };
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'CustomToolbar/BorderRadius',
  );

  const handleItemClick = (radius) => () => {
    handleSetBorderRadius(radius);
    handleClose();
  };

  return (
    <>
      <IconBox isOpen={open} tooltipTitle="Border radius">
        <PanoramaFishEyeIcon onClick={handleOpen} />
      </IconBox>
      <Popover
        id={id}
        data-testid={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            padding: '0',
            borderRadius: '12px',
          },
        }}
      >
        <Typography
          variant="subtitle1"
          component="p"
          onClick={handleItemClick(radiusValues.ROUNDED_CORNERS)}
          sx={{
            padding: '16px',
            cursor: 'pointer',
            backgroundColor: `${
              initialRadius === radiusValues.ROUNDED_CORNERS
                ? 'rgba(29, 29, 29, 0.08)'
                : 'transparent'
            }`,
          }}
        >
          <ElementIcon style={{ borderRadius: '12px' }} />
          Rounded corners
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          onClick={handleItemClick(radiusValues.SQUARED_CORNERS)}
          sx={{
            padding: '16px',
            cursor: 'pointer',
            backgroundColor: `${
              initialRadius === radiusValues.SQUARED_CORNERS
                ? 'rgba(29, 29, 29, 0.08)'
                : 'transparent'
            }`,
          }}
        >
          <ElementIcon />
          Squared corners
        </Typography>
      </Popover>
    </>
  );
}

export default BorderRadius;
