import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import RadioButtonGroup from '../../../RadioButtonGroup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { nanoid } from 'nanoid';
import Condition from '../../../../../experiences/transitions/ConditionalTransitionForm/components/Condition';
import { NewItemButton } from '../../../../../Button';

const radioButtons = [
  {
    value: false,
    Icon: <CheckIcon />,
    ariaLabel: 'show field',
  },
  {
    value: true,
    Icon: <CloseIcon />,
    ariaLabel: 'hide field',
  },
  {
    value: 'condition',
    Icon: <RouteOutlinedIcon />,
    ariaLabel: 'show condition',
  },
];

const emptyCondition = [
  {
    first_value: '',
    second_value: '',
    operator: 'equals',
    data_type: 'string',
    nanoid: nanoid(),
  },
];

export default function ShowFieldSection({
  selectedValue,
  onChange,
  mentionSteps,
  experienceId,
  showConditions,
  errors = {},
}) {
  const [conditions, setConditions] = React.useState([
    {
      conditions_attributes: _isEmpty(showConditions) ? emptyCondition : showConditions,
    },
  ]);

  React.useEffect(() => {
    if (selectedValue === 'condition') {
      onChange({
        showConditions: conditions[0].conditions_attributes,
      });
    }
  }, [selectedValue, conditions]);

  const handleAddCondition = () => {
    const newCondition = { ...emptyCondition[0], nanoid: nanoid() };
    setConditions((prev) => [
      {
        conditions_attributes: [
          ...prev[0].conditions_attributes,
          newCondition,
        ],
      },
    ]);
  };

  const handleChange = (value) => {
    if (value === 'condition') {
      onChange({
        hidden: null,
        showConditions: conditions[0].conditions_attributes,
      });
    } else {
      const conditionsWithId = conditions[0].conditions_attributes.filter((c) => c.id);

      onChange({
        hidden: value,
        showConditions: conditionsWithId.map((c) => ({ ...c, _destroy: true })),
      });
    }
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', pt: '1rem', mb: '1rem' }}
      >
        <Box sx={{ fontWeight: 500 }}>Show Field</Box>
        <Box sx={{ ml: 'auto' }}>
          <RadioButtonGroup
            selectedValue={selectedValue}
            buttons={radioButtons}
            onChange={handleChange}
            sizeSmall
          />
        </Box>
      </Box>
      {selectedValue === 'condition' && (
        <Stack gap={1}>
          {conditions[0].conditions_attributes.map((condition, i) => (
            <Condition
              key={condition.id || condition.nanoid}
              conditionIndex={i}
              ruleIndex={0}
              label={i === 0 ? 'If' : 'And'}
              experienceId={experienceId}
              mentionSteps={mentionSteps}
              conditionAttributes={condition}
              onChange={setConditions}
              withHiddenInput
              errors={errors.show_condition?.conditions && errors.show_condition.conditions[i]}
            />
          ))}
          <NewItemButton size="small" onClick={handleAddCondition}>
            + Add condition
          </NewItemButton>
        </Stack>
      )}
    </>
  );
}
