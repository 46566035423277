import _countBy from 'lodash/countBy';
import _findIndex from 'lodash/findIndex';
import { Transforms, Node } from 'slate';
import { nanoid } from 'nanoid';
import { insertMention } from '../Mention';

export function buildSlateElements(string) {
  const children = string.split(/({{.*?}})/g).map((text) => {
    if (text.match(/{{.*}}/)) {
      return {
        mentionId: nanoid(),
        type: 'mention',
        children: [{ text: ''  }],
        reference: text,

      };
    } else {
      return {text};
    }
  });


  return [{
    type: 'paragraph',
    children,
  }];
};

function elementToText(node) {
  if (!node.type) {
    return '';
  }

  const childrens = elementsToText(node.children);

  switch (node.type) {
    case 'mention':
      return node.reference;
    default:
      return childrens;
  }
};

export function elementsToText(nodes) {
  return nodes.map(elementToText).join('');
}

export function updateMention(editor, reference, noTrailingSpace) {
  try {
    const at = [0, 1];
    const el = Node.get(editor, at);
    if (el.type === 'mention') {
      Transforms.delete(editor, {at});
    }
  } catch {}

  insertMention(editor, reference, noTrailingSpace);
}

export function withSingleLine(editor) {
  const { normalizeNode  } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
}

export function withReadOnly(editor) {
  editor.insertText = () => {};

  return editor;
}
