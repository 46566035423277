import React from 'react';
import styled from 'styled-components';
import { Editable, ReactEditor } from 'slate-react';
import DragIcon from '../../../icons/DragIcon';
import { InputWrapper, StyledErrorMessage, InputPrefixInline } from '../../../inputs/Input/style';
import HoveringToolbar from '../HoveringToolbar';

const Input = styled(Editable)`
  display: block;
  width: 100%;
  max-width: 100%;
  background: var(--white);
  color: var(--font-color-secondary);
  border: 1px solid var(--border-color-primary);
  border-left: none;
  padding: 0.5rem 15px;
  border-radius: 0px 8px 8px 0px;
  height: 32px;
  margin: 0px;
  box-sizing: content-box;
  line-height: 2;
  cursor: text;
  overflow: hidden;
  flex-grow: 0;
  white-space: pre !important;

  & span.MuiChip-root {
    margin: 0;
  }
`;

function DraggableInput({ editor, noMargin, isDraggingOver, ...props }) {
  const portalRef = React.useRef(null);

  React.useEffect(() => {
    if (isDraggingOver) {
      ReactEditor.blur(editor);
    }
  }, [isDraggingOver]);

  return (
    <div ref={portalRef}>
      <HoveringToolbar portalRef={portalRef} />
      <InputWrapper noMargin={noMargin}>
        <InputPrefixInline>
          <DragIcon fill="var(--font-color-secondary)" />
        </InputPrefixInline>
        <Input {...props} />
        {props.error ? (
          <StyledErrorMessage>{props.error}</StyledErrorMessage>
        ) : null}
      </InputWrapper>
    </div>
  );
};

export default DraggableInput;
