import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import styled from 'styled-components';
import { Input } from '../../../inputs/Input';
import MentionTitle from '../../../MentionTitle';

const RedirectInput = styled(Input)`
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: -7px;
`;

const OptionWithReferencesTags = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <MentionTitle title={children} />
    </components.Option>
  );
};

const SingleValueWithReferencesTags = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <MentionTitle title={children} />
    </components.SingleValue>
  );
};

const prefix = ({ startLabel }) => ({
  ':before': {
    color: '#BDBDBD',
    content: startLabel && `"${startLabel}"`,
    marginRight: '8px',
  },
});

const selectStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    width: '0px',
  }),
  control: (styles, state) => ({
    ...styles,
    borderWidth: '1px',
    borderRadius: '8px',
    boxShadow: 'none',
    cursor: 'pointer',
    paddingLeft: '5px',
    fontWeight: 500,
    borderBottomRightRadius: state.selectProps.flattenBorder
      ? '0'
      : '8px',
    borderBottomLeftRadius: state.selectProps.flattenBorder
      ? '0'
      : '8px',

    ':hover': {
      borderWidth: '1px',
    },

    ':active': {
      borderWidth: '1px',
    },

    input: {
      margin: '0',
      padding: '5px',
    },
  }),
  menu: (styles) => ({
    ...styles,
    color: '#000',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    marginTop: '1px',
    zIndex: 2,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    cursor: 'pointer',
    color: isDisabled ? '#ccc' : isSelected ? '#fff' : isFocused ? '000' : null,
    ':active': {
      color: '#000',
    },
    ':selected': {
      color: '#fff',
    },
    ':focused': {
      color: '#fff',
    },
  }),
  input: (styles, { selectProps }) => ({ ...styles, ...prefix(selectProps) }),
  placeholder: (styles, { selectProps }) => ({ ...styles, ...prefix(selectProps) }),
  singleValue: (styles, { selectProps }) => ({ ...styles, ...prefix(selectProps) }),
};

function Select({
  id,
  name,
  options,
  startLabel,
  defaultValue,
  onChange,
  flattenBorder,
}) {
  return (
    <ReactSelect
      id={id}
      name={name}
      options={options}
      startLabel={startLabel}
      components={{
        Option: OptionWithReferencesTags,
        SingleValue: SingleValueWithReferencesTags,
      }}
      value={defaultValue}
      defaultValue={defaultValue}
      isOptionSelected={(option, optionsArray) => (
        option.label === optionsArray[0].label
      )}
      isSearchable={false}
      closeMenuOnSelect={true}
      defaultMenuIsOpen={false}
      onChange={onChange}
      classNamePrefix="select"
      styles={selectStyles}
      flattenBorder={flattenBorder}
    />
  );
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  classNamePrefix: PropTypes.string,
  styles: PropTypes.object,
  flattenBorder: PropTypes.bool,
};

export default Select;
export { RedirectInput };
