import React from 'react';
import { Fieldset, Legend, FormSection, Label } from '../../../globals';
import TilesSelector from '../../../tilesSelector';
import AutofitIcon from '../../../../../assets/images/content_layout--autofit.png';
import HugContentIcon from '../../../../../assets/images/content_layout--hug.png';
import BlockContext from '../../../../contexts/BlockContext';
import RadioButtonGroup from '../RadioButtonGroup';
import PromptSettingsSection from '../PromptSettingsSection';
import { Input } from '../../../inputs/Input';
import Stack from '@mui/material/Stack';
import StartIcon from '@mui/icons-material/VerticalAlignTop';
import CenteredIcon from '@mui/icons-material/VerticalAlignCenterOutlined';
import EndIcon from '@mui/icons-material/VerticalAlignBottom';

const options = [
  {
    name: 'autofit',
    value: 'autofit',
    label: 'Autofit',
    testId: 'ContentLayout/autofit',
    icon: AutofitIcon,
  },
  {
    name: 'hug_content',
    value: 'hug_content',
    label: 'Hug content',
    testId: 'ContentLayout/hugContent',
    icon: HugContentIcon,
  },
];

const radioButtons = [
  {
    value: 'start',
    Icon: <StartIcon />,
    ariaLabel: 'start alignment',
  },
  {
    value: 'center',
    Icon: <CenteredIcon />,
    ariaLabel: 'center alignment',
  },
  {
    value: 'end',
    Icon: <EndIcon />,
    ariaLabel: 'end alignment',
  },
];

export default function ContentLayoutSection({ titleSetting, errors }) {
  const [blockState, setBlockState] = React.useContext(BlockContext);

  const handleChange = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      contentLayout: value,
    }));
  };

  const handleFontSizeChange = (e) => {
    setBlockState((prevState) => ({
      ...prevState,
      hugContentFontSize: e.target.value,
    }));
  };

  const handleMaxWidthChange = (e) => {
    setBlockState((prevState) => ({
      ...prevState,
      hugContentMaxWidth: e.target.value,
    }));
  };

  const handleVerticalAlignmentChange = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      contentVerticalAlignment: value,
    }));
  };

  return (
    <Fieldset>
      <Legend>Layout Settings</Legend>
      <FormSection>
        <Label>Content Layout</Label>
        <div aria-label="content layout buttons">
          <TilesSelector
            options={options}
            defaultValue={blockState.contentLayout}
            onChange={handleChange}
          />
        </div>
        {blockState.contentLayout === 'autofit' && (
          <PromptSettingsSection titleSetting={titleSetting} errors={errors} />
        )}
        {blockState.contentLayout === 'hug_content' && (
          <Stack gap={2} direction="row" flexWrap="wrap" sx={{ my: '1rem' }}>
            <div>
              <Label>Vertical Alignment</Label>
              <RadioButtonGroup
                selectedValue={blockState.contentVerticalAlignment}
                buttons={radioButtons}
                onChange={handleVerticalAlignmentChange}
              />
            </div>
            <div>
              <Label>Font size</Label>
              <Input
                type="number"
                placeholder="px"
                value={blockState.hugContentFontSize}
                name="ask[hug_content_font_size]"
                onChange={handleFontSizeChange}
                inputStyle={{ maxWidth: '186px' }}
                noMargin
              />
            </div>
            <div>
              <Label>Max width</Label>
              <Input
                type="number"
                placeholder="px"
                value={blockState.hugContentMaxWidth}
                name="ask[hug_content_max_width]"
                onChange={handleMaxWidthChange}
                inputStyle={{ maxWidth: '186px' }}
                noMargin
              />
            </div>
          </Stack>
        )}
      </FormSection>
      <input
        type="hidden"
        name="ask[content_layout]"
        value={blockState.contentLayout}
      />
      <input
        type="hidden"
        name="ask[content_vertical_alignment]"
        value={blockState.contentVerticalAlignment}
      />
    </Fieldset>
  );
}
