import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 8px;
  border: 1px solid var(--border-color-primary);
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  opacity: 0.85;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
`;

export const TextWrapper = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: var(--font-color-primary--light);
  margin-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 6px;
  margin-left: auto;
  cursor: pointer;

  svg {
    color: #727278;
    transition: color 0.2s;
  }

  &:hover {
    svg {
      color: #dadada;
    }
  }
`;
