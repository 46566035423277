import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  FormSubsection,
  FormSectionDescription,
  Textarea,
  ErrorMessageFloat,
  Label,
} from '../../../globals';
import Checkbox from '../../../checkbox';
import { Input } from '../../../inputs/Input';
import { PreviewContainer } from './style';
import BlockContext from '../../../../contexts/BlockContext';

const prepareUrl = (url) => {
  const urlPattern = /^((http)|(\/\/))/;

  if (url.match(urlPattern)) {
    return url;
  } else {
    return `//${url}`;
  }
};

export const generateDisclaimerPreviewText = (text, url) => {
  const textWithUrl = text.replace(
    /\[(.+?)\]/g,
    `<a href="${prepareUrl(url)}" target="_blank">$1</a>`,
  );

  if (url === '') {
    return text;
  } else {
    return <div dangerouslySetInnerHTML={{ __html: textWithUrl }} />;
  }
};

function Disclaimer({
  isChecked: defaultChecked,
  text: defaultText,
  link: defaultLink,
  errors,
}) {
  const [checked, setChecked] = useState(defaultChecked);
  const [text, setText] = useState(
    defaultText || 'I agree to the [Terms and Conditions]',
  );
  const [link, setLink] = useState(defaultLink || '');
  const [, setBlockState] = useContext(BlockContext);

  const updateDisclaimer = (property, value) => {
    setBlockState((prevState) => ({
      ...prevState,
      disclaimer: {
        ...prevState.disclaimer,
        [property]: value,
      },
    }));
  };

  useEffect(() => {
    setBlockState((prevState) => ({
      ...prevState,
      disclaimer: {
        ...prevState.disclaimer,
        tocLabel: text,
        url: link,
      },
    }));
  }, [checked]);

  const handleChange = (e) => {
    const value = e.target.checked;

    setChecked(value);
    setBlockState((prevState) => ({
      ...prevState,
      disclaimerChecked: value,
    }));
  };

  const handleTextChange = (e) => {
    const value = e.target.value;

    setText(value);
    updateDisclaimer('tocLabel', value);
  };

  const handleLinkChange = (e) => {
    const value = e.target.value;

    setLink(value);
    updateDisclaimer('url', value);
  };

  return (
    <div data-testid="BlockForm/Disclaimer">
      <Checkbox
        label="Disclaimer"
        checkedByDefault={checked}
        onChange={handleChange}
        withInput
        data-testid="Disclaimer/Checkbox"
        name="ask[disclaimer_checked]"
      />
      {checked && (
        <FormSubsection>
          {errors['disclaimer.toc_label'] && (
            <ErrorMessageFloat>
              {errors['disclaimer.toc_label'][0]}
            </ErrorMessageFloat>
          )}
          <Label>Disclaimer text</Label>
          <Textarea
            name="ask[disclaimer_attributes][toc_label]"
            value={text}
            onChange={handleTextChange}
          />
          <FormSectionDescription>
            Put optional linked text in [ ]
          </FormSectionDescription>
          <Label>Disclaimer link</Label>
          <Input
            value={link}
            name="ask[disclaimer_attributes][url]"
            onChange={handleLinkChange}
            placeholder="ie: https://example.com/terms"
            error={errors['disclaimer.url']}
          />
          <Label>Preview</Label>
          <PreviewContainer>
            <Checkbox
              label={generateDisclaimerPreviewText(text, link)}
              data-testid="Disclaimer/PreviewCheckbox"
              checkedByDefault={false}
            />
          </PreviewContainer>
        </FormSubsection>
      )}
    </div>
  );
}

Disclaimer.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  text: PropTypes.string,
  link: PropTypes.string,
  errors: PropTypes.object.isRequired,
};

export default Disclaimer;
