import React from 'react';
import PropTypes from 'prop-types';
import { useHover } from 'use-events';
import DragIcon from '../../../icons/DragIcon';
import DataFieldEditForm from '../DataFieldEditForm';
import {
  MoreHorizontal,
  AlignLeft,
  User,
  MapPin,
  Mail,
  Phone,
  Calendar,
  Briefcase,
  List,
} from 'react-feather';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import { DropdownMenu } from '../../../DropdownMenu';
import MentionsTitle from '../../../MentionTitle';
import { Container, DropdownWrapper, Circle, LabelWrapper } from './style';
import { FlexRow } from '../../../globals';
import Modal from '../../../modal';
import CustomizeToolbar from '../../../CustomizeToolbar';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const menuItems = [
  {
    title: 'Edit',
    value: 'edit',
  },
  {
    title: 'Delete',
    value: 'delete',
  },
];

const renderIcon = (type) => {
  switch (type) {
    case 'text':
      return <AlignLeft />;
    case 'paragraph_text':
    case 'rich_text':
      return <AlignLeft />;
    case 'first_name':
    case 'last_name':
    case 'full_name':
      return <User />;
    case 'address':
    case 'city':
    case 'state':
    case 'zip_code':
    case 'country':
      return <MapPin />;
    case 'email':
      return <Mail />;
    case 'phone':
      return <Phone />;
    case 'date_of_birth':
      return <Calendar />;
    case 'multiple_choice':
      return <List />;
    case 'integer':
      return <LooksOneOutlinedIcon />;
    case 'money':
      return <PaymentsOutlinedIcon />;
    case 'company':
    case 'occupation':
    case 'currently_employed':
      return <Briefcase />;
    case 'checkbox':
      return <CheckBoxOutlinedIcon />;
    case 'upload':
      return <UploadFileIcon />;
    case 'signature':
      return <DrawOutlinedIcon />;
  }
};

const labelDataTestid = 'DataField/Label';

export const DataField = ({
  id,
  index,
  type,
  currency,
  hidden,
  showConditions,
  checkboxHidden,
  richText,
  required,
  cssStyle,
  multipleChoiceOptions,
  defaultValue,
  dragHandleProps,
  onChange,
  onSave,
  errors,
}) => {
  const [creationFormIsActive, setCreationFormIsActive] = React.useState(!!errors?.show_condition?.conditions);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [isHover, bind] = useHover();

  const openCreationForm = (e) => {
    if (e.target.dataset.testid === labelDataTestid) {
      setCreationFormIsActive(true);
    }
  };

  const saveDataField = (newDataField) => {
    onSave(newDataField, index);
    setCreationFormIsActive(false);
  };

  const handleDropdownSelect = (value) => {
    switch (value) {
      case 'edit':
        setCreationFormIsActive(true);
        break;
      case 'delete':
        setModalIsOpen(true);
        break;
    }
  };

  const hideModal = () => {
    setModalIsOpen(false);
  };

  const handleDeleteField = (e) => {
    e.stopPropagation();
    onChange(index, 'delete');

    hideModal();
  };

  const modalButtons = [
    { title: 'Cancel', onClick: hideModal },
    { title: 'Delete', onClick: handleDeleteField, primary: true },
  ];

  return (
    <>
      {creationFormIsActive ? (
        <>
          <span {...dragHandleProps} />
          <DataFieldEditForm
            isActive={creationFormIsActive}
            setIsActive={setCreationFormIsActive}
            initialDataField={{
              id,
              type,
              required,
              currency,
              hidden,
              showConditions,
              checkboxHidden,
              multipleChoiceOptions,
              defaultValue,
              richText,
            }}
            onSave={saveDataField}
            errors={errors}
          />
        </>
      ) : (
        <Container
          {...bind}
          onClick={openCreationForm}
          data-testid="DataFieldsSection/DataFieldWrapper"
        >
          <FlexRow {...dragHandleProps}>
            <DragIcon fill="var(--font-color-secondary)" />
          </FlexRow>
          <FlexRow>{renderIcon(type)}</FlexRow>
          <LabelWrapper isHidden={hidden} data-testid={labelDataTestid}>
            <MentionsTitle title={richText.title} />
            {required && <Circle />}
            {hidden && (
              <VisibilityOffOutlinedIcon
                sx={{ fontSize: 18, ml: 1, transform: 'translateY(4px)' }}
              />
            )}
          </LabelWrapper>
          <CustomizeToolbar
            cssStyle={cssStyle}
            index={index}
            isHover={isHover}
            handleSave={onSave}
            type="dataField"
          />
          <DropdownWrapper>
            <DropdownMenu
              dataTestId="DataField/DropdownMenu"
              menuItems={menuItems}
              triggerButton={<MoreHorizontal />}
              onSelection={handleDropdownSelect}
              disablePortal
            />
          </DropdownWrapper>
        </Container>
      )}
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          title="Confirm"
          content="Are you sure you want to delete this field?"
          buttons={modalButtons}
          hideModal={hideModal}
        />
      )}
    </>
  );
};

DataField.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
