import React from 'react';
import PropTypes from 'prop-types';
import { DirectUpload } from '@rails/activestorage';
import {
  Container,
  SubmitButtonWrapper,
  HelperText,
  ErrorWrapper,
} from './style';
import { ErrorMessage } from '../../../../../../../globals';

const UploadForm = ({
  attachMedia,
  mediaIsUploading,
  setMediaIsUploading,
  setCurrentMediaUrl,
  setContentType,
  setFilename,
  directUploadUrl,
}) => {
  const [error, setError] = React.useState();
  const inputFileRef = React.useRef(null);

  const uploadMedia = (e) => {
    e.preventDefault();

    inputFileRef.current.click();
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];

    // 20mb file size validation
    if (file.size > 20000000) {
      setError('file size must be less than or equal to 20 MB');
      return;
    }

    setCurrentMediaUrl(URL.createObjectURL(file));
    setMediaIsUploading(true);
    setError(null);

    const upload = new DirectUpload(file, directUploadUrl);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        setMediaIsUploading(false);
        attachMedia(blob.signed_id);
        setFilename(blob.filename);
        setContentType(blob.content_type);
      }
    });
  };

  return (
    <Container>
      <SubmitButtonWrapper>
        <button
          onClick={uploadMedia}
          disabled={mediaIsUploading}
          data-testid="UploadForm/UploadButton"
        >
          Upload
        </button>
      </SubmitButtonWrapper>
      <HelperText>Works with png, svg, jpg, gif, and video</HelperText>
      <input
        ref={inputFileRef}
        type="file"
        accept="image/jpeg, image/pjpeg, image/png, image/gif, image/svg+xml, video/*"
        style={{ display: 'none' }}
        onChange={handleUpload}
      />
      {error && (
        <ErrorWrapper>
          <ErrorMessage data-testid="UploadForm/ErrorMessage">
            {error}
          </ErrorMessage>
        </ErrorWrapper>
      )}
    </Container>
  );
};

UploadForm.propTypes = {
  attachMedia: PropTypes.func.isRequired,
  setCurrentMediaUrl: PropTypes.func.isRequired,
  setContentType: PropTypes.func.isRequired,
  setFilename: PropTypes.func.isRequired,
  directUploadUrl: PropTypes.string,
};

export default UploadForm;
