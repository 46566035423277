import styled from 'styled-components';

export const Container = styled.div`
  height: 44px;
  display: grid;
  grid-template-columns: 32px 38px auto 32px 32px;
  align-items: center;
  padding-right: 12px;
  padding-left: 4px;
  margin-bottom: 4px;
  margin-top: 4px;
  color: var(--font-color-primary);
  background-color: var(--surface-background--dark);
  border: 1px solid var(--border-color-primary);
  border-radius: 8px;
  cursor: pointer;
`;

export const LabelWrapper = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  opacity: ${props => props.isHidden ? 0.5 : 1};
`;

export const Circle = styled.span`
  display: inline-block;
  height: 4px;
  width: 4px;
  transform: translate(2px, -8px);
  background: var(--error);
  border-radius: 50%;
`;

export const DropdownWrapper = styled.div`
  .MuiIconButton-root {
    padding: 0;
    color: var(--font-color-secondary--light);
    transition: color 0.2s;

    &:hover {
      background-color: transparent;
      color: var(--font-color-primary);
    }
  }
`;
