import React from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';
import Box from '@mui/material/Box';
import Checkbox from '../../../checkbox';
import { NewItemButton } from '../../../Button';
import { DonationInputWrapper, RightButtonWrapper } from './style';
import { Label, FormSection, Hint } from '../../../globals';
import BlockContext from '../../../../contexts/BlockContext';
import OptionInput from './components/OptionInput';
import BlockLabelInput from '../BlockLabelInput';

const MAX_OPTION_ITEMS_COUNT = 100;
const MIN_OPTION_ITEMS_COUNT = 1;

// Transform options array before updating Context
const transformOptions = (options) =>
  options.filter((option) => !option.deleted);

function MultipleChoiceSection({
  hint,
  optionsList,
  otherIsChecked,
  multiIsChecked,
  withoutMultiSelect,
  withoutCheckboxes,
  withoutInputNames,
  updateBlockAttributes,
  onOptionsChange,
}) {
  const [, setBlockState] = React.useContext(BlockContext);

  const initOption = {
    id: '',
    title: '',
    deleted: false,
    error: null,
    richText: { title: '', titleHtml: '', titleElements: '' },
    draggableId: nanoid(),
  };

  const currentOptions = () => {
    const optionsByAsk = optionsList.map((option) => {
      let error = '';
      const { errors } = option;
      if (errors) {
        error = errors['rich_text.title'] || errors.name;
      }
      return {
        id: option.id,
        richText: option.richText,
        cssStyle: option.cssStyle,
        deleted: false,
        error: error && error[0],
        draggableId: nanoid(),
      };
    });

    return optionsByAsk.length === 0 ? [initOption] : optionsByAsk;
  };

  const [optionItems, setOptionItems] = React.useState(currentOptions());
  const addOptionItem = () => {
    if (MAX_OPTION_ITEMS_COUNT > optionItems.length) {
      const lastItem = optionItems[optionItems.length - 1];
      const newOptionItem = initOption;

      if (lastItem && lastItem.cssStyle) {
        delete lastItem.cssStyle.id;

        newOptionItem.cssStyle = lastItem.cssStyle;
      }

      setOptionItems([...optionItems, newOptionItem]);
    }
  };

  const addNewInput = (e) => {
    e.preventDefault();

    addOptionItem();
  };

  const handleOptionsChange = (items) => {
    if (onOptionsChange) {
      onOptionsChange(items);
    }

    setOptionItems(items);
    setBlockState((prevState) => ({
      ...prevState,
      multipleChoiceOptions: transformOptions(items),
    }));
  };

  const removeInput = (indexForDelete, e) => {
    e.preventDefault();

    let newItems = [...optionItems];
    const currentItem = newItems[indexForDelete];
    // If item is new then id is null
    //
    if (currentItem.id) {
      newItems[indexForDelete].deleted = true;
    } else {
      newItems = newItems.filter((_item, index) => {
        return indexForDelete !== index;
      });
    }

    handleOptionsChange(newItems);
  };

  const updateOptionField = (index) => (
    textValue,
    elementsValue,
    htmlValue,
  ) => {
    const newItems = [...optionItems];
    newItems[index].richText.title = textValue;
    newItems[index].richText.titleElements = elementsValue;
    newItems[index].richText.titleHtml = htmlValue;

    handleOptionsChange(newItems);
  };

  const updateOptionFieldStyles = ({ cssStyle }, index) => {
    const newItems = [...optionItems];
    newItems[index] = {
      ...newItems[index],
      cssStyle: cssStyle ? cssStyle : {},
    };

    handleOptionsChange(newItems);
  };

  const handleChoiceOther = (e) => {
    const checked = e.target.checked;
    updateBlockAttributes({ multipleChoiceOther: checked });
    // Update Context
    setBlockState((prevState) => ({
      ...prevState,
      multipleChoiceOther: checked,
    }));
  };

  const handleChoiceMultiSelect = (e) => {
    const checked = e.target.checked;
    updateBlockAttributes({
      multipleChoiceMultiSelect: checked,
    });
    // Update Context
    setBlockState((prevState) => ({
      ...prevState,
      multipleChoiceMultiSelect: checked,
    }));
  };

  const notDeletedOptionItems = () => {
    return optionItems.filter((item) => {
      return !item.deleted;
    });
  };

  const renderNewItemButton = () => {
    if (MAX_OPTION_ITEMS_COUNT > optionItems.length) {
      return (
        <DonationInputWrapper>
          <NewItemButton
            noMargin
            onClick={addNewInput}
            data-testid="MultipleChoiceSection/NewItemButton"
          >
            + Add Option
          </NewItemButton>
          <RightButtonWrapper />
        </DonationInputWrapper>
      );
    }
  };

  // draggable

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      optionItems,
      result.source.index,
      result.destination.index,
    );

    handleOptionsChange(newItems);
  };

  return (
    <>
      <FormSection data-testid="BlockForm/MultipleChoiceSection">
        <BlockLabelInput
          multiIsChecked={multiIsChecked}
          blockType="multiple_choice"
          hidden={withoutCheckboxes}
        />
        <Label>Multiple Choice Options</Label>
        <Hint>{hint}</Hint>
        <Box sx={{ position: 'relative', mb: '1rem' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {optionItems.map((item, index) => (
                    <Draggable
                      key={item.draggableId}
                      draggableId={item.draggableId}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <>
                            <span
                              style={{
                                position: 'absolute',
                                left: 0,
                                height: '50px',
                                width: '30px',
                                zIndex: 1,
                              }}
                              {...provided.dragHandleProps}
                            />
                            <OptionInput
                              option={item}
                              index={index}
                              position={index + 1}
                              error={item.error}
                              withDeleteButton={
                                MIN_OPTION_ITEMS_COUNT <
                                notDeletedOptionItems().length
                              }
                              withoutInputNames={withoutInputNames}
                              multiIsChecked={multiIsChecked}
                              onChange={updateOptionField(index)}
                              onStylesChange={updateOptionFieldStyles}
                              addOptionItem={addOptionItem}
                              onDelete={(e) => removeInput(index, e)}
                              isDraggingOver={snapshot.isDraggingOver}
                            />
                          </>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {renderNewItemButton()}
        </Box>

        {!withoutCheckboxes && (
          <>
            <Checkbox
              checkedByDefault={otherIsChecked}
              value={otherIsChecked}
              name="ask[multiple_choice_other_checked]"
              label='Include "Other" option'
              withInput
              data-testid="MultipleChoiceSection/OtherCheckbox"
              onChange={handleChoiceOther}
            />
            <Checkbox
              disabled={withoutMultiSelect}
              checkedByDefault={multiIsChecked}
              value={multiIsChecked}
              name="ask[multiple_choice_multi_select]"
              label="Allow more than one selection"
              withInput
              data-testid="MultipleChoiceSection/MultiSelectCheckbox"
              onChange={handleChoiceMultiSelect}
            />
          </>
        )}
      </FormSection>
    </>
  );
}

MultipleChoiceSection.propTypes = {
  optionsList: PropTypes.array.isRequired,
  multiIsChecked: PropTypes.bool,
  otherIsChecked: PropTypes.bool,
  withoutMultiSelect: PropTypes.bool,
  updateBlockAttributes: PropTypes.func,
  hint: PropTypes.string,
  withoutCheckboxes: PropTypes.bool,
};

export default MultipleChoiceSection;
