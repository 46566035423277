import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '../ColorPicker';
import HighlightPicker from '../HighlightPicker';
import ParagraphButton from '../ParagraphButton';
import MarkButton from '../MarkButton';
import BlockButton from '../BlockButton';
import LinksButton from '../LinksButton';
import Select from '../Select';
import LetterSettings from '../LetterSettings';
import { Container, Divider } from './style';
import BlockContext, {
  FONT_WEIGHT_MENU_ITEMS,
} from '../../../../../../contexts/BlockContext';
import useHorizontalScroll from '../../../../../hooks/useHorizontalScroll';

const ButtonsContainer = ({ linkButtonRef, defaultFont }) => {
  const [
    { fontsList, fontsSelectItems, fontWeightsSelectItems, paletteColors },
    setBlockState,
  ] = React.useContext(BlockContext);
  const {
    scrollContainerRef,
    handleScroll,
    isAtStart,
    isAtEnd,
  } = useHorizontalScroll();

  const handleFontFamilyChange = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      fontWeightsSelectItems: FONT_WEIGHT_MENU_ITEMS.map((item) => ({
        ...item,
        disabled: !fontsList
          .find((f) => f.font_family === value)
          ?.font_weights?.includes(item.value),
      })),
    }));
  };

  return (
    <Container
      ref={scrollContainerRef}
      onScroll={handleScroll}
      leftGradient={!isAtStart}
      rightGradient={!isAtEnd}
      data-testid="TitleSection/ButtonsContainer"
    >
      <Select
        menuItems={fontsSelectItems}
        variant="fontFamily"
        defaultValue={defaultFont}
        ariaLabel="font select"
        onChange={handleFontFamilyChange}
      />
      <Select
        menuItems={fontWeightsSelectItems}
        variant="fontWeight"
        defaultValue={400}
        ariaLabel="font weight select"
      />
      <ColorPicker paletteColors={paletteColors} />
      <HighlightPicker paletteColors={paletteColors} />
      <ParagraphButton />
      <Divider />
      <MarkButton format="bold" />
      <MarkButton format="italic" />
      <MarkButton format="strikethrough" />
      <MarkButton format="underline" />
      <Divider />
      <BlockButton format="bulleted-list" />
      <BlockButton format="numbered-list" />
      <Divider />
      <BlockButton format="left" />
      <BlockButton format="center" />
      <BlockButton format="right" />
      <Divider />
      <LinksButton buttonRef={linkButtonRef} />
      <Divider />
      <LetterSettings />
    </Container>
  );
};

ButtonsContainer.propTypes = {
  linkButtonRef: PropTypes.object,
};

export default ButtonsContainer;
