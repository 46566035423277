import styled from 'styled-components';

export const Label = styled.label`
  color: ${props => props.color ? props.color : 'var(--font-color-primary--light)'};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin: ${props => props.noMargin ? 0 : '0 0 5px 0'};

  && {
    background-color: inherit;
    letter-spacing: 1px;
    padding: 0;
    cursor: initial;
  }
`;

export const SubLabel = styled(Label)`
  color: var(--font-color-secondary--light);
`;

export const RadioLabel = styled(Label)`
  text-transform: none;
  font-size: 1rem;
  cursor: pointer;
  letter-spacing: 0px;
`;

export const Hint = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.75rem;
`;

export const Fieldset = styled.fieldset`
  background-color: transparent;
  border: 0;
  margin: 1rem 0;
  padding: 0;
`;

export const FieldsetContainer = styled.div`
  background-color: transparent;
  border: 0;
  margin: 1rem 0;
  padding: 0;
`;

export const Legend = styled.legend`
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 5px 0;
  padding: 0.5rem 0 0;
  text-transform: uppercase;
  color: var(--font-color-primary--light);
`;

export const Textarea = styled.textarea`
  background-color: var(--white);
  border: 1px solid var(--border-color-primary);
  margin: 0;
  min-height: 82px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    background-color: var(--white);
  }
`;

export const FormSection = styled.div`
  position: relative;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : 'var(--surface-background)'};
  border: 1px solid var(--border-color-primary);
  padding: 1rem;
  border-radius: 8px;
  text-align: left;
`;

export const FormField = styled.div`
  margin: 0.5rem 0 1rem 0;
  width: 100%;
`;

export const FormSubsection = styled.div`
  margin-top: 12px;
  padding-left: 24px;
`;

export const FormSectionDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  margin: 0.5rem 0 1rem 0;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const FlexCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HLine = styled.hr`
  width: 100%;
  border-top-style: solid;
  border-top-width: ${(props) => (props.width ? props.width : '1px')};
  border-top-color: ${(props) => (props.color ? props.color : '$white')};
  border-bottom-width: 0;
`;

export const ErrorMessageFloat = styled.span`
  border-radius: 5px;
  text-align: center;
  color: var(--white);
  background-color: var(--error);
  font-size: 12px;
  padding: 5px 12px;
  position: relative;
  float: right;
  z-index: 5;
`;

export const ErrorMessage = styled.div`
  border-radius: 5px;
  text-align: center;
  background-color: var(--error);
  color: var(--white);
  font-size: 12px;
  padding: 3px 9px;
`;

export const SubmitButton = styled.input``;

export const Code = styled.code`
  background-color: var(--code-background);
  padding: 1px 2px 2px;
  color: var(--code-color);
`;
