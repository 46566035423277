import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MentionChip from '../MentionChip';
import Box from '@mui/material/Box';

function MentionTitle({ title = '' }) {
  const formattedTitle = useMemo(() => {
    return title.split(/({{.*?}})/g).map((text, index) => {
      if (text.match(/{{(#|\/).*}}/)) {
        return (
          <Box
            component="span"
            sx={{
              bgcolor: 'rgba(114,114,120,0.2)',
              borderRadius: '4px',
              p: '2px 4px',
              m: '4px 2px',
            }}
          >
            {text.slice(3, -2)}
          </Box>
        );
      } else if (text.match(/{{.*}}/)) {
        return (
          <MentionChip
            key={index}
            children={''}
            element={{ reference: text }}
          />
        );
      } else {
        return text;
      }
    });
  }, [title]);

  return <>{formattedTitle}</>;
}

MentionTitle.propTypes = {
  title: PropTypes.string,
};

export default MentionTitle;
