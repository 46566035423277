import React from 'react';
import PropTypes from 'prop-types';
import { Trash, Film } from 'react-feather';
import {
  Container,
  ImageWrapper,
  TextWrapper,
  IconWrapper,
  Image,
} from './style';

function Preview({ previewUrl, removeMedia, fileName, contentType }) {
  const isVideo = (type) => type && type.includes('video');

  return (
    <Container>
      <ImageWrapper>
        {isVideo(contentType) ? (
          <Film data-testid="Preview/VideoIcon" />
        ) : (
          <Image src={previewUrl} />
        )}
      </ImageWrapper>
      <TextWrapper>{fileName}</TextWrapper>
      <IconWrapper onClick={removeMedia} data-testid="Preview/DeleteButton">
        <Trash />
      </IconWrapper>
    </Container>
  );
};

Preview.propTypes = {
  previewUrl: PropTypes.string,
  fileName: PropTypes.string,
  contentType: PropTypes.string,
  removeMedia: PropTypes.func.isRequired,
};

export default Preview;
