import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  position: absolute;
  right: 5px;
  transform: translateY(-50%);
`;

export const CheckboxContainer = styled.div`
  display: block;
  background-color: var(--surface-background);
  border: 1px solid var(--border-color-primary);
  border-radius: 8px;
  padding: 4px 12px;
  margin-bottom: 4px;
  margin-top: 4px;
`;
