import React, { useContext } from 'react';
import useBuildMentionsSteps from '../../../../../hooks/useBuildMentionsSteps';
import { ExperienceIdContext } from '../../../../index';
import { Label, FormSection } from '../../../../../globals';
import { EditableMentionsInput } from '../../../../../SlateElements';

function SubmissionAmountForm({ errors, goalState, updateGoalState }) {
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'GoalSection.SubmissionAmountForm',
  });
  const experienceId = useContext(ExperienceIdContext);

  const handleValueChange = (value, attribute) => {
    if (value[value.length - 1] !== '[') {
      updateGoalState(attribute, value);
    }
  };

  return (
    <FormSection>
      <Label>Goal</Label>
      <EditableMentionsInput
        initialValue={goalState.submissionGoal.toString()}
        placeholder="ex: 1000"
        experienceId={experienceId}
        steps={mentionSteps}
        name="ask[ask_metric_attributes][submission_goal]"
        error={errors['ask_metric.submission_goal']}
        onValueChange={(value) => handleValueChange(value, 'submissionGoal')}
        inputStyles={{ marginBottom: '1rem' }}
        withHiddenInput
      />
      <Label>Goal description</Label>
      <EditableMentionsInput
        initialValue={goalState.submissionGoalName || ''}
        placeholder="ex: submissions"
        experienceId={experienceId}
        dataTestId="SubmissionAmountForm/Input"
        steps={mentionSteps}
        name="ask[ask_metric_attributes][submission_goal_name]"
        error={errors['ask_metric.submission_goal_name']}
        onValueChange={(value) => handleValueChange(value, 'submissionGoalName')}
        withHiddenInput
        autoFocus
      />
    </FormSection>
  );
}

export default SubmissionAmountForm;
