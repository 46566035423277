export const keyboardNavigation = (
  event,
  listIsOpen,
  currentItemIndex,
  setCurrentItemIndex,
  currentItemRef,
  listRef,
  onInputKeyDown,
) => {
  if (listIsOpen) {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        setCurrentItemIndex(0);
        if (currentItemRef.current && listRef.current) {
          currentItemRef.current.click();
          listRef.current.children[1].scrollIntoView(false);
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (listRef.current) {
          if (currentItemIndex === 0) {
            setCurrentItemIndex(listRef.current.children.length - 2);
            listRef.current.children[
              listRef.current.children.length - 1
            ].scrollIntoView(false);
          } else {
            setCurrentItemIndex(currentItemIndex - 1);
            currentItemRef.current.scrollIntoView(false);
          }
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (listRef.current) {
          if (currentItemIndex === listRef.current.children.length - 2) {
            setCurrentItemIndex(0);
            listRef.current.children[1].scrollIntoView(false);
          } else {
            setCurrentItemIndex(currentItemIndex + 1);
            listRef.current.children[currentItemIndex + 2].scrollIntoView(
              false,
            );
          }
        }
        break;
      default:
        return;
    }
  } else if (onInputKeyDown) {
    onInputKeyDown(event);
  }
};
