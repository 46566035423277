import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import {
  OptionsWrapper,
  OptionLabel,
  Option,
  OptionIcon,
  LabelText,
  HiddenRadioButton,
} from './style';

function TilesSelector({ options, defaultValue, onChange, tileBackground }) {
  const [value, setValue] = useState(defaultValue);

  const isActive = (nextValue) => value === nextValue;

  const handleChange = (nextValue) => () => {
    setValue(nextValue);

    if (onChange) {
      onChange(nextValue);
    }
  };

  const prepIdentifier = (name, index) => `${name.replace(/\s/g, '')}_${index}`;

  const renderOption = (
    { value, name, icon, IconComponent, testId, label, labelBackground },
    index,
  ) => (
    <Option key={value}>
      <OptionLabel
        active={isActive(value)}
        htmlFor={prepIdentifier(name, index)}
        data-testid={testId}
        background={labelBackground}
      >
        <OptionIcon background={tileBackground}>
          <HiddenRadioButton
            type="radio"
            checked={isActive(value)}
            onChange={handleChange(value)}
            name={name}
            value={value}
            id={prepIdentifier(name, index)}
          />
          {IconComponent ? <IconComponent /> : <Icon src={icon} />}
        </OptionIcon>
      </OptionLabel>
      <LabelText>{label}</LabelText>
    </Option>
  );

  return <OptionsWrapper>{options.map(renderOption)}</OptionsWrapper>;
}

TilesSelector.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  tileBackground: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
      IconComponent: PropTypes.func,
      label: PropTypes.string.isRequired,
    }),
  ),
};

export default TilesSelector;
