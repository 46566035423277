import React from 'react';
import PropTypes from 'prop-types';
import { Container, InnerWrapper, EmptyResultsText } from './style';
import { usePreparedList } from './usePreparedList';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import _isEmpty from 'lodash/isEmpty';
import { Search } from 'react-feather';
import MentionTitle from '../../MentionTitle';

const listItemStyles = {
  color: '#333',
  borderTop: '1px solid #e0e0e0',
  '&.MuiListItem-root.Mui-selected': {
    backgroundColor: '#eee',
  },
  '&:hover': {
    backgroundColor: '#eee',
  },
  '& .MuiListItemText-primary': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

export const OptionsList = (props) => {
  const {
    steps,
    handleLastSelect,
    handleExperienceMetadataSelect,
    currentItemIndex,
    listRef,
    currentItemRef,
    setCurrentItemIndex,
    experienceId,
    listStyle,
    inputWithGutter,
    inputWidth,
    anchorEl,
  } = props;
  const { preparedList, onSelect, header, isLoaded } = usePreparedList(
    handleLastSelect,
    handleExperienceMetadataSelect,
    steps,
    experienceId,
  );

  const onHover = (index) => {
    setCurrentItemIndex(index);
  };

  return (
    <Container
      data-testid="MentionsInput/OptionsList"
      inputWithGutter={inputWithGutter}
      anchorEl={anchorEl}
      style={listStyle}
    >
      <List
        sx={{
          width: '100%',
          maxWidth: inputWidth - 19,
          backgroundColor: '#fff',
          position: 'relative',
          overflow: 'auto',
          margin: '0 auto',
          paddingTop: 0,
          maxHeight: 331,
          border: '1px solid #E0E0E0',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

          '@media (max-width: 599px)': {
            maxWidth: '100%',
          },
        }}
        ref={listRef}
      >
        <ListSubheader
          sx={{
            minHeight: 17,
            color: '#bdbdbd',
            fontSize: 11,
            fontWeight: 500,
            letterSpacing: 0.8,
            lineHeight: 1.2,
            textTransform: 'uppercase',
            marginBottom: 0,
            paddingTop: '7px',
            paddingBottom: '7px',
          }}
        >
          {header}
        </ListSubheader>
        {isLoaded ? (
          <>
            {_isEmpty(preparedList) ? (
              <InnerWrapper>
                <Search />
                <EmptyResultsText>
                  Couldn't find any matching results.
                </EmptyResultsText>
              </InnerWrapper>
            ) : (
              <>
                {preparedList.map((item, index) => {
                  const itemIsSelected = currentItemIndex === index;
                  return (
                    <ListItem
                      sx={listItemStyles}
                      ref={itemIsSelected ? currentItemRef : null}
                      selected={itemIsSelected}
                      button
                      key={item.value}
                      onClick={() => onSelect(item)}
                      onMouseEnter={() => onHover(index)}
                      data-testid={item.dataTestId || 'OptionsList/ListItem'}
                      disableRipple
                    >
                      <ListItemText secondary={item.subTitle}>
                        <MentionTitle title={item.title} />
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </>
            )}
          </>
        ) : (
          <ListItem sx={{ ...listItemStyles, justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#e0e0e0' }} />
          </ListItem>
        )}
      </List>
    </Container>
  );
};

OptionsList.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      referencePrefix: PropTypes.string,
      loadList: PropTypes.func,
    }),
  ).isRequired,
  experienceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleLastSelect: PropTypes.func.isRequired,
  setCurrentItemIndex: PropTypes.func.isRequired,
  inputWidth: PropTypes.number.isRequired,
  inputWithGutter: PropTypes.bool,
  currentItemIndex: PropTypes.number.isRequired,
  listRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  currentItemRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  listStyle: PropTypes.object,
};
