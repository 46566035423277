import React from 'react';
import Checkbox from '../../../../../checkbox';
import Box from '@mui/material/Box';
import { useHover } from 'use-events';
import CustomizeToolbar from '../../../../../CustomizeToolbar';
import BlockContext from '../../../../../../contexts/BlockContext';
import ButtonHiddenInputs from '../ButtonHiddenInputs';

const setNavigationButtons = (setBlockState, direction, attribute, value) => {
  setBlockState((prev) => ({
    ...prev,
    navigationButtons: prev.navigationButtons.map((b) => {
      if (b.direction === direction) {
        return {
          ...b,
          [attribute]: value,
        };
      } else {
        return b;
      }
    }),
  }));
};

export default function CheckboxWithToolbar({ label, direction, index }) {
  const [isHover, bind] = useHover();
  const [blockState, setBlockState] = React.useContext(BlockContext);

  const button = blockState.navigationButtons.find(
    (b) => b.direction === direction,
  );
  const handleCheckboxChange = () => {
    setNavigationButtons(
      setBlockState,
      direction,
      'enabled',
      event.target.checked,
    );
  };

  const handleStyleChange = (value) => {
    setNavigationButtons(setBlockState, direction, 'cssStyle', value.cssStyle);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        bgcolor: 'var(--surface-background)',
        p: '5px 15px',
        border: 1,
        borderColor: 'var(--border-color-primary)',
        borderRadius: '0.5rem',
        mb: '1rem',
      }}
      {...bind}
    >
      <Checkbox
        label={label}
        checkedByDefault={button && button.enabled}
        onChange={handleCheckboxChange}
        value={button && button.enabled}
      />
      <ButtonHiddenInputs index={index} button={button} />
      <CustomizeToolbar
        isHover={isHover}
        handleSave={handleStyleChange}
        cssStyle={button && button.cssStyle}
        type={`${direction}Button`}
        sx={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
        }}
      />
    </Box>
  );
}
