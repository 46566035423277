import React from 'react';
import MUISwitch, { switchClasses } from '@mui/material/Switch';

import { SwitchWithTitleWrapper, SwitchTitle, SwitchWrapper, SwitchSubtitle } from './style';

function Switch(props) {
  return (
    <MUISwitch
      disableRipple
      sx={{
        width: 48,
        height: 28,
        padding: 0,
        color: 'var(--white)',
        [`& .${switchClasses.switchBase}`]: {
          position: 'absolute',
          padding: '1px',
          color: 'var(--white)',
          borderRadius: '50%',
          [`&.${switchClasses.disabled}`]: {
            [`& + .${switchClasses.track}`]: {
              backgroundColor: 'var(--disabled-input-background)',
              opacity: 0.7,
              border: '1px solid var(--border-color-primary)',
              cursor: 'not-allowed',
            },
          },
          [`&.${switchClasses.checked}`]: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            transform: 'translateX(20px)',
            color: 'var(--white)',
            [`& + .${switchClasses.track}`]: {
              backgroundColor: 'var(--primary-color)',
              opacity: 1,
              border: 'none',
            },
            [`&.${switchClasses.disabled}`]: {
              [`& + .${switchClasses.track}`]: {
                opacity: 0.7,
              },
              color: 'var(--white)',
            },
          },
        },
        [`& .${switchClasses.thumb}`]: {
          width: 26,
          height: 26,
          boxShadow:
            '0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06)',
          border: '0.5px solid var(--border-color-primary)',
        },
        [`& .${switchClasses.checked}`]: {},
        [`& .${switchClasses.track}`]: {
          border: '1px solid var(--border-color-primary)',
          borderRadius: '14px',
          backgroundColor: 'inherit',
          opacity: 1,
        },
        [`& .${switchClasses.disabled}`]: {
          opacity: 0.5,
        },
      }}
      {...props}
    />
  );
}

export const SwitchWithTitle = (props) => {
  return (
    <>
      <SwitchWithTitleWrapper noborder={props.noborder}>
        <SwitchTitle>{props.title}</SwitchTitle>
        <SwitchWrapper>
          <Switch
            checked={props.checked}
            value={props.checked}
            onChange={props.onChange}
            {...props}
          />
        </SwitchWrapper>
      </SwitchWithTitleWrapper>
      {props.subtitle && <SwitchSubtitle>{props.subtitle}</SwitchSubtitle>}
      {props.checked && props.children}
    </>
  );
};

export default Switch;
