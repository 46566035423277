import styled from 'styled-components';
import { PrimaryButton } from '../../../Button';
import { APP_SETTINGS_PRIMARY_COLOR } from '../../../../constants';

export const StyledButton = styled(PrimaryButton).attrs((props) => ({
  style: {
    left: props.leftMargin ? props.leftMargin + 'px' : 0,
    width: props.width ? props.width + 'px' : '100%',
  },
}))`
  position: fixed;
  background-color: ${APP_SETTINGS_PRIMARY_COLOR};
  border-radius: 0;
  margin: 0;
  z-index: 6;
  box-shadow: 0 -15px 10px 0 rgba(0, 0, 0, 0.15);
`;
