import _snakeCase from 'lodash/snakeCase';
import { nanoid } from 'nanoid';

const camelKeysToSnake = (object) => {
  return Object.entries(object).reduce((accObj, entries) => {
    return {
      ...accObj,
      [_snakeCase(entries[0])]: entries[1],
      // Should have unique key in case of id absence
      nanoid: nanoid(),
    };
  }, {});
};

export const buildInitialConditions = (arr) => (
  arr.data.map(({ attributes }) => camelKeysToSnake(attributes))
);
