import React from 'react';
import PropTypes from 'prop-types';
import SimpleSelect from '../../../../select';
import Tooltip from '../../../../tooltip';
import { Label, FlexRow } from '../../../../globals';
import { Info } from 'react-feather';
import { IconWrapper } from './style';

const defaultDonationOptions = [
  { value: 'recurring', label: 'Monthly Recurring' },
  { value: 'one_time', label: 'One-Time' },
];

const defaultDonationTooltipTitle =
  'If more than one frequency option is shown, choose which option is selected by default.';

function DefaultFrequencySection({
  updateDefaultDonationType,
  defaultFrequency,
  disabled,
}) {
  const defaultDonationType = defaultDonationOptions.find(
    (option) => option.value === defaultFrequency,
  );

  return (
    <>
      <FlexRow margin="0.5rem 0">
        <Label>Default Frequency</Label>
        <Tooltip
          data-testid="PaymentDefaultFrequency/Tooltip"
          disableFocusListener
          disableTouchListener
          title={defaultDonationTooltipTitle}
          placement="top"
          width={320}
        >
          <IconWrapper>
            <Info size={15} />
          </IconWrapper>
        </Tooltip>
      </FlexRow>
      <SimpleSelect
        options={defaultDonationOptions}
        isSearchable={false}
        value={defaultDonationType}
        onChange={updateDefaultDonationType}
        isDisabled={disabled}
        name="ask[default_donation_type]"
        classNamePrefix="react-select__default-donation"
      />
      {disabled && (
        <input
          type="hidden"
          name="ask[default_donation_type]"
          value={defaultFrequency}
        />
      )}
    </>
  );
}

DefaultFrequencySection.propTypes = {
  defaultFrequency: PropTypes.string,
  updateDefaultDonationType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DefaultFrequencySection;
