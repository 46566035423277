import styled from 'styled-components';

export const SwitchWrapper = styled.span`
  margin-left: auto;
`;

export const SwitchTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

export const SwitchSubtitle = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  color: $font-color-primary;
  margin-bottom: 1rem;
`;

export const SwitchWithTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-top: 1rem;
  border-top: ${(props) =>
    props.noborder ? 'unset' : '1px solid #333'};
  margin-bottom: 1rem;
`;
