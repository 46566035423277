import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Label } from '../../../globals';
import SimpleSelect from '../../../select';
import BlockContext from '../../../../contexts/BlockContext';

function FontSection({ currentFont }) {
  const [blockState, setBlockState] = useContext(BlockContext);

  const selectedValue = () => {
    return blockState.fontsSelectItems.find((font) => font.value === currentFont);
  };

  const updateFont = ({ value }) => {
    setBlockState((prevState) => ({
      ...prevState,
      fontFamily: value,
    }));
  };

  return (
    <div data-testid="BlockForm/FontSection">
      <Label>Block Font</Label>
      <SimpleSelect
        options={blockState.fontsSelectItems}
        defaultValue={selectedValue()}
        onChange={updateFont}
        isSearchable={false}
        name="ask[font_family]"
        classNamePrefix="react-select__font"
      />
    </div>
  );
}

FontSection.propTypes = {
  currentFont: PropTypes.string.isRequired,
};

export default FontSection;
