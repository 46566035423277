import styled from 'styled-components';

export const StyledDisabledSelect = styled.span`
  width: 60px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  float: right;
  background-color: var(--disabled-input-background);
  color: var(--disabled-input-color);
  border: 1px solid var(--border-color-primary);
  padding: 2px 10px;
  margin: 0 40px 6px 0;
  text-transform: uppercase;
`;

export const StyledSelect = styled.select`
  width: 80px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  float: right;
  border: 1px solid var(--border-color-primary);
  color: var(--font-color-secondary);
  padding: 5px 10px;

  margin: 0 40px 6px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--new-item-button-background);
  cursor: pointer;
`;
