import React from 'react';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TonalityIcon from '@mui/icons-material/Tonality';
import BlockIcon from '@mui/icons-material/Block';
import IconBox from '../IconBox';
import { useMuiPopover } from '../../../hooks';

const shadows = [
  '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.12)',
  '0px 3px 12px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.2), 2px 1px 14px rgba(0, 0, 0, 0.12)',
  '0px 0px 1px rgba(0, 0, 0, 0.3), 2px 3px 0px rgba(0, 0, 0, 0.5)',
];

const roundElementStyle = {
  display: 'inline-block',
  width: '20px',
  height: '20px',
  margin: '0',
  borderRadius: '100%',
  cursor: 'pointer',
};

function RoundElement({ shadow, style, onClick }) {
  return (
    <span
      onClick={onClick}
      style={{
        ...roundElementStyle,
        boxShadow: shadow,
        ...style,
      }}
    />
  );
}

function BoxShadow({ initialShadow, handleSetBoxShadow }) {
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'CustomToolbar/BoxShadow',
  );

  const handleItemClick = (shadow) => () => {
    handleSetBoxShadow(shadow);
    handleClose();
  };

  return (
    <>
      <IconBox isOpen={open} tooltipTitle="Box shadow">
        <TonalityIcon onClick={handleOpen} />
      </IconBox>
      <Popover
        id={id}
        data-testid={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0',
            borderRadius: '12px',
          },
        }}
      >
        {shadows.map((shadow, index) => (
          <Typography
            key={shadow}
            sx={{
              padding: '8px 16px',
              paddingTop: `${index === 0 ? '16px' : '8px'}`,
              backgroundColor: `${
                shadow === initialShadow
                  ? 'rgba(29, 29, 29, 0.08)'
                  : 'transparent'
              }`,
            }}
            key={shadow}
          >
            <RoundElement shadow={shadow} onClick={handleItemClick(shadow)} />
          </Typography>
        ))}
        <Divider sx={{ marginTop: '5px' }} flexItem />
        <Typography
          sx={{
            padding: '8px 16px',
            backgroundColor: `${
              initialShadow === 'none'
                ? 'rgba(29, 29, 29, 0.08)'
                : 'transparent'
            }`,
          }}
        >
          <BlockIcon
            sx={{
              ...roundElementStyle,
              color: '#333',
              opacity: '0.54',
            }}
            onClick={handleItemClick('none')}
          />
        </Typography>
      </Popover>
    </>
  );
}

export default BoxShadow;
