import React from 'react';
import PropTypes from 'prop-types';
import { Label, HLine } from '../../globals';
import { Input } from '../../inputs/Input';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { StyledButton } from '../../modal/style';
import { SwitchWithTitle } from '../../switch';
import useBuildMentionsSteps from '../../hooks/useBuildMentionsSteps';
import { EditableMentionsInput } from '../../SlateElements';
import { slateToHtml } from '../../BlockForm/components/TitleSection/utils';
import { useHover } from 'use-events';
import CustomizeToolbar from '../../CustomizeToolbar';
import Checkbox from '../../checkbox';

function PaymentOptionFeeModal({
  kind,
  title,
  index,
  errors,
  isOpen,
  hideModal,
  setValues,
  defaultValues,
  experienceId,
}) {
  if (!isOpen) {
    return null;
  }

  const [percentageValue, setPercentageValue] = React.useState(
    defaultValues.percentageValue,
  );
  const [minValue, setMinValue] = React.useState(defaultValues.minValue);
  const [maxValue, setMaxValue] = React.useState(defaultValues.maxValue);
  const [supportsCreditCardFees, setSupportsCreditCardFees] = React.useState(
    defaultValues.supportsCreditCardFees,
  );
  const [supportsDebitCardFees, setSupportsDebitCardFees] = React.useState(
    defaultValues.supportsDebitCardFees,
  );
  const [supportsPrepaidCardFees, setSupportsPrepaidCardFees] = React.useState(
    defaultValues.supportsPrepaidCardFees,
  );
  const [supportsUnknownCardFees, setSupportsUnknownCardFees] = React.useState(
    defaultValues.supportsUnknownCardFees,
  );
  const [feesEnabled, setFeesEnabled] = React.useState(
    defaultValues.feesEnabled,
  );
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'PaymentSection',
  });
  const [disclaimerText, setDisclaimerText] = React.useState(
    defaultValues.disclaimerText,
  );
  const [isHover, bind] = useHover();

  const handleSave = () => {
    setValues({
      percentageValue,
      minValue,
      maxValue,
      feesEnabled,
      disclaimerText,
      supportsCreditCardFees,
      supportsDebitCardFees,
      supportsPrepaidCardFees,
      supportsUnknownCardFees,
    });
    hideModal();
  };

  const handleDisclaimerTextChange = (value, elements) => {
    setDisclaimerText((prev) => ({
      ...prev,
      richText: {
        title: value,
        titleElements: JSON.stringify(elements),
        titleHtml: slateToHtml(elements),
      },
    }));
  };

  const handleDisclaimerStyleChange = (value) => {
    setDisclaimerText((prev) => ({ ...prev, cssStyle: value.cssStyle }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={hideModal}
      aria-label="payment option settings modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 500,
          bgcolor: 'background.paper',
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 500,
            mt: 2,
          }}
        >
          {title}
        </Box>
        <HLine color="var(--border-color-primary)" />
        <Box sx={{ px: 2, pb: 2 }}>
          <SwitchWithTitle
            checked={feesEnabled}
            onChange={(e) => setFeesEnabled(e.target.checked)}
            title="Charge Fee"
            data-testid="PaymentOptionFeeModal/FeesSwitcher"
            noborder="true"
          />
          <Box sx={{ display: feesEnabled ? 'block' : 'none' }}>
            <Stack
              sx={{ mt: 1.5 }}
              spacing={1}
              direction="row"
              aria-label={`payment method ${kind}`}
            >
              <Box>
                <Label>Fee %</Label>
                <Input
                  min={0}
                  name="fee_percentage"
                  value={percentageValue}
                  onChange={(e) => setPercentageValue(e.target.value)}
                  type="number"
                  error={errors[`payment_methods[${index}].fee_percentage`]}
                  autoFocus
                />
              </Box>
              <Box>
                <Label>Min fee</Label>
                <Input
                  min={0}
                  name="fee_min"
                  value={minValue}
                  onChange={(e) => setMinValue(e.target.value)}
                  type="number"
                  error={errors[`payment_methods[${index}].fee_min`]}
                />
              </Box>
              <Box>
                <Label>Max fee</Label>
                <Input
                  min={0}
                  name="fee_max"
                  value={maxValue}
                  onChange={(e) => setMaxValue(e.target.value)}
                  type="number"
                  error={errors[`payment_methods[${index}].fee_max`]}
                />
              </Box>
            </Stack>
            {kind === 'card' && (
              <Box sx={{ pb: 2 }}>
                <Label>Apply fees to a card type</Label>
                <Checkbox
                  name="supports_credit_card_fees"
                  checkedByDefault={supportsCreditCardFees}
                  value={supportsCreditCardFees}
                  onChange={(e) => setSupportsCreditCardFees(e.target.checked)}
                  label="Credit"
                />
                <Checkbox
                  name="supports_debit_card_fees"
                  checkedByDefault={supportsDebitCardFees}
                  value={supportsDebitCardFees}
                  onChange={(e) => setSupportsDebitCardFees(e.target.checked)}
                  label="Debit"
                />
                <Checkbox
                  name="supports_prepaid_card_fees"
                  checkedByDefault={supportsPrepaidCardFees}
                  value={supportsPrepaidCardFees}
                  onChange={(e) => setSupportsPrepaidCardFees(e.target.checked)}
                  label="Prepaid"
                />
                <Checkbox
                  name="supports_unknown_card_fees"
                  checkedByDefault={supportsUnknownCardFees}
                  value={supportsUnknownCardFees}
                  onChange={(e) => setSupportsUnknownCardFees(e.target.checked)}
                  label="Unknown"
                />
              </Box>
            )}
            <Box sx={{ pb: 2 }}>
              <Label>Disclaimer text</Label>
              <Box sx={{ position: 'relative' }} {...bind}>
                <EditableMentionsInput
                  experienceId={experienceId}
                  steps={mentionSteps}
                  name="fee_disclaimer"
                  key={`payment_fee_disclaimer_${index}`}
                  initialValue={JSON.parse(
                    disclaimerText.richText.titleElements,
                  )}
                  dataTestId="PaymentOptionFeeModal/DisclaimerText"
                  onValueChange={handleDisclaimerTextChange}
                  multirows
                  customizable
                />
                <CustomizeToolbar
                  isHover={isHover}
                  handleSave={handleDisclaimerStyleChange}
                  cssStyle={disclaimerText.cssStyle}
                  type="feeDisclaimer"
                  sx={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            borderRadius: '0 0 8px 8px',
            overflow: 'hidden',
          }}
        >
          <StyledButton onClick={hideModal}>Cancel</StyledButton>
          <StyledButton primary onClick={handleSave}>
            Save
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
}

PaymentOptionFeeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default PaymentOptionFeeModal;
