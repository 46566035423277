import React from 'react';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconBox from '../IconBox';
import { useMuiPopover } from '../../../hooks';
import InputWithAdornments from './components/InputWithAdornments';
import { Label } from '../../../globals';
import _isEmpty from 'lodash/isEmpty';

const getNumber = (value) => value.replace(/[^0-9.-]+/g, '');
const getUnit = (value) => {
  const unit = value.replace(/[0-9.-]+/g, '');
  return unit ? unit : 'px';
};

export default function Spacing({ type, Icon, values, onChange }) {
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    `CustomToolbar/${type}`,
  );

  const handleChange = (value, orientation, unit) => {
    const valueWithUnit = _isEmpty(value) ? '' : `${value}${unit}`;
    const values = orientation === 'vertical' ? {
      [`${type}Top`]: valueWithUnit,
      [`${type}Bottom`]: valueWithUnit,
    } : {
      [`${type}Right`]: valueWithUnit,
      [`${type}Left`]: valueWithUnit,
    };
    onChange(values);
  };

  const inputs = [
    {
      orientation: 'vertical',
      inputValue: getNumber(values.vertical),
      unitValue: getUnit(values.vertical),
    },
    {
      orientation: 'horizontal',
      inputValue: getNumber(values.horizontal),
      unitValue: getUnit(values.horizontal),
    },
  ];

  return (
    <>
      <IconBox isOpen={open} tooltipTitle={type}>
        <Icon onClick={handleOpen} />
      </IconBox>
      <Popover
        id={id}
        data-testid={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            p: 2,
            borderRadius: '12px',
            width: 180,
          },
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Label noMargin>{type}</Label>
        </Box>
        <Stack spacing={1}>
          {inputs.map(({ inputValue, unitValue, orientation }) => (
            <InputWithAdornments
              key={orientation}
              inputValue={inputValue}
              unitValue={unitValue}
              orientation={orientation}
              onChange={handleChange}
            />
          ))}
        </Stack>
      </Popover>
    </>
  );
}
