import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';

export const DropdownMenu = ({
  menuItems,
  triggerButton,
  onSelection,
  disabled,
  dataTestId,
  placement,
  disablePortal,
  styles,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onMenuItemSelect = (event, value) => {
    onSelection(value);
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div data-testid={dataTestId}>
      <IconButton
        sx={{ '&:hover': { color: 'inherit', backgroundColor: 'transparent' } }}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        data-testid="DropdownMenu/TriggerButton"
        disableRipple
        onClick={handleToggle}
        disabled={disabled}
      >
        {triggerButton}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={disablePortal}
        placement={placement || 'bottom-end'}
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={styles?.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={styles?.menuList}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.map(({ title, value, icon }) => (
                    <MenuItem
                      sx={styles?.menuItem}
                      key={value}
                      onClick={(event) => onMenuItemSelect(event, value)}
                      value={value}
                    >
                      {icon && <ListItemIcon>{icon}</ListItemIcon>}
                      {title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

DropdownMenu.propTypes = {
  triggerButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
  onSelection: PropTypes.func,
  disabled: PropTypes.bool,
  placement: PropTypes.string,
  disablePortal: PropTypes.bool,
  styles: PropTypes.object,
};
