import styled from 'styled-components';

export const Container = styled.div`
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(3, minmax(80px, 124px));
  grid-template-rows: auto;
  grid-template-areas:
    '. top .'
    'left center right'
    '. bottom .';
`;

export const LabelWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const GridItem = styled.div`
  display: flex;
  grid-area: ${(props) => props.gridArea};
  justify-self: ${(props) => {
  switch (props.gridArea) {
    case 'right':
      return 'start';
    case 'top':
    case 'bottom':
      return 'center';
    case 'left':
      return 'end';
  }
}};
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 80px;
  margin: 0.5em 12px 0;
  align-self: center;

  & input {
    text-align: center;
  }

  & span {
    min-width: 110px;
  }
`;

export const IconWrapper = styled.div`
  margin-left: 8px;
`;

export const TBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Times New Roman';
  font-size: 40px;
  font-style: italic;
  width: 124px;
  height: 124px;
  margin: auto;
  border: 2px dashed var(--border-color-primary);
  background-color: var(--background-primary);
  user-select: none;
`;
