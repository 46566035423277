export const SET_BACKGROUND_COLOR = 'setBackgroundColor';
export const SET_COLOR = 'setColor';
export const SET_BORDER_COLOR = 'setBorderColor';
export const SET_BORDER_SIDES = 'setBorderSides';
export const SET_BORDER_WIDTH = 'setBorderWidth';
export const SET_BORDER_STYLE = 'setBorderStyle';
export const SET_BOX_SHADOW = 'setBoxShadow';
export const SET_BORDER_RADIUS = 'setBorderRadius';
export const SET_SPACING = 'setSpacing';
export const SET_STYLES = 'setStyles';
export const SET_TYPE = 'setType';

const dataFieldInitialState = {
  backgroundColor: 'rgba(255,255,255,1)',
  borderColor: 'rgba(255,255,255,1)',
  borderWidth: '0px',
  borderStyle: 'solid',
  boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 4px',
  borderRadius: '8px',
};

const paymentOptionInitialState = {
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  borderColor: 'rgba(255, 255, 255, 0.4)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '8px',
  boxShadow: 'none',
};

const multipleChoiceInitialState = {
  backgroundColor: 'rgba(255,255,255,0.8)',
  borderColor: 'rgba(255,255,255,0)',
  borderWidth: '0px',
  borderStyle: 'solid',
  boxShadow: 'none',
  borderRadius: '2rem',
};

export const initialState = {
  dataField: dataFieldInitialState,
  paymentOption: paymentOptionInitialState,
  multipleChoice: multipleChoiceInitialState,
  multipleChoice_withMultiSelection: {
    ...multipleChoiceInitialState,
    borderRadius: '8px',
  },
  submissionButton: {},
  blockPrompt: {},
  feeDisclaimer: {},
  askLabel: {},
  backButton: {
    borderRadius: '50%',
  },
  forwardButton: {
    borderRadius: '50%',
  },
};

function reducer(state, action) {
  switch (action.type) {
    case SET_STYLES: {
      return {
        ...state,
        ...action.payload.styles,
      };
    }
    case SET_BACKGROUND_COLOR: {
      return {
        ...state,
        backgroundColor: action.payload.color,
      };
    }
    case SET_COLOR: {
      return {
        ...state,
        color: action.payload.color,
      };
    }
    case SET_BORDER_COLOR: {
      return {
        ...state,
        borderColor: action.payload.color,
      };
    }
    case SET_BORDER_SIDES: {
      return {
        ...state,
        ...action.payload.styles,
      };
    }
    case SET_BORDER_WIDTH: {
      return {
        ...state,
        borderWidth: action.payload.width,
      };
    }
    case SET_BORDER_STYLE: {
      return {
        ...state,
        borderStyle: action.payload.style,
      };
    }
    case SET_BORDER_RADIUS: {
      return {
        ...state,
        borderRadius: action.payload.radius,
      };
    }
    case SET_BOX_SHADOW: {
      return {
        ...state,
        boxShadow: action.payload.shadow,
      };
    }
    case SET_SPACING: {
      return {
        ...state,
        ...action.payload.styles,
      };
    }
    default:
      return {};
  }
}

export default reducer;
