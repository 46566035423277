import React, { useState, useEffect, useCallback, useContext } from 'react';

import Popover from '@mui/material/Popover';
import { useSlate } from 'slate-react';
import {
  getExperiencesList,
  getBlocksList,
  getMergeFields,
} from '../../../../../api';

import { ExperienceIdContext } from '../../../../index';
import { EditableMentionsInput } from '../../../../../SlateElements';
import LinkIcon from '@mui/icons-material/Link';
import Button from '../ChangeButton';
import {
  Label,
  Container,
  ButtonGroup,
  PrimaryActionButton,
  ActionButton,
  InputWrapper,
} from './style';
import { isUrl, insertLink, activeLink, unwrapLink } from './utils';

const mentionSteps = [
  {
    type: 'experiences',
    title: 'Experiences',
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: getBlocksList,
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: getMergeFields,
  },
];

function LinksButton({ buttonRef, onPopover }) {
  const editor = useSlate();
  const [selection, setSelection] = useState(null);
  const [anchoreEl, setAnchoreEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);
  const experienceId = useContext(ExperienceIdContext);

  const handleOpen = useCallback(
    (e) => {
      if (onPopover) {
        onPopover(true);
      }
      const link = activeLink(editor);

      if (link) {
        setInputValue(link[0].url);
      }

      setAnchoreEl(e.currentTarget);
      setSelection(editor.selection);
    },
    [editor],
  );
  const handleClose = () => {
    if (onPopover) {
      onPopover(false);
    }
    setAnchoreEl(null);
    setInputValue('');
    setSelection(null);
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleUnlink = useCallback(
    (e) => {
      e.preventDefault();
      editor.selection = selection;
      if (activeLink(editor)) {
        unwrapLink(editor);
      }
      handleClose();
    },
    [selection],
  );
  const handleLink = useCallback(
    (e) => {
      e.preventDefault();
      editor.selection = selection;

      if (!error) {
        insertLink(editor, inputValue);
        handleClose();
      }
    },
    [inputValue, error, selection],
  );

  useEffect(() => {
    if (inputValue !== '' && !isUrl(inputValue)) {
      setError('is not valid');
    } else {
      setError(null);
    }
  }, [inputValue]);

  const open = Boolean(anchoreEl);
  const id = open ? 'simple-popover' : undefined;
  const isActive = activeLink(editor);
  const iconColor = isActive ? '#FFF' : '#333';

  return (
    <>
      <Button
        aria-describedby={id}
        isActive={isActive}
        handleClick={handleOpen}
        popover="Hyperlink"
        buttonProps={{
          ref: buttonRef,
          'data-testid': 'TitleSection/LinkButton',
        }}
      >
        <LinkIcon sx={{ color: iconColor, fontSize: 22 }} />
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchoreEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            '&.MuiPaper-root': {
              marginTop: '4px',
              marginLeft: '-12px',
              borderRadius: '8px',
              overflow: 'initial',
            },
          },
        }}
      >
        <Container data-testid="TitleSection/HyperlinkDropdown">
          <Label>Url</Label>
          <InputWrapper>
            <EditableMentionsInput
              name="LinksButton/mentionsInput"
              experienceId={experienceId}
              steps={mentionSteps}
              initialValue={inputValue}
              onValueChange={handleInputChange}
              placeholder="https://example.com"
              error={error}
              autoFocus
              noTrailingSpace
            />
          </InputWrapper>
          <ButtonGroup>
            <ActionButton
              onClick={handleUnlink}
              data-testid="TitleSection/LinksButton/unlink"
            >
              Unlink
            </ActionButton>
            <PrimaryActionButton
              onClick={handleLink}
              data-testid="TitleSection/LinksButton/link"
            >
              Link
            </PrimaryActionButton>
          </ButtonGroup>
        </Container>
      </Popover>
    </>
  );
}

export default LinksButton;
