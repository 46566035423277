import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import ReactColorPicker from '../../BlockForm/components/ReactColorPicker';
import ColorOpacityPicker from '../../BlockForm/components/ReactColorPicker/components/ColorOpacityPicker';

function ColorPickerInput({
  color,
  onChange,
  onChangeComplete,
  onColorPick,
  withAlpha,
  withoutColorsBar,
  paletteColors,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (_, event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorPick = (colorObj) => {
    if (onColorPick) {
      onColorPick(colorObj);
    } else {
      onChangeComplete(colorObj.color);
    }
  };

  return (
    <>
      <ColorOpacityPicker
        color={color}
        handleOpenColorPicker={handleClick}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
        withAlpha={withAlpha}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <ReactColorPicker
          color={color}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
          handleColorPick={handleColorPick}
          withAlpha={withAlpha}
          withoutColorsBar={withoutColorsBar}
          paletteColors={paletteColors}
        />
      </Popover>
    </>
  );
}

ColorPickerInput.propTypes = {
  color: PropTypes.string,
  withAlpha: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func,
};

export default ColorPickerInput;
