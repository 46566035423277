import React from 'react';
import PropTypes from 'prop-types';
import { Legend, FormSection } from '../../../globals';
import BackgroundContent from './components/BackgroundContent';
import BackgroundColor from '../BackgroundLayout/components/BackgroundColor';
import { SectionWrapper } from './components/BackgroundContent/style';

function BackgroundSettings({ backgroundSetting, directUploadUrl, errors }) {
  const {
    id,
    backgroundColorOverlay,
    backgroundMediaOpacity,
    backgroundMediaSignedId,
    backgroundMediaFileName,
    backgroundMediaPreviewUrl,
  } = backgroundSetting;

  return (
    <div data-testid="BackgroundSettings/Container">
      <SectionWrapper>
        <Legend>Background Style</Legend>
        <FormSection
          backgroundColor="var(--background-secondary)"
          data-testid="BackgroundSettings/BackgroundStyle"
        >
          <BackgroundColor
            label="Background color overlay"
            defaultColor={backgroundColorOverlay}
            blockStateAttribute="backgroundColorOverlay"
            hiddenInputAttribute="background_color_overlay"
          />
        </FormSection>
      </SectionWrapper>
      <Legend>Background Content</Legend>
      <FormSection
        backgroundColor="var(--background--secondary)"
        data-testid="BackgroundSettings/BackgroundContent"
      >
        <BackgroundContent
          directUploadUrl={directUploadUrl}
          defaultSignedId={backgroundMediaSignedId}
          fileName={backgroundMediaFileName}
          mediaOpacity={backgroundMediaOpacity}
          previewUrl={backgroundMediaPreviewUrl}
          errors={errors}
        />
      </FormSection>
      <input
        type="hidden"
        name="ask[background_setting_attributes][id]"
        value={id}
      />
    </div>
  );
};

BackgroundSettings.propTypes = {
  directUploadUrl: PropTypes.string,
  backgroundSetting: PropTypes.shape({
    id: PropTypes.number,
    backgroundColorOverlay: PropTypes.string,
    backgroundMediaFileName: PropTypes.string,
    backgroundMediaOpacity: PropTypes.string,
    backgroundMediaPreviewUrl: PropTypes.string,
  }),
  errors: PropTypes.object.isRequired,
};

export default BackgroundSettings;
