import styled from 'styled-components';

export const MetricItemWrapper = styled.div`
  background-color: var(--surface-background);
  padding: 5px 15px;
  padding-bottom: ${(props) => (props.isChecked ? '10px' : '5px')};
  border: 1px solid var(--border-color-primary);
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: left;
`;

export const CheckboxLabel = styled.label`
  margin 0;
  letter-spacing: inherit;
`;

export const InputCheckbox = styled.input`
  position: relative;
  outline: none;
  margin-right: 10px;

  &:focus {
    outline: none;
  }
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.125em;
  font-size: 0.8rem;
`;
