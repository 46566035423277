import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useHover } from 'use-events';
import { FlexRow, Label } from '../../../../globals';
import { Info } from 'react-feather';
import Tooltip from '../../../../tooltip';
import { IconWrapper } from './style';
import { EditableMentionsInput } from '../../../../SlateElements';
import { ExperienceIdContext } from '../../../index';
import { handleCtaButtonChange } from '../utils';
import CustomizeToolbar from '../../../../CustomizeToolbar';
import HiddenInputs from '../HiddenInputs';
import BlockContext from '../../../../../contexts/BlockContext';
import useBuildMentionsSteps from '../../../../hooks/useBuildMentionsSteps';

const InputWithTooltip = ({
  name,
  label,
  errors,
  index,
  tooltipTitle,
  primaryColor,
}) => {
  const buttonIndex = index + 1;
  const [{ ctaButtonStyle, ctaButtons }, setBlockState] = useContext(
    BlockContext,
  );
  const ctaButton = ctaButtons[buttonIndex];
  const experienceId = useContext(ExperienceIdContext);
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'MentionsInput',
  });
  const [isHover, bind] = useHover();
  const [cssStyle, setCssStyle] = useState(null);

  useEffect(() => {
    if (ctaButtonStyle === null) {
      setCssStyle({ backgroundColor: primaryColor });
      return;
    }
    if (ctaButtonStyle !== undefined) {
      setCssStyle(ctaButtonStyle || {});
    }
  }, [ctaButtonStyle]);

  const handleStylesSave = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      ctaButtonStyle: {
        ...prevState.ctaButtonStyle,
        ...value.cssStyle,
      },
    }));
  };

  return (
    <>
      <FlexRow style={{ margin: '1rem 0 0.5rem' }}>
        <Label data-testid={`ButtonSettingsLabel/${name}`}>{label}</Label>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={tooltipTitle}
          placement="bottom"
          width={300}
        >
          <IconWrapper data-testid={`TooltipIcon/${name}`}>
            <Info size={15} />
          </IconWrapper>
        </Tooltip>
      </FlexRow>
      <Box
        {...bind}
        sx={{ position: 'relative' }}
        data-testid={`CtaButton/${name}`}
      >
        <EditableMentionsInput
          key={`payment_buttons_settings[${index}]`}
          initialValue={JSON.parse(ctaButton.richText.titleElements)}
          experienceId={experienceId}
          steps={mentionSteps}
          error={errors[`cta_buttons[${index}].rich_text.title`]}
          onValueChange={(value, elements) =>
            handleCtaButtonChange(value, elements, buttonIndex, setBlockState)
          }
          customizable
        />
        {cssStyle && (
          <CustomizeToolbar
            cssStyle={cssStyle}
            isHover={isHover}
            handleSave={handleStylesSave}
            type="submissionButton"
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          />
        )}
        <HiddenInputs
          index={buttonIndex}
          buttonId={ctaButton.id}
          cssStyle={ctaButtonStyle}
          richText={ctaButton.richText}
          kind={ctaButton.kind}
        />
      </Box>
    </>
  );
};

InputWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
};

export default InputWithTooltip;
