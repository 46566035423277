import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Textarea } from './style';
import { withMention } from '../Mention';
import EditableMentionsElement from '../EditableMentionsElement';

function EditableMentionsTextarea(props) {
  const { placeholder, inputStyles, name } = props;
  const [value, setValue] = useState('');

  const handleValueChange = (value) => {
    setValue(value);
  };

  const editor = useMemo(
    () => withMention(withHistory(withReact(createEditor()))),
    [],
  );

  return (
    <>
      <EditableMentionsElement
        {...props}
        onValueChange={handleValueChange}
        editor={editor}
        render={(renderElement, renderLeaf, handleKeyDown) => (
          <Textarea
            data-testid="EditableExperienceTitle/Textarea"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            spellCheck={false}
            style={inputStyles}
            name={name}
          />
        )}
      />
      <input type="hidden" name={name} value={value} />
    </>
  );
}

EditableMentionsTextarea.propTypes = {
  defaultTitleElements: PropTypes.string,
};

export default EditableMentionsTextarea;
