import styled from 'styled-components';
import { Editable } from 'slate-react';
import { Search } from 'react-feather';

export const SearchIcon = styled(Search)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #727278;
  opacity: 0.6;
  cursor: pointer;
`;

export const Input = styled(Editable)`
  display: block;
  background: #fff;
  color: black;
  padding: 0.5rem 15px;
  padding-right: 35px;
  border-radius: 8px;
  border-width: 0px;
  min-heigth: 48px;
  margin: 0px;
  box-sizing: border-box;
  line-height: 2;
  cursor: pointer;

  & span.MuiChip-root, p, h1, h2, h3, h4 {
    margin: 0;
  }
`;
