import styled from 'styled-components';
import { Editable } from 'slate-react';

export const Textarea = styled(Editable)`
  position: static !important;
  overflow: auto;
  resize: vertical;
  background-color: #fff;
  border-width: 0;
  margin: 0;
  min-height: 195px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px 8px 0px 0px;
  border-top: 1px solid var(--border-color-primary);
  border-left: 1px solid var(--border-color-primary);
  border-right: 1px solid var(--border-color-primary);
  padding: 12px 16px;
  color: black;

  [data-slate-placeholder='true'] {
    width: auto !important;
  }

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: #bdbdbd;
  }

  p, h1, h2, h3, h4 {
    margin: 0;
  }

  h1, h2, h3, h4 {
    line-height: 1.4em;
  }

  small {
    display: block;
  }

  span.highlighted {
    padding-top: 2px;
    padding-bottom: 4px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  span.withLineHeight {
    display: inline-block;
  }

  ul li span.withLineHeight,
  ol li span.withLineHeight {
    display: inline;
  }

  ul,
  ol {
    margin: 1em;
    padding: unset;
  }

  ul li span[data-slate-leaf="true"],
  ol li span[data-slate-leaf="true"] {
    color: black;
  }

  ul li a span[data-slate-leaf="true"],
  ol li a span[data-slate-leaf="true"] {
    color: var(--link-color);
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`;
