import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHover } from 'use-events';
import Box from '@mui/material/Box';
import { Fieldset, Legend, FormSection, Label } from '../../../../globals';
import { ExperienceIdContext } from '../../../index';
import BlockContext from '../../../../../contexts/BlockContext';
import { EditableMentionsInput } from '../../../../SlateElements';
import CustomizeToolbar from '../../../../CustomizeToolbar';
import HiddenInputs from '../HiddenInputs';
import useBuildMentionsSteps from '../../../../hooks/useBuildMentionsSteps';
import { handleCtaButtonChange } from '../utils';

function SingleButtonSettings({ legend, errors, primaryColor }) {
  const [blockState, setBlockState] = useContext(BlockContext);
  const experienceId = useContext(ExperienceIdContext);
  const [cssStyle, setCssStyle] = useState(null);
  const [isHover, bind] = useHover();
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'TitleSection',
  });
  const { ctaButtonStyle, ctaButtons } = blockState;
  const ctaButton = ctaButtons[0];

  const handleStylesChange = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      ctaButtonStyle: value.cssStyle,
    }));
  };

  useEffect(() => {
    if (ctaButtonStyle === null) {
      setCssStyle({ backgroundColor: primaryColor });
      return;
    }
    if (ctaButtonStyle !== undefined) {
      setCssStyle(blockState.ctaButtonStyle || {});
    }
  }, [ctaButtonStyle]);

  return (
    <Fieldset>
      <Legend>{legend}</Legend>
      <FormSection style={{ position: 'relative' }}>
        <Label>Button text</Label>
        <Box
          sx={{ position: 'relative' }}
          {...bind}
          data-testid="CtaButton/content"
        >
          <EditableMentionsInput
            experienceId={experienceId}
            dataTestId="SingleButtonSettings/Input"
            key="single_button_settings"
            initialValue={JSON.parse(ctaButton.richText.titleElements)}
            steps={mentionSteps}
            error={errors['cta_buttons[0].rich_text.title']}
            onValueChange={(value, elements) =>
              handleCtaButtonChange(value, elements, 0, setBlockState)
            }
            customizable
          />
          {cssStyle && (
            <CustomizeToolbar
              isHover={isHover}
              handleSave={handleStylesChange}
              cssStyle={cssStyle}
              type="submissionButton"
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            />
          )}
        </Box>
        <HiddenInputs
          index={0}
          cssStyle={ctaButtonStyle}
          richText={ctaButton.richText}
          buttonId={ctaButton.id}
          kind={ctaButton.kind}
        />
      </FormSection>
    </Fieldset>
  );
}

SingleButtonSettings.propTypes = {
  legend: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
};

export default SingleButtonSettings;
