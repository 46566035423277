import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessageFloat, Label } from '../../../globals';
import Disclaimer from '../Disclaimer';
import { ErrorWrapper, CheckboxContainer } from './style';
import DataFieldEditForm from '../DataFieldEditForm';
import { NewItemButton } from '../../../Button';
import { DragAndDropDataFields } from '../DragAndDropDataFields';
import BlockLabelInput from '../BlockLabelInput';

function DataFieldsSection({
  dataFields,
  blockType,
  setDataFields,
  errors,
  serializedErrors,
  disclaimer,
  disclaimerChecked,
}) {
  const [creationFormIsActive, setCreationFormIsActive] = React.useState(false);

  const saveDataField = (dataField) => {
    const lastDataField = dataFields[dataFields.length - 1];
    const newDataField = dataField;

    if (lastDataField && lastDataField.cssStyle) {
      delete lastDataField.cssStyle.id;

      newDataField.cssStyle = lastDataField.cssStyle;
    }

    setDataFields([...dataFields, { ...newDataField }]);
    setCreationFormIsActive(false);
  };

  return (
    <div
      data-testid="BlockForm/DataFieldsSection"
      style={{ position: 'relative' }}
    >
      {blockType === 'data_only' && (
        <>
          <BlockLabelInput blockType={blockType} />
          <Label>Data Fields</Label>
        </>
      )}
      {errors.data_fields && blockType === 'donation' && (
        <ErrorWrapper>
          <ErrorMessageFloat>{errors.data_fields}</ErrorMessageFloat>
        </ErrorWrapper>
      )}
      <DragAndDropDataFields
        errors={serializedErrors}
        dataFields={dataFields}
        setDataFields={setDataFields}
      />
      {disclaimer && (
        <CheckboxContainer>
          <Disclaimer
            link={disclaimer.url}
            text={disclaimer.toc_label}
            isChecked={disclaimerChecked}
            errors={errors}
          />
        </CheckboxContainer>
      )}
      {creationFormIsActive ? (
        <DataFieldEditForm
          isActive={creationFormIsActive}
          setIsActive={setCreationFormIsActive}
          onSave={saveDataField}
        />
      ) : (
        <NewItemButton
          noMargin
          onClick={() => setCreationFormIsActive(true)}
          data-testid="DataFieldsSection/AddDataFieldButton"
        >
          + Add Data Field
        </NewItemButton>
      )}
    </div>
  );
}

DataFieldsSection.propTypes = {
  dataFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      disabled: PropTypes.bool,
      type: PropTypes.string,
      required: PropTypes.bool,
    }),
  ).isRequired,
  blockType: PropTypes.string,
  setDataFields: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  disclaimerChecked: PropTypes.bool,
};

export default DataFieldsSection;
