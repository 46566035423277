import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import BlockContext from '../../../../contexts/BlockContext';
import { usePrevious } from '../../../hooks';
import { FieldsetContainer, Legend, FormSection } from '../../../globals';
import FreeResponseSection from '../FreeResponseSection';
import PaymentSection from '../PaymentSection';
import MultipleChoiceSection from '../MultipleChoiceSection';
import DataFieldsSection from '../DataFieldsSection';
import ContentSection from '../ContentSection';
import { DEFAULT_DONATION_DATA_FIELDS } from '../../../../constants';
import BlockLabelInput from '../BlockLabelInput';
import { buildInitialConditions } from '../../../experiences/events/EditActionForm/utils';

const initialDataFields = (fields) => {
  return fields.map((f) => {
    if (f.showCondition && !_isEmpty(f.showCondition.conditions.data)) {
      return {
        ...f,
        showConditions: buildInitialConditions(f.showCondition.conditions),
        hidden: null,
      };
    } else {
      return {
        ...f,
        showConditions: null,
      };
    }
  });
};

function BlockDetails({ block, updateBlockAttributes, isEditPage, errors, serializedErrors }) {
  const [dataFields, setDataFields] = useState(initialDataFields(block.dataFields));
  const prevBlockType = usePrevious(block.type);
  const [, setBlockState] = useContext(BlockContext);

  const updateDataFields = (newDataFields) => {
    setDataFields(newDataFields);

    setBlockState((prevState) => ({
      ...prevState,
      dataFields: newDataFields.filter((field) => !field.deleted),
    }));
  };

  useEffect(() => {
    // Render Name and Email fields when there's no data field and we're changing block type
    if (_isEmpty(block.dataFields)) {
      if (
        prevBlockType &&
        prevBlockType !== block.type &&
        block.type === 'donation'
      ) {
        updateDataFields(DEFAULT_DONATION_DATA_FIELDS);
      } else {
        updateDataFields([]);
      }
    }
  }, [block.type]);

  const renderSection = () => {
    switch (block.type) {
      case 'free_response':
        return (
          <FreeResponseSection
            ugcTypes={block.ugcTypes}
            updateBlockAttributes={updateBlockAttributes}
            errors={errors}
          />
        );
      case 'donation':
        return (
          <PaymentSection
            block={block}
            dataFields={dataFields}
            setDataFields={updateDataFields}
            updateBlockAttributes={updateBlockAttributes}
            isEditPage={isEditPage}
            errors={errors}
            serializedErrors={serializedErrors}
          />
        );
      case 'multiple_choice':
        return (
          <MultipleChoiceSection
            optionsList={block.multipleChoiceOptions}
            otherIsChecked={block.multipleChoiceOther}
            multiIsChecked={block.multipleChoiceMultiSelect}
            withoutMultiSelect={isEditPage}
            updateBlockAttributes={updateBlockAttributes}
            hint="When multiple choice options are edited or removed, existing response data will still remain. Conditional relationships will be affected."
          />
        );
      case 'data_only':
        return (
          <DataFieldsSection
            setDataFields={updateDataFields}
            dataFields={dataFields}
            errors={errors}
            serializedErrors={serializedErrors}
            disclaimerChecked={block.disclaimerChecked}
            disclaimer={block.disclaimer}
            blockType={block.type}
          />
        );
      case 'quick_answer':
        return <FormSection><BlockLabelInput blockType="quick_answer" /></FormSection>;
      case 'content':
        return <ContentSection defaultTimeout={block.contentTimeout} />;
      default:
        return null;
    }
  };

  return (
    <FieldsetContainer>
      <Legend>Details</Legend>

      {renderSection()}
    </FieldsetContainer>
  );
}

BlockDetails.propTypes = {
  block: PropTypes.shape({
    contentTimeout: PropTypes.number,
    ctaButtonContent: PropTypes.string,
    ctaButtonSettingsChecked: PropTypes.bool,
    currency: PropTypes.string,
    customDonation: PropTypes.bool,
    dataFields: PropTypes.arrayOf(
      PropTypes.shape({
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    paymentOptions: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.string,
      }),
    ),
    donationType: PropTypes.string,
    metricsChecked: PropTypes.bool,
    multipleChoiceMultiSelect: PropTypes.bool,
    multipleChoiceOptions: PropTypes.array,
    multipleChoiceOther: PropTypes.bool,
    paypalEmail: PropTypes.string,
    paypalEnabled: PropTypes.bool,
    politicalDonation: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    ugcTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  updateBlockAttributes: PropTypes.func.isRequired,
  isEditPage: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default BlockDetails;
