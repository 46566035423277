import React from 'react';
import CssStyleHiddenInputs from '../../../CssStyleHiddenInputs';

export default function ButtonHiddenInputs(props) {
  const button = props.button || {};
  const attributesName = `ask[navigation_buttons_attributes][${props.index}]`;
  return (
    <>
      <input type="hidden" name={`${attributesName}[id]`} value={button.id} />
      <input
        type="hidden"
        name={`${attributesName}[enabled]`}
        value={button.enabled}
      />
      <input
        type="hidden"
        name={`${attributesName}[direction]`}
        value={button.direction}
      />
      <CssStyleHiddenInputs
        cssStyle={button.cssStyle}
        attributesName={`${attributesName}[css_style_attributes]`}
      />
    </>
  );
}
