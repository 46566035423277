import React from 'react';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function FormulaPicker({ anchorEl, setAnchorEl, editor }) {
  const [items, setItems] = React.useState([
    {
      label: 'calc',
      description: 'Arithmetic for numbers',
      value: 'calc',
    },
  ]);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value) => {
    const mustaches = `{{#${value}}}{{/${value}}}`;
    const fragment = editor.getFragment();
    const fragmentStyles = fragment[0].children[0];
    const focusDistance = `{{/${value}}}`.length;

    editor.deleteBackward(1);
    Transforms.insertNodes(editor, { ...fragmentStyles, text: mustaches });
    Transforms.move(editor, { distance: focusDistance, reverse: true });
    ReactEditor.focus(editor);

    handleClose();
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (key === 'Backspace' || key === 'Delete') {
      handleClose();
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
      disablePortal
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {items.map(({ label, description, value }) => (
        <MenuItem
          sx={{
            '&.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible': {
              backgroundColor: '#eee',
            },
          }}
          key={value}
          onClick={() => handleSelect(value)}
        >
          <Stack spacing={0.5}>
            <Typography variant="body1">{label}</Typography>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
              {description}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Menu>
  );
}
