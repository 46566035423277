import _isEmpty from 'lodash/isEmpty';

export const showErrorAlert = (enqueueSnackbar, err) => {
  let errorMessage = 'Something went wrong. Try again later.';

  try {
    const { detail } = JSON.parse(err.message).errors[0];
    errorMessage = detail;
  } catch (_error) {
    if (err) {
      if (err.message === 'Failed to fetch') {
        errorMessage =
          'Please check your internet connection or try again later.';
      } else if (typeof err.message === 'string' && !_isEmpty(err.message)) {
        errorMessage = err.message;
      }
    }
  }

  enqueueSnackbar(errorMessage, {
    variant: 'error',
    autoHideDuration: 3000,
  });
};
