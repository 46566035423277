export const stringOperators = [
  { label: 'equals', value: 'equals' },
  { label: 'does not equal', value: 'does_not_equal' },
  { label: 'contains', value: 'contains' },
  { label: 'does not contain', value: 'does_not_contain' },
  { label: 'starts with', value: 'starts_with' },
  { label: 'ends with', value: 'ends_with' },
  { label: 'is empty', value: 'is_empty' },
  { label: 'is not empty', value: 'is_not_empty' },
];

export const numberOperators = [
  { label: '=', value: '=' },
  { label: '≠', value: '≠' },
  { label: '>', value: '>' },
  { label: '<', value: '<' },
  { label: '>=', value: '>=' },
  { label: '<=', value: '<=' },
  { label: 'is empty', value: 'is_empty' },
  { label: 'is not empty', value: 'is_not_empty' },
];

export const dateOperators = [
  { label: 'is', value: 'is' },
  { label: 'is after', value: 'is_after' },
  { label: 'is before', value: 'is_before' },
  { label: 'is on or after', value: 'is_on_or_after' },
  { label: 'is on or before', value: 'is_on_or_before' },
  { label: 'is empty', value: 'is_empty' },
  { label: 'is not empty', value: 'is_not_empty' },
];

export const getOperatorFromReferenceType = (type) => {
  switch (type) {
    case 'integer':
    case 'money':
      return numberOperators[0].value;
    case 'date':
      return dateOperators[0].value;
    default:
      return stringOperators[0].value;
  }
};

