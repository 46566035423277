import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import IconBox from '../IconBox';
import { useMuiPopover } from '../../../hooks';

const borderWidth = [
  '0px',
  '1px',
  '2px',
  '3px',
  '4px',
  '8px',
  '12px',
  '16px',
  '24px',
];

function BorderWidth({ initialWidth, handleSetBorderWidth }) {
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'CustomToolbar/BorderWidth',
  );

  const handleItemClick = (event) => {
    handleSetBorderWidth(event.target.innerText);
    handleClose();
  };

  return (
    <>
      <IconBox isOpen={open} tooltipTitle="Border width">
        <LineWeightIcon onClick={handleOpen} />
      </IconBox>
      <Popover
        id={id}
        data-testid={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0',
            borderRadius: '12px',
          },
        }}
      >
        {borderWidth.map((width, index) => (
          <Typography
            variant="subtitle2"
            component="p"
            onClick={handleItemClick}
            sx={{
              width: '100%',
              fontSize: '14px',
              padding: '8px 16px',
              paddingTop: `${index === 0 ? '16px' : '8px'}`,
              paddingBottom: `${
                index === borderWidth.length - 1 ? '16px' : '8px'
              }`,
              color: '#333',
              cursor: 'pointer',
              backgroundColor: `${
                width === initialWidth
                  ? 'rgba(29, 29, 29, 0.08)'
                  : 'transparent'
              }`,
              textAlign: 'center',
            }}
            key={width}
          >
            {width}
          </Typography>
        ))}
      </Popover>
    </>
  );
}

export default BorderWidth;
