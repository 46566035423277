import React from 'react';
import CssStyleHiddenInputs from '../../../CssStyleHiddenInputs';

export default function HiddenInputs({
  elementsValue,
  htmlValue,
  textValue,
  cssStyle,
}) {
  return (
    <>
      <input
        type="hidden"
        name="ask[title_setting_attributes][rich_text_attributes][title_elements]"
        value={elementsValue}
      />
      <input
        type="hidden"
        name="ask[title_setting_attributes][rich_text_attributes][title_html]"
        value={htmlValue}
      />
      <input
        type="hidden"
        name="ask[title_setting_attributes][rich_text_attributes][title]"
        value={textValue}
      />
      <CssStyleHiddenInputs
        cssStyle={cssStyle}
        attributesName="ask[title_setting_attributes][css_style_attributes]"
      />
    </>
  );
}
