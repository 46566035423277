import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../inputs/Input';
import { Fieldset, Label, FormSection, FlexRow } from '../../../globals';
import {
  Container,
  GridContainer,
  GridItem,
  InputWrapper,
  TBox,
  LabelWrapper,
  IconWrapper,
} from './style';
import Tooltip from '../../../tooltip';
import { Info } from 'react-feather';
import BlockContext from '../../../../contexts/BlockContext';
import FontSizeSection from './FontSizeSection';

const tooltipTitle =
  'This controls the relative position of your text box based on the viewport height (top and bottom) and width (left and right). Your numbers should typically range between 0-100.';

function PromptSettingsSection({ titleSetting, errors }) {
  const {
    positionTop,
    positionRight,
    positionBottom,
    positionLeft,
    fontSizeMin,
    fontSizeMax,
  } = titleSetting;

  const [, setBlockState] = useContext(BlockContext);
  const updateContext = (attribute, value) => {
    // this code updates one field of titleSettings object of blockState at a time
    setBlockState((prevState) => ({
      ...prevState,
      titleSetting: { ...prevState.titleSetting, [attribute]: parseInt(value) },
    }));
  };

  return (
    <Container data-testid="BlockForm/promptSettingsSection">
      <Fieldset data-testid="PromptSettings/textBoxSizing">
        <FlexRow>
          <Label>text box sizing</Label>
          <Tooltip
            data-testid="PromptSettings/tooltip"
            disableFocusListener
            disableTouchListener
            title={tooltipTitle}
            placement="top"
            width={320}
          >
            <IconWrapper>
              <Info size={15} />
            </IconWrapper>
          </Tooltip>
        </FlexRow>
        <FormSection backgroundColor="var(--background-secondary)">
          <FlexRow>
            <GridContainer>
              <LabelWrapper>
                <Label>margin %</Label>
              </LabelWrapper>
              <GridItem gridArea="top">
                <InputWrapper>
                  <Input
                    type="number"
                    defaultValue={positionTop}
                    name="ask[title_setting_attributes][position_top]"
                    error={
                      errors['title_setting.position_top'] &&
                      errors['title_setting.position_top'][0]
                    }
                    onChange={(e) =>
                      updateContext('positionTop', e.target.value)
                    }
                  />
                </InputWrapper>
              </GridItem>
              <GridItem gridArea="right">
                <InputWrapper>
                  <Input
                    type="number"
                    defaultValue={positionRight}
                    name="ask[title_setting_attributes][position_right]"
                    error={
                      errors['title_setting.position_right'] &&
                      errors['title_setting.position_right'][0]
                    }
                    onChange={(e) =>
                      updateContext('positionRight', e.target.value)
                    }
                  />
                </InputWrapper>
              </GridItem>
              <GridItem gridArea="center">
                <TBox>T</TBox>
              </GridItem>
              <GridItem gridArea="bottom">
                <InputWrapper>
                  <Input
                    type="number"
                    defaultValue={positionBottom}
                    name="ask[title_setting_attributes][position_bottom]"
                    error={
                      errors['title_setting.position_bottom'] &&
                      errors['title_setting.position_bottom'][0]
                    }
                    onChange={(e) =>
                      updateContext('positionBottom', e.target.value)
                    }
                  />
                </InputWrapper>
              </GridItem>
              <GridItem gridArea="left">
                <InputWrapper>
                  <Input
                    type="number"
                    defaultValue={positionLeft}
                    name="ask[title_setting_attributes][position_left]"
                    error={
                      errors['title_setting.position_left'] &&
                      errors['title_setting.position_left'][0]
                    }
                    onChange={(e) =>
                      updateContext('positionLeft', e.target.value)
                    }
                  />
                </InputWrapper>
              </GridItem>
            </GridContainer>
          </FlexRow>
        </FormSection>
      </Fieldset>
      <FontSizeSection
        defaultValues={{
          fontSizeMin: fontSizeMin || '',
          fontSizeMax: fontSizeMax || '',
        }}
        updateContext={updateContext}
        defaultErrors={errors}
      />
    </Container>
  );
}

PromptSettingsSection.propTypes = {
  titleSetting: PropTypes.shape({
    position_top: PropTypes.number,
    position_right: PropTypes.number,
    position_bottom: PropTypes.number,
    position_left: PropTypes.number,
    font_size_min: PropTypes.number,
    font_size_max: PropTypes.number,
  }).isRequired,
  errors: PropTypes.shape({
    'title_setting.position_top': PropTypes.array,
    'title_setting.position_right': PropTypes.array,
    'title_setting.position_bottom': PropTypes.array,
    'title_setting.position_left': PropTypes.array,
    'title_setting.font_size_min': PropTypes.array,
    'title_setting.font_size_max': PropTypes.array,
  }),
};

export default PromptSettingsSection;
