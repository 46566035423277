import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '../../../globals';
import { EditableMentionsInput } from '../../../SlateElements';
import useBuildMentionsSteps from '../../../hooks/useBuildMentionsSteps';
import { ExperienceIdContext } from '../../index';
import BlockContext from '../../../../contexts/BlockContext';
import { slateToHtml } from '../TitleSection/utils';
import Box from '@mui/material/Box';
import HiddenInputs from './components/HiddenInputs';
import { DEFAULT_ASK_LABELS } from '../../../../constants';
import { useHover } from 'use-events';
import CustomizeToolbar from '../../../CustomizeToolbar';

function BlockLabelInput({ hidden, blockType, multiIsChecked }) {
  if (hidden) {
    return null;
  }

  const [blockState, setBlockState] = React.useContext(BlockContext);
  const experienceId = React.useContext(ExperienceIdContext);
  const { askLabel, initialValues } = blockState;
  const [isHover, bind] = useHover();
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'MentionsInput',
  });

  const handleLabelChange = (value, elements) => {
    setBlockState((prevState) => ({
      ...prevState,
      askLabel: {
        ...askLabel,
        richText: {
          id: askLabel.richText.id,
          title: value,
          titleElements: JSON.stringify(elements),
          titleHtml: slateToHtml(elements),
        },
      },
    }));
  };

  const handleStylesChange = (value) => {
    setBlockState((prevState) => ({
      ...prevState,
      askLabel: {
        ...askLabel,
        cssStyle: value.cssStyle,
      },
    }));
  };

  const initialValue = React.useMemo(() => {
    if (blockType === initialValues.blockType) {
      return JSON.parse(initialValues.askLabel.richText.titleElements);
    } else {
      const type = blockType === 'multiple_choice' && multiIsChecked
        ? 'multiple_choice_multi'
        : blockType;
      return JSON.parse(DEFAULT_ASK_LABELS[type].titleElements);
    }
  }, [blockType, multiIsChecked]);

  const valueToInsert = React.useMemo(() => {
    if (blockType === 'multiple_choice' && blockType !== initialValues.blockType) {
      return initialValue;
    }
  }, [blockType, multiIsChecked]);

  return (
    <Box aria-label="block label section" sx={{ mb: 2 }}>
      <Label>Label</Label>
      <Box sx={{ position: 'relative' }} {...bind}>
        <EditableMentionsInput
          experienceId={experienceId}
          dataTestId="BlockLabelInput"
          key="label_input"
          initialValue={initialValue}
          valueToInsert={valueToInsert}
          steps={mentionSteps}
          onValueChange={handleLabelChange}
          customizable
        />
        <CustomizeToolbar
          isHover={isHover}
          handleSave={handleStylesChange}
          cssStyle={askLabel.cssStyle || {}}
          type="askLabel"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        />
      </Box>
      <HiddenInputs askLabel={askLabel} />
    </Box>
  );
}

BlockLabelInput.propTypes = {
  hidden: PropTypes.bool,
  blockType: PropTypes.string.isRequired,
};

export default BlockLabelInput;
