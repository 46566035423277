import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createEditor, Editor, Transforms } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Input } from './style';
import { withMention } from '../Mention';
import { withSingleLine } from '../utils';
import EditableMentionsElement from '../EditableMentionsElement';
import ErrorMessage from '../../../shared/ErrorMessage';
import { Container } from './style';
import HoveringToolbar from '../HoveringToolbar';
import { withLinks } from '../../../BlockForm/components/TitleSection/components/LinksButton/utils';

function EditableMentionsInput(props) {
  const {
    placeholder,
    inputStyles,
    name,
    error,
    initialValue,
    withHiddenInput,
    onValueChange,
    onKeyDown,
    dataTestId,
    autoFocus,
    multirows,
    label,
    customizable,
    containerPosition = 'relative',
  } = props;
  const [value, setValue] = useState(initialValue);
  const portalRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      });
    }
  }, []);

  const handleValueChange = (value, elements) => {
    setValue(value);
    if (onValueChange) {
      onValueChange(value, elements);
    }
  };

  const editor = useMemo(
    () =>
      withMention(
        withLinks(withSingleLine(withHistory(withReact(createEditor())))),
      ),
    [],
  );

  return (
    <Container position={containerPosition} ref={portalRef}>
      <EditableMentionsElement
        {...props}
        editor={editor}
        onValueChange={handleValueChange}
        render={(renderElement, renderLeaf, handleKeyDown) => (
          <>
            <Input
              data-testid={dataTestId}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onKeyDown={(e) => {
                handleKeyDown(e);
                if (onKeyDown) {
                  onKeyDown(e);
                }
              }}
              placeholder={placeholder}
              spellCheck={false}
              style={inputStyles}
              name={name}
              autoFocus={autoFocus}
              multirows={multirows?.toString()}
              label={label}
            />
            {customizable && <HoveringToolbar portalRef={portalRef} />}
          </>
        )}
      />
      {withHiddenInput && (
        <input
          type="hidden"
          value={value}
          name={name}
          data-testid={dataTestId}
        />
      )}
      {error && <ErrorMessage text={error} position="right-top" />}
    </Container>
  );
}

EditableMentionsInput.propTypes = {
  defaultTitleElements: PropTypes.string,
};

export default EditableMentionsInput;
