import React from 'react';
import Popover from '@mui/material/Popover';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Typography from '@mui/material/Typography';
import IconBox from '../IconBox';
import { useMuiPopover } from '../../../hooks';
import _isEqual from 'lodash/isEqual';

const borderSides = [
  {
    label: 'All sides',
    Icon: <BorderOuterIcon />,
    styles: { borderTop: '', borderRight: '', borderBottom: '', borderLeft: '' },
  },
  {
    label: 'Bottom',
    Icon: <BorderBottomIcon />,
    styles: { borderTop: 'none', borderRight: 'none', borderBottom: '', borderLeft: 'none' },
  },
  {
    label: 'None',
    Icon: <DoDisturbIcon />,
    styles: { borderTop: 'none', borderRight: 'none', borderBottom: 'none', borderLeft: 'none' },
  },
];

function BorderSides({ initialStyles, handleSetBorderSides }) {
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'CustomToolbar/BorderSides',
  );

  const handleItemClick = (styles) => () => {
    handleSetBorderSides(styles);
    handleClose();
  };

  return (
    <>
      <IconBox isOpen={open} tooltipTitle="Border sides">
        <BorderOuterIcon onClick={handleOpen} />
      </IconBox>
      <Popover
        id={id}
        data-testid={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            py: 1,
            borderRadius: '12px',
          },
        }}
      >
        {borderSides.map(({ label, Icon, styles }) => (
          <Typography
            onClick={handleItemClick(styles)}
            key={label}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '40px',
              padding: '8px 16px',
              cursor: 'pointer',
              backgroundColor: `${
                _isEqual(styles, initialStyles)
                  ? 'rgba(29, 29, 29, 0.08)'
                  : 'transparent'
              }`,
              '& .MuiSvgIcon-root': {
                opacity: 0.54,
                mr: 1.5,
              },
            }}
          >
            {Icon}
            {label}
          </Typography>
        ))}
      </Popover>
    </>
  );
}

export default BorderSides;
