import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 599px) {
    padding-bottom: 3rem;
  }
`;

export const Subtitle = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
`;

export const ErrorMessageWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: -13px;
`;

export const textareaStyle = {
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
  minHeight: '200px',
};
