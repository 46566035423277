import React from 'react';
import { SwitchWithTitle } from '../../../switch';
import BlockContext from '../../../../contexts/BlockContext';
import CheckboxWithToolbar from './components/CheckboxWithToolbar';
import DestroyHiddenInputs from './components/DestroyHiddenInputs';
import _isEmpty from 'lodash/isEmpty';

const checkboxes = [
  {
    label: 'Back button',
    direction: 'back',
  },
  {
    label: 'Forward button',
    direction: 'forward',
  },
];

const initialButtons = [
  {
    direction: 'back',
    enabled: true,
    cssStyle: {},
  },
  {
    direction: 'forward',
    enabled: true,
    cssStyle: {},
  },
];

export default function NavigationButtonsSection() {
  const [blockState, setBlockState] = React.useContext(BlockContext);
  const handleIsChecked = (e) => {
    const checked = e.target.checked;
    const navigationButtons =
      checked && _isEmpty(blockState.navigationButtons)
        ? initialButtons
        : blockState.navigationButtons;
    setBlockState((prevState) => ({
      ...prevState,
      showNavigationButtons: checked,
      navigationButtons,
    }));
  };

  return (
    <div aria-label="block navigation buttons section">
      <SwitchWithTitle
        checked={blockState.showNavigationButtons}
        onChange={handleIsChecked}
        title="Show navigation buttons"
        data-testid="NavigationButtonsSection/Switcher"
      >
        {checkboxes.map(({ label, direction }, index) => (
          <CheckboxWithToolbar
            key={label}
            label={label}
            direction={direction}
            index={index}
          />
        ))}
      </SwitchWithTitle>
      <DestroyHiddenInputs blockState={blockState} />
    </div>
  );
}
