import React from 'react';
import SimpleSelect from '../../../../../select';
import Box from '@mui/material/Box';
import MentionTitle from '../../../../../MentionTitle';
import { COUNTRIES_FOR_SELECT } from '../../../../../../constants';

const buildOptions = (type, multipleChoiceOptions) => {
  const noneOption = { value: '', label: 'None' };
  switch (type) {
    case 'multiple_choice':
      const preparedMcOptions = multipleChoiceOptions.map(
        ({ richText: { title } }) => ({
          value: title,
          label: <MentionTitle title={title} />,
        }),
      );
      return [noneOption, ...preparedMcOptions];
    case 'country':
      const countries = COUNTRIES_FOR_SELECT.map((o) => ({
        value: o.value,
        label: o.name,
      }));
      return [noneOption, ...countries];
    case 'currently_employed':
      return [
        noneOption,
        { value: 'true', label: 'Yes, I am currently employed' },
        { value: 'false', label: 'No, I am not currently employed' },
      ];

    default:
      return [];
  }
};

export default function DefaultSelectInput({
  type,
  onChange,
  defaultValue,
  multipleChoiceOptions,
}) {
  const [options, setOptions] = React.useState(buildOptions(type, multipleChoiceOptions));

  React.useEffect(() => {
    setOptions(buildOptions(type, multipleChoiceOptions));
  }, [type, multipleChoiceOptions]);

  const handleTypeChange = (option) => {
    onChange({ defaultValue: option.value });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <SimpleSelect
        options={options}
        defaultValue={options.find((o) => o.value === defaultValue) || options[0]}
        placeholder="Choose value or press “[“"
        classNamePrefix="react-select__default-value"
        onChange={(option) => handleTypeChange(option)}
      />
    </Box>
  );
}
