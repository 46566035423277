import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Label, FormSectionDescription, FormSection } from '../../../globals';
import Checkbox from '../../../checkbox';
import Slider from '../../../slider';
import BlockLabelInput from '../BlockLabelInput';

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
];

function ContentSection({ defaultTimeout }) {
  const timeout = defaultTimeout;
  const [checked, setChecked] = useState(timeout !== null);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <FormSection data-testid="BlockForm/ContentSection">
        <BlockLabelInput blockType="content" />
        <Label>Content block Options</Label>
        <FormSectionDescription>
          Great for blocks that don't require a response. Choose background
          content on the next page.
        </FormSectionDescription>
        <Checkbox
          label="Set a time limit"
          checkedByDefault={checked}
          onChange={handleChange}
          data-testid="ContentSection/Checkbox"
        />
        {checked && (
          <>
            <Label>Time Limit</Label>
            <FormSectionDescription>
              Choose how many seconds you want this block to be shown for. Time
              limit will not affect blocks with buttons.
            </FormSectionDescription>
            <Slider
              defaultValue={timeout || 5}
              min={1}
              max={20}
              marks={marks}
              name="ask[content_timeout]"
              data-testid="ContentSection/Slider"
            />
          </>
        )}
        {!checked && <input type="hidden" name="ask[content_timeout]" />}
      </FormSection>
    </>
  );
}

ContentSection.propTypes = {
  defaultTimeout: PropTypes.number,
};

export default ContentSection;
