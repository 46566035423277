import React from 'react';
import IconBox from '../IconBox';
import { useMuiPopover } from '../../../hooks';
import { rgbaObjToString } from '../../../../utils';
import ColorPopover from '../ColorPopover';
import { getInitialColor } from '../../utils';

export default function Color({ title, Icon, initialColor, onChangeComplete, paletteColors }) {
  const [color, setColor] = React.useState(getInitialColor(initialColor, paletteColors));
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'simple-popover',
  );

  const handleChange = (value) => {
    setColor(rgbaObjToString(value));
  };

  const handleChangeComplete = (value) => {
    const rgbaString = rgbaObjToString(value);
    setColor(rgbaString);
    onChangeComplete(rgbaString);
  };

  const handleColorPick = (colorObj) => {
    setColor(colorObj.color);
    onChangeComplete(colorObj.name);
  };

  return (
    <>
      <IconBox isOpen={open} tooltipTitle={title}>
        <Icon onClick={handleOpen} />
      </IconBox>
      <ColorPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        color={color}
        handleChange={handleChange}
        handleChangeComplete={handleChangeComplete}
        handleColorPick={handleColorPick}
        paletteColors={paletteColors}
      />
    </>
  );
}
