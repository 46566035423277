import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import {
  StyledTitle,
  InnerContentWrapper,
  ButtonsWrapper,
  StyledButton,
} from './style';

const getDefaultStyles = (theme, style = {}) => {
  return {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'var(--modal-overlay-color)',
      zIndex: 10,
    },
    content: {
      borderRadius: '8px',
      borderWidth: '0px',
      color: theme === 'dark' ? 'var(--font-color-primary)' : 'var(--font-color-secondary--middle)',
      backgroundColor: theme === 'dark' ? 'var(--background-secondary)' : 'var(--white)',
      fontWeight: '500',
      lineHeight: '24px',
      padding: '0',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-48%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      minWidth: '280px',
      maxWidth: `${style.maxWidth ? style.maxWidth : '380px'}`,
    },
  };
};

const renderContent = (content) => {
  switch (typeof content) {
    case 'object':
      return <div>{content}</div>;
    case 'string':
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }
};

const Modal = ({
  isOpen,
  title,
  content,
  buttons,
  hideModal,
  customStyles,
  theme,
  style,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles || getDefaultStyles(theme, style)}
      ariaHideApp={false}
      onRequestClose={hideModal}
    >
      <InnerContentWrapper>
        <StyledTitle>{title}</StyledTitle>
        {renderContent(content)}
      </InnerContentWrapper>
      {buttons && (
        <ButtonsWrapper>
          {buttons.map((button, index) => {
            return (
              <StyledButton
                key={index}
                onClick={button.onClick}
                primary={button.primary}
                disabled={button.disabled}
              >
                {button.title}
              </StyledButton>
            );
          })}
        </ButtonsWrapper>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttons: PropTypes.array,
  hideModal: PropTypes.func,
  curtomStyles: PropTypes.shape({}),
  style: PropTypes.shape({}),
  theme: PropTypes.string,
};

export default Modal;
