import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { EditableMentionsInput } from '../../../../../SlateElements';
import Select from '../../../Select';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Label } from '../../../../../globals';
import { DropdownMenu } from '../../../../../DropdownMenu';
import DatePicker from '../DatePicker';
import {
  stringOperators,
  numberOperators,
  dateOperators,
  getOperatorFromReferenceType,
} from './utils';

export default function Condition({
  label,
  ruleIndex,
  conditionIndex,
  experienceId,
  mentionSteps,
  conditionAttributes,
  onChange,
  onKeyDown,
  errors,
}) {
  if (conditionAttributes._destroy) {
    return <Box sx={{ mt: -1 }} />;
  }

  const [referenceType, setReferenceType] = React.useState(
    conditionAttributes.data_type,
  );
  const [selectedDate, setSelectedDate] = React.useState();

  const handleConditionChange = (value, attr) => {
    onChange((prev) =>
      prev.map((t, i) => {
        if (i === ruleIndex) {
          return {
            ...prev[i],
            conditions_attributes: prev[i].conditions_attributes.map(
              (condition, i) => {
                if (i === conditionIndex) {
                  return {
                    ...condition,
                    [attr]: value,
                    data_type: referenceType,
                  };
                } else {
                  return condition;
                }
              },
            ),
          };
        } else {
          return t;
        }
      }),
    );
  };

  const handleDelete = () => {
    onChange((prev) =>
      prev.map((t, i) => {
        if (i === ruleIndex) {
          return {
            ...prev[i],
            conditions_attributes: conditionAttributes.id
              ? prev[i].conditions_attributes.map((condition, i) => {
                if (i === conditionIndex) {
                  return {
                    ...condition,
                    _destroy: true,
                  };
                } else {
                  return condition;
                }
              })
              : prev[i].conditions_attributes.filter(
                (_c, i) => i !== conditionIndex,
              ),
          };
        } else {
          return t;
        }
      }),
    );
  };

  const operators = React.useMemo(() => {
    switch (referenceType) {
      case 'integer':
      case 'money':
        return numberOperators;
      case 'date':
        return dateOperators;

      default:
        return stringOperators;
    }
  }, [referenceType]);

  const handleReferenceType = (value) => {
    setReferenceType(value || 'string');
    if (value !== referenceType) {
      handleConditionChange(getOperatorFromReferenceType(value), 'operator');
    }
  };

  const handleDatePick = (date) => {
    handleConditionChange(date.toLocaleDateString('en-US'), 'second_value');
    setSelectedDate(date.toLocaleDateString('en-US'));
  };

  return (
    <Stack gap={1} aria-label="condition">
      <Box sx={{ position: 'relative', display: 'flex', my: 0.5 }}>
        <Label noMargin>{label}</Label>
        {conditionIndex > 0 && (
          <Box sx={{ position: 'absolute', right: 0, top: -10 }}>
            <DropdownMenu
              dataTestId="Conditon/DropdownMenu"
              menuItems={[
                {
                  title: 'Delete condition',
                  value: 'delete',
                  icon: <DeleteOutlineIcon />,
                },
              ]}
              triggerButton={<MoreHorizIcon />}
              onSelection={handleDelete}
              disablePortal
            />
          </Box>
        )}
      </Box>
      <EditableMentionsInput
        experienceId={experienceId}
        steps={mentionSteps}
        placeholder="Press “[“ to add a reference"
        name="condition_first_value"
        initialValue={conditionAttributes.first_value}
        onValueChange={(value) => handleConditionChange(value, 'first_value')}
        extendKeyBoardNavigation={onKeyDown}
        onReferenceType={handleReferenceType}
        error={errors && errors.first_value}
        noTrailingSpace
      />
      <Box sx={{ position: 'relative', pl: 17.5 }}>
        <Box sx={{ position: 'absolute', left: 0 }}>
          <Box sx={{ width: 132 }}>
            <Select
              options={operators}
              defaultValue={operators.find(
                (o) => o.value === conditionAttributes.operator,
              )}
              onChange={(operator) =>
                handleConditionChange(operator.value, 'operator')
              }
              name="condition_operator"
              id=""
            />
          </Box>
        </Box>
        <Box sx={{ height: 50, display: 'flex' }}>
          {!['is_empty', 'is_not_empty'].includes(
            conditionAttributes.operator,
          ) && (
            <>
              <EditableMentionsInput
                name="condition_second_value"
                experienceId={experienceId}
                steps={mentionSteps}
                placeholder="Add value or press “[“"
                initialValue={conditionAttributes.second_value}
                valueToInsert={selectedDate}
                onValueChange={(value) =>
                  handleConditionChange(value, 'second_value')
                }
                extendKeyBoardNavigation={onKeyDown}
                error={errors && errors.second_value}
                noTrailingSpace
              />
              {referenceType === 'date' && (
                <DatePicker
                  selectedDate={
                    selectedDate || conditionAttributes.second_value
                  }
                  onChange={handleDatePick}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
}
