import React from 'react';
import Popover from '@mui/material/Popover';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import Typography from '@mui/material/Typography';
import IconBox from '../IconBox';
import { useMuiPopover } from '../../../hooks';

const borderStyles = ['solid', 'dashed', 'dotted'];

function BorderStyle({ initialStyle, handleSetBorderStyle }) {
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'CustomToolbar/BorderStyle',
  );

  const handleItemClick = (style) => () => {
    handleSetBorderStyle(style);
    handleClose();
  };

  return (
    <>
      <IconBox isOpen={open} tooltipTitle="Border style">
        <LineStyleIcon onClick={handleOpen} />
      </IconBox>
      <Popover
        id={id}
        data-testid={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0',
            borderRadius: '12px',
          },
        }}
      >
        {borderStyles.map((borderStyle) => (
          <Typography
            onClick={handleItemClick(borderStyle)}
            key={borderStyle}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '40px',
              padding: '8px 16px',
              cursor: 'pointer',
              backgroundColor: `${
                borderStyle === initialStyle
                  ? 'rgba(29, 29, 29, 0.08)'
                  : 'transparent'
              }`,
            }}
            key={borderStyle}
          >
            <span
              style={{
                display: 'inline-block',
                width: '72px',
                height: '4px',
                borderTop: `4px ${borderStyle} #333`,
              }}
            />
          </Typography>
        ))}
      </Popover>
    </>
  );
}

export default BorderStyle;
